import {Button, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LabeledItem} from "../../base/data/LabeledItem";
import {BooleanDisplay} from "../../base/displays/BooleanDisplay";
import {AHeader} from "../../base/layout/AHeader";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {RolesProvider} from "../../domain/RolesProvider";
import {Role} from "../../domain/types";
import {gs} from "../../theme";

interface RoleViewProps {}

export function RoleView(props: RoleViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const tenantContext = useTenantContext();
    const {tenantUuid} = tenantContext;
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [role, setRole] = useState<Role>();

    useEffect(() => {
        if (routeParams.roleUuid && api.auth) {
            const rolesProvider = new RolesProvider(api);
            rolesProvider.get(tenantUuid, routeParams.roleUuid).then(setRole);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={role ? role.name : "..."}
                        crumbs={[
                            {label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`},
                            {
                                label: role ? role.name : "...",
                                href: `/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`
                            }
                        ]}
                    >
                        {tenantContext.role &&
                            tenantContext.role.canExertPermission(
                                `/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`,
                                "WRITE"
                            ) && (
                                <Button
                                    variant="outlined"
                                    onClick={() =>
                                        navigate(`/tenants/${tenantUuid}/roles/${routeParams.roleUuid}/edit`)
                                    }
                                >
                                    {t("roles.edit")}
                                </Button>
                            )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {role && (
                    <>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("roles.singular")}>{role.name}</LabeledItem>
                                    <LabeledItem label={t("roles.description")}>{role.description}</LabeledItem>
                                    <LabeledItem label={t("roles.defaultRole")}>
                                        <BooleanDisplay value={role.defaultRole} />
                                    </LabeledItem>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <AHeader title={t("roles.permissions")} variant="h2" />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="50%">{t("roles.entity")}</TableCell>
                                            <TableCell>{t("roles.permissions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {role.permissions.permissions.map((p) => (
                                            <TableRow key={p.entity}>
                                                <TableCell>{p.entity}</TableCell>
                                                <TableCell>{p.permissions.join(", ")}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
}
