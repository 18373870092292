import {Button, Container, Grid, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Comments} from "../../../base/Comments";
import {MetaDataDisplay} from "../../../base/data/MetaDataDisplay";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {CommentsProvider} from "../../../domain/CommentsProvider";
import {CurrentUserProvider} from "../../../domain/CurrentUserProvider";
import {Comment, CommentSendRequest} from "../../../domain/comments";
import {MeasureWrapper} from "../../../domain/measures";
import {IAsset, IMeasure, UserInfo} from "../../../domain/types";
import {gs} from "../../../theme";
import {MeasureView} from "./MeasureView";

interface AssetViewMeasureProps {}

export function AssetViewMeasure(props: AssetViewMeasureProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, language} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();
    const [measure, setMeasure] = useState<IMeasure>();

    const [comments, setComments] = useState<Comment[]>([]);

    const [us, setUs] = useState("");

    useEffect(() => {
        fetchMeasure();
    }, [api, tenantUuid, routeParams]);

    const measureWrapper = useMemo<MeasureWrapper | undefined>(
        () => (measure ? new MeasureWrapper(measure) : undefined),
        [measure]
    );

    const fetchMeasure = useCallback(() => {
        if (routeParams.assetUuid && routeParams.measureUuid && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.profile().then((user: UserInfo) => {
                setUs(user.username);
            });
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.measure(tenantUuid, routeParams.assetUuid, routeParams.measureUuid).then(setMeasure);
        }
        return true;
    }, [api, tenantUuid, routeParams]);

    useEffect(() => {
        fetchComments();
    }, [api, measure, routeParams.assetUuid]);

    const fetchComments = useCallback(() => {
        if (api.auth && measure && routeParams.assetUuid) {
            const commentsProvider = new CommentsProvider(api);
            commentsProvider.listForMeasure(tenantUuid, routeParams.assetUuid, measure.uuid).then((res) => {
                setComments(res);
            });
        }
        return true;
    }, [api, measure, routeParams.assetUuid]);

    const handleSendMessage = (r: CommentSendRequest, measureUuid: string): Promise<Comment> => {
        const request: CommentSendRequest = {
            comment: r.comment,
            locale: r.locale,
            measureUuid: measureUuid
        };
        const commentsProvider = new CommentsProvider(api);
        return commentsProvider.create(tenantUuid, request).then((res) => {
            fetchComments();
            return res;
        });
    };

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}`}
                onDataChangeEvent={() => fetchMeasure()}
                onUpdated={() => fetchMeasure()}
                onCreated={() => fetchMeasure()}
                onDeleted={() => fetchMeasure()}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={measureWrapper ? measureWrapper.title(language) : "..."}
                            crumbs={[
                                {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                                {
                                    label: asset ? asset.name : "...",
                                    href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`
                                },
                                {
                                    label: measureWrapper ? measureWrapper.title(language) : "...",
                                    href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}`
                                }
                            ]}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    navigate(
                                        `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}/edit`
                                    )
                                }
                            >
                                {t("edit")}
                            </Button>
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {measure && (
                        <>
                            <Grid item xs={12}>
                                <BoxedPaper>
                                    <MeasureView measure={measure} onTranslate={() => fetchMeasure()} />
                                </BoxedPaper>
                            </Grid>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures/${routeParams.measureUuid}/comments`}
                                onDataChangeEvent={() => fetchComments()}
                                onUpdated={() => fetchComments()}
                                onCreated={() => fetchComments()}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h2">{t("comments.plural")}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Comments
                                                messages={comments}
                                                performSend={(m) => handleSendMessage(m, measure!.uuid)}
                                                us={us}
                                                translationEnabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DataChangeConsumer>
                            <Grid item xs={12}>
                                <BoxedPaper>
                                    <MetaDataDisplay metaData={measure.metaData} />
                                </BoxedPaper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
