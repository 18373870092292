import React, {useCallback, useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Permission} from "../../../domain/types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {PermissionEditForm} from "./PermissionEditForm";
import Box from "@mui/material/Box";
import {gs} from "../../../theme";

interface PermissionEditDialogProps {
    open: boolean;
    onClose: () => void;
    permission: Permission;
    onChange: (permission: Permission) => void;
    onDelete?: () => void;
}

export function PermissionEditDialog(props: PermissionEditDialogProps) {
    const {open, onClose, permission, onChange, onDelete} = props;
    const {t} = useLanguageContext();

    const [editingPermission, setEditingPermission] = useState<Permission>();

    useEffect(() => {
        setEditingPermission(permission);
    }, [permission]);

    const handleApply = useCallback(() => {
        if (editingPermission) {
            onChange(editingPermission);
        }
        onClose();
    }, [onChange, editingPermission]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
            <DialogTitle>{t("roles.editPermission.title")}</DialogTitle>
            <DialogContent>
                <Box paddingTop={gs}>
                    {editingPermission &&
                        <PermissionEditForm permission={editingPermission} onChange={setEditingPermission} />
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                {onDelete &&
                    <Button variant="outlined" color="secondary" onClick={() => onDelete()}>{t("delete")}</Button>
                }
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button variant="contained" onClick={handleApply}>{t("apply")}</Button>
            </DialogActions>
        </Dialog>
    );
}