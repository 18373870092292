import {Button, Container, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DateDisplay} from "../../../base/displays/DateDisplay";
import {UserDisplay} from "../../../base/displays/UserDisplay";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../../base/table/APagingTable";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {TenantsProvider} from "../../../domain/TenantsProvider";
import {ITenant, Page, PageChange, defaultPage} from "../../../domain/types";
import {gs} from "../../../theme";
import {CreateTenantDialog} from "./CreateTenantDialog";

interface TenantsListProps {}

export function TenantsList(props: TenantsListProps) {
    const {api} = useAuthContext();
    const {tenantUuid, role} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [tenants, setTenants] = useState<Page<ITenant>>();
    const [create, setCreate] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        const tenantsProvider = new TenantsProvider(api);
        if (api.auth) {
            tenantsProvider.list(tenantUuid, pageChange).then(setTenants);
        }
    }, [api, tenantUuid, pageChange]);

    const {t, es} = useLanguageContext();
    const columns: GridColDef[] = [
        {field: "name", headerName: t("tenants.name"), flex: 1},
        {
            field: "createdAt",
            headerName: t("metaData.createdAt"),
            flex: 1,
            renderCell: (r) => <DateDisplay date={r.row.metaData.createdAt} />
        },
        {
            field: "createdBy",
            headerName: t("metaData.createdBy"),
            flex: 1,
            renderCell: (r) => <UserDisplay username={r.row.metaData.createdBy} />
        }
    ];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tenants.plural")}
                        crumbs={[{label: t("tenants.plural"), href: `/tenants/${tenantUuid}/tenants`}]}
                    >
                        {role?.canExertPermission(`/tenants`, "WRITE") && (
                            <Button variant="outlined" onClick={() => setCreate(true)}>
                                {t("tenants.create.title")}
                            </Button>
                        )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {tenants && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={tenants}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(tenant: ITenant) => navigate(`/tenants/${tenant.uuid}/view`)}
                        />
                    </Grid>
                )}
            </Grid>
            <CreateTenantDialog open={create} onClose={() => setCreate(false)} />
        </Container>
    );
}
