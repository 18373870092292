import {Api} from "../libs/Api";
import {IActivity} from "./types";

export class ActivitiesProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async listAssetActivities(tenantUuid: string, assetUuid: string): Promise<IActivity[]> {
        return this.api.get<IActivity[]>(`/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/activities`);
    }

    async listToolActivities(tenantUuid: string, toolUuid: string): Promise<IActivity[]> {
        return this.api.get<IActivity[]>(`/api/v1/tenants/${tenantUuid}/tools/${toolUuid}/activities`);
    }
}
