import React, {useCallback, useEffect, useState} from "react";
import {emptyQuestionScoreRiskTemplateModification, QuestionScoreRiskTemplateModification} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {RiskTemplateForm} from "./RiskTemplateForm";

interface TemplateAddDialogProps {
    open: boolean;
    onClose: (template?: QuestionScoreRiskTemplateModification) => void;
}

export function TemplateAddDialog(props: TemplateAddDialogProps) {
    const {open, onClose} = props;
    const {t} = useLanguageContext();

    const [editingTemplate, setEditingTemplate] = useState<QuestionScoreRiskTemplateModification>(emptyQuestionScoreRiskTemplateModification());

    useEffect(() => {
        setEditingTemplate(emptyQuestionScoreRiskTemplateModification());
    }, [open]);

    const handleApply = useCallback(() => {
        onClose(editingTemplate);
    }, [editingTemplate, onClose]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
            <DialogTitle>{t("questionnaires.question.riskTemplates.add")}</DialogTitle>
            <DialogContent>
                <RiskTemplateForm
                    value={editingTemplate}
                    onApply={setEditingTemplate}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button variant="contained" color="primary" onClick={() => handleApply()}>{t("questionnaires.question.riskTemplates.add")}</Button>
            </DialogActions>
        </Dialog>
    );
}