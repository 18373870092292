import React, {useCallback} from "react";
import {IDocumentResponse, UserInfo} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {DocumentUploadInput} from "../../base/inputs/DocumentUploadInput";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";

interface EditProfilePictureDialogProps {
    open: boolean;
    onClose: (updated?: boolean) => void;
    userInfo: UserInfo;
}

export function EditProfilePictureDialog(props: EditProfilePictureDialogProps) {
    const {open, onClose, userInfo} = props;
    const {t} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const handleUploadComplete = useCallback((document: IDocumentResponse) => {
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.updateCurrentUserProfilePicture(tenantUuid, document.document.uuid)
                .then(() => {
                    onClose(true);
                });
        }
    }, [api, tenantUuid]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
            <DialogTitle>{t("userProfile.profilePictureEdit.title")}</DialogTitle>
            <DialogContent>
                <DocumentUploadInput label="Upload" onUploadComplete={handleUploadComplete} delay={300} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}
