import React, {useEffect, useMemo, useState} from "react";
import {Answer, Question, Questionnaire} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {gs} from "../../../theme";
import {LabeledData} from "../../../base/data/LabeledData";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {QuestionnairesProvider} from "../../../domain/QuestionnairesProvider";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {AnswerDisplay} from "../../../base/displays/AnswerDisplay";
import {ALoading} from "../../../base/loading/ALoading";

interface AssetRiskQuestionProps {
    questionnaire: Questionnaire;
    answer: Answer;
}

export function AssetRiskQuestion(props: AssetRiskQuestionProps) {
    const {questionnaire, answer} = props;
    const {t} = useLanguageContext();

    const {tenantUuid} = useTenantContext();
    const {api} = useAuthContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [questions, setQuestions] = useState<Question[]>();

    useEffect(() => {
        if (api && api.auth) {
            setLoading(true);
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.questions(tenantUuid, questionnaire.slug)
                .then(setQuestions)
                .finally(() => setLoading(false));
        }
    }, [tenantUuid, questionnaire, api]);

    const question = useMemo<Question | undefined>(() => {
        if (!questions) {
            return undefined;
        }
        return questions.find((q) => q.uuid === answer.questionUuid);
    }, [questions, answer]);

    return (
        <Grid container spacing={gs}>
            {loading && (
                <Grid item xs={12}>
                    <ALoading />
                </Grid>
            )}
            {question && (
                <>
                    <Grid item xs={12}>
                        <LabeledData label={t("questionnaires.title")}>
                            <MultiLingualTextDisplay texts={question.titles} />
                        </LabeledData>
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledData label={t("questionnaires.description")}>
                            <MultiLingualTextDisplay texts={question.descriptions} />
                        </LabeledData>
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledData label={t("questionnaires.question.answer")}>
                            <AnswerDisplay question={question} answer={answer.answer} />
                        </LabeledData>
                    </Grid>
                </>
            )}
        </Grid>
    );
}