import {Api} from "../libs/Api";
import {IApiVersion, IVersion} from "./types";

export class PublicProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async version(): Promise<IVersion> {
        const apiVersion = await this.api.get<IApiVersion>('/api/v1/public/version');
        return {
            apiVersion: apiVersion.apiVersion,
            appVersion: process.env.REACT_APP_VERSION || "<not-set>"
        };
    }

}