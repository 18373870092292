import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BlockSvg} from "../../base/displays/BlockSvg";
import {TextInput} from "../../base/inputs/TextInput";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {CatalogProvider} from "../../domain/CatalogProvider";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {CatalogRequest, IAsset, IKiTool, IWebSearchResult} from "../../domain/types";
import {gs} from "../../theme";
import {WebSearchResultList} from "./steps/WebSearchResultList";
import {CatalogRequestNewWizard} from "./CatalogRequestNewWizard";

interface CatalogRequestNewProps {}

export function CatalogRequestNew(props: CatalogRequestNewProps) {
    const {t, language} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const navigate = useNavigate();

    const [tool, setTool] = useState<IKiTool>();
    const [asset, setAsset] = useState<IAsset>();
    const [needle, setNeedle] = useState<string>();

    const [searchParams, setSearchParams] = useSearchParams();

    const [requestSent, setRequestSent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const toolUuid = searchParams.get("toolUuid");
        if (toolUuid && api && api.auth && tenantUuid) {
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider.get(tenantUuid, toolUuid).then(setTool);
        }
        const assetUuid = searchParams.get("assetUuid");
        if (assetUuid && api && api.auth && tenantUuid) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, assetUuid).then(setAsset);
        }
        const needle = searchParams.get("search");
        if (needle) {
            setNeedle(needle);
        }
    }, [api, tenantUuid, searchParams]);

    const handleSubmit = useCallback(async (catalogRequest: CatalogRequest) => {
        setLoading(true);
        const catalogProvider = new CatalogProvider(api);
        return catalogProvider
            .create(tenantUuid, catalogRequest)
            .then((res) => {
                setLoading(false);
            })
            .finally(() => {
                setRequestSent(true);
            });
    }, [api, tenantUuid]);

    const handleNavigateToCatalog = () => {
        navigate(`/tenants/${tenantUuid}/catalog`);
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("catalog.request.title")}
                        crumbs={[
                            {
                                label: t("catalog.singular"),
                                href: `/tenants/${tenantUuid}/catalog`
                            },
                            {
                                label: t("catalog.request.title"),
                                href: ""
                            }
                        ]}
                    />
                </Grid>
                {!requestSent && (
                    <Grid item xs={12}>
                        <CatalogRequestNewWizard tool={tool} asset={asset} needle={needle} onSubmit={handleSubmit} />
                    </Grid>
                )}
                {requestSent && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={loading}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <BlockSvg src="/img/illu/undraw_done_i0ak.svg" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="center">
                                        {t("catalog.request.sent")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Button onClick={() => handleNavigateToCatalog()} variant="outlined">
                                            {t("catalog.request.backToCatalog")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
