import React from "react";
import {Checkbox, FormControlLabel, FormLabel} from "@mui/material";

interface SimpleCheckBoxProps {
    label: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

export function SimpleCheckBox(props: SimpleCheckBoxProps) {
    const {label, checked, onChange, disabled} = props;

    return (
        <FormControlLabel
            label={label}
            control={<Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />}
        />
    );
}