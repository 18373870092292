import {Api} from "../libs/Api";
import {ITenant, Page, PageChange} from "./types";

export class TenantsProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(authTenantUuid: string, pageChange: PageChange): Promise<Page<ITenant>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${authTenantUuid}/tenants`, pageChange);
        return this.api.get<Page<ITenant>>(url);
    }

    async get(tenantUuid: string): Promise<ITenant> {
        const url = `/api/v1/tenants/${tenantUuid}`;
        return this.api.get<ITenant>(url);
    }

    async create(authTenantUuid: string, name: string): Promise<ITenant> {
        const url = `/api/v1/tenants/${authTenantUuid}/tenants`;
        return this.api.post<ITenant>(url, {name});
    }
}
