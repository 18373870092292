import React, {PropsWithChildren, useCallback, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {HistogramInlineDisplay, HistogramSection} from "../charts/HistogramInlineDisplay";
import {gs} from "../../theme";
import {AGridItemGrow} from "../layout/AGridItemGrow";

interface KpiCardProps extends PropsWithChildren<{
    value: string | number;
    title: string;
    diagrammSections: HistogramSection[];
    diagrammLabel: string;
    diagrammSummary: string;
}> {}

export function KpiCard(props: KpiCardProps) {
    const {value, title, diagrammSections, diagrammLabel, diagrammSummary, children} = props;
    const {t} = useLanguageContext();

    const [showDetails, setShowDetails] = useState<boolean>(false);

    const handleShowDetails = useCallback(() => {
        if (children) {
            setShowDetails(!showDetails);
        }
    }, [children, showDetails]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={`${value}`} />
                    <CardContent>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <AGridItemGrow>
                                        <Typography variant="body2">{diagrammLabel}</Typography>
                                    </AGridItemGrow>
                                    <Grid item>
                                        <Typography variant="body2">{diagrammSummary}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <HistogramInlineDisplay sections={diagrammSections} onClick={handleShowDetails} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {(children && showDetails) && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
        </Grid>
    );
}