import {Api} from "../libs/Api";
import {UiTranslationRequest} from "./comments";
import {IAssetRisk, IRiskHistogram} from "./types";

export class RisksProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async topTen(tenantUuid: string): Promise<IAssetRisk[]> {
        const url = `/api/v1/tenants/${tenantUuid}/risks/top-ten`;
        return this.api.get<IAssetRisk[]>(url);
    }

    async riskHistogram(tenantUuid: string): Promise<IRiskHistogram> {
        const url = `/api/v1/tenants/${tenantUuid}/risks/histogram`;
        return this.api.get<any>(url);
    }

    async translate(tenantUuid: string, assetUuid: string, riskUuid: string, request: UiTranslationRequest) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks/${riskUuid}/translate`;
        return this.api.post(url, request);
    }

    aiActHistogram(tenantUuid: string) {
        const url = `/api/v1/tenants/${tenantUuid}/risks/ai-act`;
        return this.api.get<any>(url);
    }
}
