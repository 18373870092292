import {MenuItem, TextField} from "@mui/material";
import React, {useCallback} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Role} from "../../domain/types";

interface RoleSelecionProps {
    value: string;
    roles: Role[];
    onChange: (value: string) => void;
    disabled?: boolean;
}

export function RoleSelecion(props: RoleSelecionProps) {
    const {value, roles, onChange, disabled} = props;

    const {t} = useLanguageContext();

    const handleChange = useCallback(
        (value: string) => {
            onChange(value);
        },
        [onChange]
    );

    return (
        <TextField
            label={t("roles.singular")}
            variant="outlined"
            fullWidth
            select
            value={value}
            disabled={disabled}
            onChange={(event) => handleChange(event.target.value)}
        >
            {roles.map((role) => (
                <MenuItem key={role.uuid} value={role.uuid} selected={value === role.uuid}>
                    {role.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
