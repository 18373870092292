import {Alert, Button, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {SimpleCheckBox} from "../../../base/inputs/SimpleCheckBox";
import {TextInput} from "../../../base/inputs/TextInput";
import {AHeader} from "../../../base/layout/AHeader";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {Permission, Permissions, RoleModification} from "../../../domain/types";
import {gs} from "../../../theme";
import {PermissionEditDialog} from "./PermissionEditDialog";

interface RoleFormProps {
    role: RoleModification;
    onChange: (value: RoleModification) => void;
}

export function RoleForm(props: RoleFormProps) {
    const {role, onChange} = props;

    const [defaultOnLoad, setDefaultOnLoad] = useState<boolean | undefined>(role.defaultRole);

    const [defaultRole, setDefaultRole] = useState<boolean>(Boolean(role.defaultRole));
    const [name, setName] = useState<string>(role.name);
    const [description, setDescription] = useState<string>(role.description);
    const [permissions, setPermissions] = useState<Permissions>(role.permissions);

    const [selectedPermission, setSelectedPermission] = useState<number>();

    const {tenantUuid} = useTenantContext();

    useEffect(() => {
        setName(role.name);
    }, [role]);

    const handleRoleChanged = useCallback(
        (defaultRole: boolean) => {
            setDefaultRole(defaultRole);
            onChange({defaultRole, name, description, permissions});
        },
        [onChange, description, permissions]
    );

    const handleNameChanged = useCallback(
        (name: string) => {
            setName(name);
            onChange({defaultRole, name, description, permissions});
        },
        [onChange, description, permissions]
    );

    const handleDescriptionChanged = useCallback(
        (description: string) => {
            setDescription(description);
            onChange({defaultRole, name, description, permissions});
        },
        [onChange, name, permissions]
    );

    const handlePermissionChanged = useCallback(
        (permission: Permission) => {
            if (selectedPermission != undefined) {
                permissions.permissions[selectedPermission] = permission;
                onChange({defaultRole, name, description, permissions});
            }
        },
        [onChange, name, description, selectedPermission]
    );

    const handleAddPermission = useCallback(() => {
        const newPermission: Permission = {entity: `/tenants/${tenantUuid}`, permissions: ["READ"]};
        permissions.permissions.push(newPermission);
        setPermissions({...permissions});
        setSelectedPermission(permissions.permissions.length - 1);
    }, [permissions, tenantUuid]);

    const handleRemovePermission = useCallback(() => {
        if (selectedPermission != undefined) {
            permissions.permissions.splice(selectedPermission, 1);
            setPermissions({...permissions});
            setSelectedPermission(undefined);
        }
    }, [permissions, selectedPermission]);

    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <TextInput label={t("roles.name")} value={name} onChange={handleNameChanged} />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("roles.description")}
                    value={description}
                    onChange={handleDescriptionChanged}
                    rows={5}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleCheckBox
                    checked={role.defaultRole ? role.defaultRole : false}
                    disabled={defaultOnLoad}
                    label={t("roles.defaultRole")}
                    onChange={(v) => handleRoleChanged(v)}
                />
                {defaultOnLoad && <Alert severity="warning">{t("roles.defaultRoleMessage")} </Alert>}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <AHeader title={t("roles.permissions")} variant="h2">
                    <Button variant="outlined" onClick={() => handleAddPermission()}>
                        {t("roles.addPermission.title")}
                    </Button>
                </AHeader>
            </Grid>
            <Grid item xs={12}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width="50%">{t("roles.entity")}</TableCell>
                            <TableCell>{t("roles.permissions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {role.permissions.permissions.map((p, index) => (
                            <TableRow key={p.entity} hover onClick={() => setSelectedPermission(index)}>
                                <TableCell>{p.entity}</TableCell>
                                <TableCell>{p.permissions.join(", ")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <PermissionEditDialog
                open={selectedPermission !== undefined}
                onClose={() => setSelectedPermission(undefined)}
                permission={role.permissions.permissions[selectedPermission || 0]}
                onChange={handlePermissionChanged}
                onDelete={permissions.permissions.length > 1 ? () => handleRemovePermission() : undefined}
            />
        </Grid>
    );
}
