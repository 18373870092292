import {Button, Chip, Grid} from "@mui/material";
import Container from "@mui/material/Container";
import {GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {IAsset, Page, PageChange, defaultPage} from "../../domain/types";
import {calculateContrastRatio, stateColor} from "../../libs/tools";
import {gs} from "../../theme";

interface AssetsListProps {}

export function AssetsList(props: AssetsListProps) {
    const {api} = useAuthContext();
    const {tenantUuid, role} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [assets, setAssets] = useState<Page<IAsset>>();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (api.auth) {
            fetchAssets();
        }
    }, [api, tenantUuid, pageChange]);

    const fetchAssets = useCallback(() => {
        const assetsProvider = new AssetsProvider(api);
        assetsProvider.list(tenantUuid, pageChange).then(setAssets);
        return true;
    }, [api, tenantUuid, pageChange]);

    const {t, es} = useLanguageContext();
    const columns: GridColDef[] = [
        {
            field: "state",
            headerName: t("assets.headerNames.state"),
            renderCell: (params) => (
                <Chip
                    label={es("assets.states", params.value)}
                    sx={{
                        background: stateColor(params.value),
                        color: calculateContrastRatio(stateColor(params.value))
                    }}
                />
            )
        },
        {field: "name", headerName: t("assets.headerNames.name"), flex: 1},
        {
            field: "category",
            headerName: t("assets.headerNames.category"),
            flex: 1,
            renderCell: (params) => es("tools.toolCategory", params.value)
        },
        {
            field: "type",
            headerName: t("assets.headerNames.type"),
            flex: 1,
            renderCell: (params) => es("tools.toolType", params.value)
        },
        {field: "vendor", headerName: t("assets.headerNames.vendor"), flex: 1}
    ];

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets`}
                onDataChangeEvent={() => fetchAssets()}
                onCreated={() => fetchAssets()}
                onUpdated={() => fetchAssets()}
                onDeleted={() => fetchAssets()}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={t("assets.plural")}
                            crumbs={[{label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`}]}
                        >
                            {role?.canExertPermission(`/tenants/${tenantUuid}/assets`, "WRITE") && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/tenants/${tenantUuid}/assets/add`)}
                                >
                                    {t("assets.addAsset")}
                                </Button>
                            )}
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {assets && (
                        <Grid item xs={12}>
                            <APagingTable
                                page={assets}
                                columns={columns}
                                onPageChanged={setPageChange}
                                onClick={(asset: IAsset) => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`)}
                                onSelectionChanged={setSelected}
                            />
                        </Grid>
                    )}
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
