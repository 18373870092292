import React, {useMemo} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";

export interface HistogramSection {
    label: string;
    color: string;
    value: number;
}

interface HistogramInlineDisplayProps {
    sections: HistogramSection[];
    onClick?: () => void;
}

export function HistogramInlineDisplay(props: HistogramInlineDisplayProps) {
    const {sections, onClick} = props;
    const {t} = useLanguageContext();

    const totalSum = useMemo<number>(() => sections.reduce((acc, curr) => acc + curr.value, 0), [sections]);
    const numberOfItems = useMemo<number>(() => sections.length, [sections]);

    if (sections.length == 0) {
        return null;
    }

    return (
        <Grid
            container
            style={{
                flexWrap: "nowrap",
                alignItems: "center"
            }}
            onClick={() => onClick && onClick()}
        >
            {sections.map((d, index) => {
                let borderRadius;
                if (numberOfItems === 1) {
                    borderRadius = "8px";
                } else {
                    if (index === 0) {
                        borderRadius = "8px 0 0 8px";
                    } else if (index === numberOfItems - 1) {
                        borderRadius = "0 8px 8px 0";
                    } else {
                        borderRadius = undefined;
                    }
                }
                return (
                    <Box
                        key={index}
                        title={d.label}
                        style={{
                            background: d.color,
                            width: `${(d.value / totalSum) * 100}%`,
                            height: "12px",
                            borderRadius: borderRadius || undefined
                        }}
                    />
                );
            })}
        </Grid>

    );
}