import {Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Hidden, Typography} from "@mui/material";
import * as React from "react";
import {useAuthContext} from "../context/auth/AuthContext";
import {gs} from "../theme";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLanguageContext} from "../context/language/LanguageContext";
import {AHeaderWithBreadcrumbs} from "../base/layout/AHeaderWithBreadcrumbs";

interface IndexProps {}

export function Index(props: IndexProps) {
    const {api} = useAuthContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();
    const [checking, setChecking] = useState<boolean>(true);

    useEffect(() => {
        if (api.auth && api.auth.isLoggedIn) {
            navigate("/current-user/tenant-select");
        }
        setChecking(false);
    }, [api]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                {checking &&
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs title={t("checking")} crumbs={[]} />
                    </Grid>
                }
                {!checking && (
                    <>
                        <Grid item xs={12}>
                            <AHeaderWithBreadcrumbs title={t("home.title")} crumbs={[]} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardHeader title={t("home.card.title")} />
                                <CardContent>
                                    <Typography variant="body1">{t("home.card.info")}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="outlined" color="primary" onClick={() => navigate("/auth/login")}>
                                        {t("home.card.signup")}
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={() => navigate("/auth/login")}>
                                        {t("login.singular")}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item xs={12} sm={6} md={8}>
                                <img alt="Visual for login" src="/undraw_japan_ubgk.svg" style={{width: "85%", marginLeft: "auto", marginRight: "auto", display: "block"}} />
                            </Grid>
                        </Hidden>
                    </>
                )}
            </Grid>
        </Container>
    );
}
