import {
    Divider,
    Grid,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import React, {MouseEvent, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BellIcon} from "../base/BellIcon";
import {UserAvatar} from "../base/avatars/UserAvatar";
import {DateDisplay} from "../base/displays/DateDisplay";
import {useAuthContext} from "../context/auth/AuthContext";
import {DataChangeConsumer} from "../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../context/language/LanguageContext";
import {useTenantContext} from "../context/tenant/TenantContext";
import {NotificationsProvider} from "../domain/NotificationsProvider";
import {
    Notification,
    NotificationCreationRequest,
    NotificationWrapper,
    NotificationsWrapper
} from "../domain/notifications";
import {luxonDate} from "../libs/tools";
import theme from "../theme";

type Props = {};

export function NotificationsBubble({}: Props) {
    const [notifications, setNotifications] = useState<NotificationsWrapper>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {api} = useAuthContext();
    const {tenantUuid, role} = useTenantContext();
    const navigate = useNavigate();

    const loadNotifications = useCallback(() => {
        const notificationsProvider = new NotificationsProvider(api);
        notificationsProvider.list(tenantUuid).then((res) => {
            const wrapper = new NotificationsWrapper(res.content);
            setNotifications(wrapper);
        });
        return true;
    }, [api, tenantUuid]);

    useEffect(() => {
        loadNotifications();
    }, []);

    const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [notifications, loadNotifications]);

    const handleNavigateDetails = useCallback(() => {
        handleClose();
        navigate(`/tenants/${tenantUuid}/notifications`);
    }, []);

    const handleMarkReadSingleNotification = useCallback((uuid: string) => {
        const request: NotificationCreationRequest = {
            notificationUuids: [uuid]
        };

        const notificationProvider = new NotificationsProvider(api);
        notificationProvider.markRead(tenantUuid, request);
    }, []);

    const handleNavigateToNotificationDetails = useCallback((notification: Notification) => {
        let url = `/tenants/${tenantUuid}/`;
        if (notification.tool) {
            url += `tools/${notification.tool.uuid}`;
        }

        if (notification.asset) {
            url += `assets/${notification.asset.uuid}`;
        }

        if (notification.assetRisk) {
            url += `assets/${notification.assetRisk.asset.uuid}/risks/${notification.assetRisk.uuid}`;
        }

        if (notification.measure) {
            url += `assets/${notification.measure.asset.uuid}/measures/${notification.measure.uuid}`;
        }

        if (notification.task) {
            url += `assets/${notification.measure.asset.uuid}/tasks/${notification.task.uuid}`;
        }

        handleMarkReadSingleNotification(notification.uuid);
        navigate(url);

        handleClose();
    }, []);

    const {t} = useLanguageContext();
    return (
        <>
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets`}
                onDataChangeEvent={() => loadNotifications()}
                onCreated={() => loadNotifications()}
            >
                <IconButton color="inherit" onClick={handleOpen}>
                    <BellIcon numberOfNotifications={notifications ? notifications.unread.length : 0} />
                </IconButton>

                <Menu
                    id="notifications-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                >
                    {notifications && notifications.unread.length == 0 && (
                        <MenuItem disabled>{t("notifications.noNotifications")}</MenuItem>
                    )}

                    <List disablePadding>
                        {notifications &&
                            notifications.unread
                                .sort(
                                    (a, b) =>
                                        luxonDate(b.notification.metaData.createdAt).toMillis() -
                                        luxonDate(a.notification.metaData.createdAt).toMillis()
                                )
                                .slice(0, 7)
                                .map((notification) => (
                                    <ListItemButton
                                        alignItems="flex-start"
                                        key={notification.notification.uuid}
                                        onClick={() => handleNavigateToNotificationDetails(notification.notification)}
                                    >
                                        <ListItemAvatar>
                                            <UserAvatar username={notification.notification.metaData.createdBy} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={notification.notification.subject}
                                            secondary={
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={{color: "text.primary", display: "inline"}}
                                                        >
                                                            {notification.notification.shortContent}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DateDisplay
                                                            date={notification.notification.metaData.createdAt}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                    </List>

                    {notifications && <Divider />}
                    {notifications && (
                        <MenuItem onClick={handleNavigateDetails}>
                            <ListItemText style={{paddingLeft: theme.spacing(4)}}>
                                {t("notifications.viewAll", {n: notifications.notifications.length})}
                            </ListItemText>
                        </MenuItem>
                    )}
                </Menu>
            </DataChangeConsumer>
        </>
    );
}

interface NotificationMenuItemProps {
    notification: NotificationWrapper;
    onClick: () => void;
}

function NotificationMenuItem(props: NotificationMenuItemProps) {
    const {t} = useLanguageContext();
    const {notification, onClick} = props;

    const label = useMemo<string>(() => {
        switch (notification.notification.type) {
            default:
                return notification.notification.subject;
        }
    }, [t, notification]);

    const handleNavigate = useCallback(() => {
        onClick();
        console.log("handle nacigate");

        // uiStateStore.go(`/compliance${notification.notification.path}`);
    }, [notification]);

    return (
        <MenuItem onClick={handleNavigate}>
            {/* {NotificationTypeDisplayIconLookup(notification.notification.notificationType)} */}
            icon
            {/* <span className={classes.label}>{label}</span> */}
            {label}
        </MenuItem>
    );
}
