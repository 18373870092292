import {MenuItem, TextField} from "@mui/material";
import React, {useCallback, useMemo} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MultilingualText, MultilingualTextWrapper, Question, QuestionOption} from "../../domain/types";

interface MultipleChoiceSingleAnswerInputProps {
    label?: string;
    placeholder?: string;
    question: Question;
    value: string;
    onChange: (value: string) => void;
}

export function MultipleChoiceSingleAnswerInput(props: MultipleChoiceSingleAnswerInputProps) {
    const {label, placeholder, question, value, onChange} = props;
    const options = question.options || [];
    const {language} = useLanguageContext();

    const handleOptionChanged = useCallback(
        (value: string) => {
            onChange(value);
        },
        [onChange]
    );

    const allSlugs = useMemo<string[]>(() => {
        const set = new Set(options.map((o) => o.slug));
        return Array.from(set);
    }, [options]);

    const actualOptions = useMemo<QuestionOption[]>(() => {
        const result: QuestionOption[] = [];
        for (let slug of allSlugs) {
            let option = options.find((o) => o.slug == slug && o.locale == language);
            if (option) {
                result.push(option);
            } else {
                option = options.find((o) => o.slug == slug);
                if (option) {
                    result.push(option);
                } else {
                    throw new Error(`We cannot end in this situation`);
                }
            }
        }
        return result;
    }, [options, allSlugs, language]);

    return (
        <TextField
            variant="outlined"
            fullWidth
            label={(!value && placeholder) ? placeholder : label}
            select
            value={value}
            onChange={(e) => handleOptionChanged(e.target.value)}
        >
            {actualOptions.map((o) => (
                <MenuItem key={o.slug} value={o.slug}>
                    {o.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
