import {Grid, List, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BlockSvg} from "../../base/displays/BlockSvg";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {ALoading} from "../../base/loading/ALoading";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {ITenant} from "../../domain/types";
import {gs} from "../../theme";

interface TenantSelectProps {}

export function TenantSelect(props: TenantSelectProps) {
    const {api} = useAuthContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tenants, setTenants] = useState<ITenant[]>();

    useEffect(() => {
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenants().then((tenants) => {
                setTenants(tenants);
                if (tenants.length == 1) {
                    navigate(`/tenants/${tenants[0].uuid}`);
                }
            });
        }
    }, [api]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tenant.select")}
                        crumbs={[{label: t("logout.singular"), href: "/auth/logout"}]}
                    />
                </Grid>

                <Grid item xs={12}>
                    {!tenants && <ALoading />}
                    {tenants && tenants.length == 0 && (
                        <BoxedPaper>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <BlockSvg src="/img/illu/undraw_taken_mshk.svg" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center">
                                        {t("tenants.empty.info")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="center">
                                        {t("tenants.empty.message")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    )}
                    {tenants && tenants.length > 0 && (
                        <Paper>
                            <List>
                                {tenants.map((tenant) => (
                                    <ListItemButton
                                        key={tenant.uuid}
                                        onClick={() => navigate(`/tenants/${tenant.uuid}`)}
                                    >
                                        <ListItemText primary={tenant.name} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
