import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Typography} from "@mui/material";
import {useAuthContext} from "../../context/auth/AuthContext";
import {IVersion} from "../../domain/types";
import {PublicProvider} from "../../domain/PublicProvider";

interface VersionDisplayProps {

}

export function VersionDisplay(props: VersionDisplayProps) {
    const {t} = useLanguageContext();
    const {api} = useAuthContext();

    const [version, setVersion] = useState<IVersion>();

    useEffect(() => {
        if (api) {
            const publicProvider = new PublicProvider(api);
            publicProvider.version().then(setVersion);
        }
    }, []);

    if (!version) {
        return null;
    }

    return (
        <Typography variant="body2">
            {`${t("version.apiVersion")}: ${version.apiVersion} - ${t("version.appVersion")}: ${version.appVersion}`}
        </Typography>
    );
}