import React, {useCallback, useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {
    emptyQuestionScore,
    Question,
    QuestionScore,
    QuestionScoreRiskTemplate
} from "../../../domain/types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ScoreForm} from "./ScoreForm";

interface ScoreAddDialogProps {
    open: boolean;
    question: Question;
    onClose: (score?: QuestionScore, templates?: QuestionScoreRiskTemplate[]) => void;
}

export function ScoreAddDialog(props: ScoreAddDialogProps) {
    const {open, question, onClose} = props;
    const {t} = useLanguageContext();

    const [editingScore, setEditingScore] = useState<QuestionScore>(emptyQuestionScore());
    const [editingTemplates, setEditingTemplates] = useState<QuestionScoreRiskTemplate[]>([]);

    useEffect(() => {
        setEditingScore(emptyQuestionScore());
        setEditingTemplates([]);
    }, [open]);

    const handleApply = useCallback(() => {
        onClose(editingScore, editingTemplates);
    }, [editingScore, editingTemplates, onClose]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
            <DialogTitle>{t("questionnaires.scores.add")}</DialogTitle>
            <DialogContent>
                <ScoreForm
                    question={question}
                    score={editingScore}
                    onApplyScore={setEditingScore}
                    templates={editingTemplates}
                    onApplyTemplates={setEditingTemplates}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button variant="contained" color="primary" onClick={() => handleApply()}>{t("questionnaires.scores.add")}</Button>
            </DialogActions>
        </Dialog>
    );
}