import {Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Snackbar, SnackbarContent} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LabeledItem} from "../../base/data/LabeledItem";
import {AddressDisplay} from "../../base/displays/AddressDisplay";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {RoleDisplay} from "../../base/displays/RoleDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {ITenant, UserInfo} from "../../domain/types";
import {gs} from "../../theme";
import {EditProfilePictureDialog} from "./EditProfilePictureDialog";
import {UserAvatar} from "../../base/avatars/UserAvatar";

interface UserProfileProps {}

export function UserProfile(props: UserProfileProps) {
    const {t} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid, role} = useTenantContext();

    const navigate = useNavigate();

    const [profile, setProfile] = useState<UserInfo>();
    const [tenant, setTenant] = useState<ITenant>();
    const [editProfilePicture, setEditProfilePicture] = useState<boolean>(false);
    const [profilePictureUpdated, setProfilePictureUpdated] = useState<boolean>(false);
    const [noCache, setNoCache] = useState<boolean>(false);

    const fetchProfile = useCallback((noCache?: boolean) => {
        const currentUserProvider = new CurrentUserProvider(api);
        currentUserProvider.profile().then(setProfile);
        if (noCache) {
            setNoCache(true);
        }
    }, [api]);

    useEffect(() => {
        fetchProfile();
    }, [api]);

    useEffect(() => {
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(tenantUuid).then(setTenant);
        }
    }, [api, tenantUuid]);

    const handleCloseEditProfilePicture = useCallback((updated?: boolean) => {
        setEditProfilePicture(false);
        if (updated) {
            fetchProfile(true);
            setProfilePictureUpdated(true);
        }
    }, [fetchProfile]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("userProfile.title")}
                        crumbs={[
                            {
                                label: t("userProfile.title"),
                                href: "/current-user/profile"
                            }
                        ]}
                    />
                </Grid>
                {profile && (
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <UserAvatar username={profile.username} onClick={() => setEditProfilePicture(true)} noCache={noCache}  />
                                }
                                title={`${profile.givenName} ${profile.name}`}
                            />
                            <CardContent>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("userProfile.givenName")}>{profile.givenName}</LabeledItem>
                                    <LabeledItem label={t("userProfile.name")}>{profile.name}</LabeledItem>
                                    <LabeledItem label={t("userProfile.email")}>{profile.email}</LabeledItem>
                                    {role && (
                                        <LabeledItem label={t("userProfile.role")}>
                                            <RoleDisplay role={role.role} />
                                        </LabeledItem>
                                    )}
                                </Grid>
                            </CardContent>
                            <EditProfilePictureDialog
                                open={editProfilePicture}
                                onClose={(updated) => handleCloseEditProfilePicture(updated)}
                                userInfo={profile}
                            />
                            <Snackbar open={profilePictureUpdated}
                                      anchorOrigin={{vertical: "top", horizontal: "center"}}
                                      autoHideDuration={30000}
                                      onClose={() => setProfilePictureUpdated(false)}
                                      message={t("userProfile.profilePictureEdit.cacheInfo")} />
                        </Card>
                    </Grid>
                )}
                {tenant && (
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader title={tenant.name} />
                            <CardContent>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("tenant.singular")}>{tenant.name}</LabeledItem>

                                    <LabeledItem label={t("tenant.companySize")}>{tenant.companySize}</LabeledItem>

                                    <LabeledItem label={t("tenant.industry")}>
                                        <MultiLingualTextDisplay texts={tenant.industry.names} />
                                    </LabeledItem>

                                    {tenant.address && (
                                        <LabeledItem label={t("tenant.address")}>
                                            <AddressDisplay address={tenant.address} />
                                        </LabeledItem>
                                    )}

                                    <LabeledItem label={t("tenant.defaultLocale")}>{tenant.defaultLocale}</LabeledItem>

                                    <LabeledItem label={t("tenant.locales")}>
                                        <Grid container spacing={1}>
                                            {tenant.locales.map((locale, index) => (
                                                <Grid item key={index}>
                                                    <Chip label={locale} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </LabeledItem>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                {role?.canExertPermission(`/tenants/${tenantUuid}`, "WRITE") && (
                                    <Button onClick={() => navigate(`/tenants/${tenant.uuid}/edit`)}>
                                        {t("tenant.edit.title")}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
