import {Api} from "../libs/Api";
import {ITenant, Role, TenantModification, TenantUserUpdateLocaleRequest, UserInfo} from "./types";

export class CurrentUserProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async profile(): Promise<UserInfo> {
        return this.api.get<UserInfo>("/api/v1/current-user");
    }

    async tenants(): Promise<ITenant[]> {
        return this.api.get<ITenant[]>("/api/v1/current-user/tenants");
    }

    async tenant(tenantUuid: string): Promise<ITenant> {
        return this.api.get<ITenant>(`/api/v1/current-user/tenants/${tenantUuid}`);
    }

    async updateTenant(tenantUuid: string, request: TenantModification): Promise<ITenant> {
        return this.api.put<ITenant>(`/api/v1/current-user/tenants/${tenantUuid}`, request);
    }

    async role(tenantUuid: string): Promise<Role> {
        return this.api.get<Role>(`/api/v1/current-user/tenants/${tenantUuid}/role`);
    }

    async updateCurentUserLocale(tenantUuid: string, request: TenantUserUpdateLocaleRequest): Promise<void> {
        return this.api.put(`/api/v1/current-user/tenants/${tenantUuid}/locale `, request);
    }

    async updateCurrentUserProfilePicture(tenantUuid: string, documentUuid: string): Promise<void> {
        return this.api.put(`/api/v1/current-user/tenants/${tenantUuid}/profile-picture`, {documentUuid});
    }
}
