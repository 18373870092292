import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton, ListItemSecondaryAction,
    ListItemText,
    OutlinedInput,
    Paper,
    Typography
} from "@mui/material";
import {GridSearchIcon} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {ALoading} from "../../base/loading/ALoading";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CatalogProvider} from "../../domain/CatalogProvider";
import {AssetState, CatalogEntry, CatalogResult, MultilingualTextWrapper} from "../../domain/types";
import {calculateContrastRatio, maxLen, stateColor} from "../../libs/tools";
import {gs} from "../../theme";
import {DocumentToolAvatar} from "../../base/displays/ToolAvatar";

interface CatalogCacheElement {
    needle: string;
    catalog: CatalogResult;
}

interface CatalogProps {}

export function Catalog(props: CatalogProps) {
    const {t, es, language} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [needle, setNeedle] = useState("");

    const [catalog, setCatalog] = useState<CatalogResult>();

    useEffect(() => {
        const localStorageCached = localStorage.getItem("artiligence-catalog");
        if (localStorageCached) {
            const cache: CatalogCacheElement = JSON.parse(localStorageCached);
            setNeedle(cache.needle);
            setCatalog(cache.catalog);
        }
    }, []);

    let debouncedSearchTimeoutId: any;

    const handleSearch = useCallback(
        (value: string) => {
            if (api.auth) {
                clearTimeout(debouncedSearchTimeoutId);
                const catalogProvider = new CatalogProvider(api);
                catalogProvider
                    .search(tenantUuid, value)
                    .then((results) => {
                        setCatalog(results);
                        localStorage.setItem(
                            "artiligence-catalog",
                            JSON.stringify({
                                needle: value,
                                catalog: results
                            })
                        );
                    })
                    .finally(() => setLoading(false));
            }
        },
        [api, tenantUuid]
    );

    const debouncedHandleSearch = useCallback(
        (value: string) => {
            setNeedle(value);
            clearTimeout(debouncedSearchTimeoutId);

            if (value.length > 2) {
                setLoading(true);
            }

            debouncedSearchTimeoutId = setTimeout(() => {
                if (value.length > 2) {
                    handleSearch(value);
                }
            }, 1000);
        },
        [handleSearch]
    );

    const handleAssetClick = (entry: CatalogEntry) => {
        switch (entry.state) {
            case AssetState.New:
            case AssetState.Declined:
                const module = entry.entity + "Uuid";
                navigate(`request-new?${module}=${entry.uuid}`);
                break;
            case AssetState.Approved:
            case AssetState.ConditionallyApproved:
            case AssetState.Review:
                navigate(`${entry.entity}/${entry.uuid}`);
                break;
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs} justifyContent="center">
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("catalog.singular")}
                        crumbs={[
                            {
                                label: t("catalog.singular"),
                                href: `/tenants/${tenantUuid}/catalog`
                            }
                        ]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="search">{t("search.singular")}</InputLabel>
                        <OutlinedInput
                            id="search"
                            type="text"
                            value={needle}
                            onChange={(e) => debouncedHandleSearch(e.target.value)}
                            placeholder={t("search.placeholder")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label={t("search.singular")} edge="end">
                                        <GridSearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={t("search.singular")}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    {loading && <ALoading />}
                    {(!loading && catalog) && (
                        <Paper>
                            <List sx={{padding: 0}}>
                                {(!catalog.entries || catalog.entries.length === 0) && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Typography align={"center"}>
                                                    {needle.length > 2 ?
                                                        t("catalog.entities.none", {searchTerm: needle}) :
                                                        t("catalog.entities.startTyping")
                                                    }
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                )}

                                {catalog.entries &&
                                    catalog.entries.map((item) => (
                                        <ListItemButton
                                            key={item.uuid}
                                            alignItems="center"
                                            onClick={() => handleAssetClick(item)}
                                        >
                                            <ListItemAvatar>
                                                <DocumentToolAvatar name={item.name} documentUuid={item.favicon ? item.favicon.uuid : undefined} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name} secondary={maxLen(new MultilingualTextWrapper(item.descriptions).resolved(language), 140)} />
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={es("assets.states", item.state)}
                                                    sx={{
                                                        background: stateColor(item.state as AssetState),
                                                        color: calculateContrastRatio(
                                                            stateColor(item.state as AssetState)
                                                        )
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                    ))}

                                {catalog.entries && (
                                    <Box p={gs}>
                                        <Grid container justifyContent="center" alignItems="center" spacing={gs}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" textAlign="center">
                                                    {t("catalog.entities.notInList")}
                                                </Typography>
                                            </Grid>

                                            <Grid item>
                                                <Button variant="outlined" onClick={() => navigate(`request-new?search=${needle}`)}>
                                                    {t("catalog.entities.requestTool")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </List>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
