import {Container, Grid} from "@mui/material";
import * as React from "react";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {gs} from "../../theme";
import {AssetRiskWizard, AssetRiskWizardMode} from "./add/AssetRiskWizard";

interface AssetAddProps {}

export function AssetAdd(props: AssetAddProps) {
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("assets.addAsset")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {label: t("assets.addAsset"), href: `/tenants/${tenantUuid}/assets/add`}
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <AssetRiskWizard mode={AssetRiskWizardMode.AddAsset} />
                </Grid>
            </Grid>
        </Container>
    );
}
