import {Button, styled} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MultilingualText, MultilingualTextWrapper} from "../../domain/types";

interface MultiLingualTextDisplayProps {
    texts: MultilingualText[];
    onTranslate?: (targetLanguage: string) => Promise<void>;
}

const MiniControlSpan = styled("span")(({theme}) => ({
    cursor: "pointer",
    fontSize: "0.75em",
    color: theme.palette.secondary.main
}));

export function MultiLingualTextDisplay(props: MultiLingualTextDisplayProps) {
    const {texts, onTranslate} = props;
    const {t, es, language} = useLanguageContext();

    const [translating, setTranslating] = useState(false);

    const targetedLanguage = useMemo<string>(() => es("languages", language), [language]);

    const resolvedText = useMemo<MultilingualText | undefined>(
        () => new MultilingualTextWrapper(texts).resolvedText(language),
        [texts, language]
    );

    const resolveLanguage = useCallback((language: string) => {
        return es("languages", language);
    }, [es]);

    const handleTranslate = useCallback(()=> {
        if (onTranslate !== undefined) {
            setTranslating(true);
            onTranslate(language).finally(() => {
                setTranslating(false);
            });
        }
    }, [onTranslate, language]);

    if (!resolvedText) {
        return null;
    }

    return (
        <span>
            {resolvedText.text}
            {(onTranslate != undefined && resolvedText.locale != language) && (
                <MiniControlSpan onClick={() => !translating && handleTranslate()}>
                    <br />
                    {translating ?
                        t("translations.translating", {
                            source: resolveLanguage(resolvedText.locale),
                            target: resolveLanguage(targetedLanguage)
                        }) :
                        t("translations.translate", {target: targetedLanguage})
                    }
                </MiniControlSpan>
            )}
        </span>
    );
}
