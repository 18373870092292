import {IDocumentResponse} from "./types";

export class DocumentUploader {

    readonly file: File;
    readonly uploadUrl: IDocumentResponse;

    private uploadPromiseResolver?: ((value?: void | PromiseLike<void> | undefined) => void);
    private uploadPromiseRejecter?: ((reason?: any) => void) ;

    private progressUpdaters: ((value: number) => void)[] = [];

    constructor(file: File, uploadUrl: IDocumentResponse) {
        this.file = file;
        this.uploadUrl = uploadUrl;
    }

    private uploadFinished(event: ProgressEvent) {
        if (this.uploadPromiseResolver) {
            this.uploadPromiseResolver();
        }
    }

    private uploadFailed(event: ProgressEvent) {
        if (this.uploadPromiseRejecter) {
            this.uploadPromiseRejecter("Upload Failed");
        }
    }

    private updateProgress(event: ProgressEvent) {
        const progress = Math.floor(event.loaded / event.total * 100);
        this.progressUpdaters.forEach((updater) => updater(progress));
    }

    onProgress(progressUpdater: (value: number) => void) {
        this.progressUpdaters.push(progressUpdater);
    }

    async upload(): Promise<void> {
        if (this.uploadUrl.url.readonly) {
            throw new Error(`The URL ${this.uploadUrl.url.url} is readonly`);
        }
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
            this.uploadFinished(event);
        };
        xhr.onabort = (event) => {
            this.uploadFailed(event);
        };
        if (xhr.upload) {
            xhr.upload.onprogress = (event) => {
                this.updateProgress(event);
            };
        }
        xhr.open("PUT", this.uploadUrl.url.url);
        xhr.setRequestHeader("Content-Type", this.file.type);
        xhr.send(this.file);
        return new Promise((resolve, reject) => {
            this.uploadPromiseResolver = resolve;
            this.uploadPromiseRejecter = reject;
        });
    }

}