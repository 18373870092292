import * as React from "react";
import {gs} from "../../theme";
import {Divider, Grid, Typography} from "@mui/material";
import {Answer, IAssetRisk, Question} from "../../domain/types";
import {AnswerInput} from "./AnswerInput";
import {useCallback} from "react";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {LabeledData} from "../../base/data/LabeledData";
import {RisksList} from "../../pages/assets/add/RisksList";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface AnswerQuestionFormProps {
    question: Question;
    answer?: Answer;
    onAnswer: (answer: Answer) => void;
    risks: IAssetRisk[];
}

export function AnswerQuestionForm(props: AnswerQuestionFormProps) {
    const {question, answer, onAnswer, risks} = props;

    const {t} = useLanguageContext();

    const handleAnswer = useCallback((value: string) => {
        onAnswer({ questionUuid: question.uuid, answer: value });
    }, [question]);

    return (
        <Grid container spacing={gs}>
            {question.titles.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <MultiLingualTextDisplay texts={question.titles} />
                    </Typography>
                </Grid>
            }
            {question.descriptions.length > 0 &&
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <MultiLingualTextDisplay texts={question.descriptions} />
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <AnswerInput question={question} value={answer ? answer.answer : ""} onChange={(value) => handleAnswer(value)} />
            </Grid>
            {risks.length > 0 && (
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledData label={t("risks.plural")}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <RisksList risks={risks} />
                                </Grid>
                            </Grid>
                        </LabeledData>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
