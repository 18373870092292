import {Button, Container, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {RoleWrapper} from "../../domain/permissions";
import {Page, PageChange, TenantUserInfo, defaultPage} from "../../domain/types";
import {gs} from "../../theme";

interface UsersListProps {}

export function UsersList(props: UsersListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [tenantUsers, setTenantUsers] = useState<Page<TenantUserInfo>>();

    const [role, setRole] = useState<RoleWrapper>();

    useEffect(() => {
        if (api.auth && api.auth.isLoggedIn) {
            const usersProvider = new UsersProvider(api);
            usersProvider.listTenantUsers(tenantUuid, pageChange).then(setTenantUsers);

            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.role(tenantUuid).then((role) => {
                setRole(new RoleWrapper(role));
            });
        }
    }, [api, tenantUuid]);

    const {t} = useLanguageContext();
    const columns: GridColDef[] = [
        {
            field: "user.givenName",
            headerName: t("users.givenName"),
            flex: 1,
            renderCell: (params) => params.row.user.givenName
        },
        {field: "user.name", headerName: t("users.name"), flex: 1, renderCell: (params) => params.row.user.name},
        {field: "user.email", headerName: t("users.email"), flex: 1, renderCell: (params) => params.row.user.email},
        {
            field: "tenantUser.role.name",
            headerName: t("roles.singular"),
            flex: 1,
            renderCell: (params) => params.row.tenantUser.role.name
        }
    ];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("users.plural")}
                        crumbs={[{label: t("users.plural"), href: `/tenants/${tenantUuid}/users`}]}
                    >
                        {role && role.canExertPermission(`/tenants/${tenantUuid}/users`, "WRITE") && (
                            <Button variant="outlined" onClick={() => navigate("invite")}>
                                {t("users.inviteUser")}
                            </Button>
                        )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {tenantUsers && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={tenantUsers}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(user: TenantUserInfo) =>
                                navigate(`/tenants/${tenantUuid}/users/${user.user.sub}`)
                            }
                            getRowId={(row) => row.user.sub}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
