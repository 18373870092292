import React, {useMemo, useState} from "react";
import {IAssetRisk, MultilingualTextWrapper} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {LabeledData} from "../../../base/data/LabeledData";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";

interface RisksListProps {
    risks: IAssetRisk[];
}

export function RisksList(props: RisksListProps) {
    const {risks} = props;
    const {language, es} = useLanguageContext();

    const sortedRisks = useMemo<IAssetRisk[]>(() => risks.sort((a, b) => b.risk.probability * b.risk.severity - a.risk.probability * a.risk.severity), [risks]);

    const [selectedRisk, setSelectedRisk] = useState<number | undefined>();

    return (
        <List sx={{
            overflowY: "scroll",
            maxHeight: "50vh"
        }}>
            {sortedRisks.map((r, index) => (
                <Tooltip
                    open={Boolean(selectedRisk == index)}
                    onClose={() => setSelectedRisk(undefined)}
                    title={<RiskTooltipContent risk={r} />}
                    key={index}>
                    <ListItemButton key={r.category} onClick={() => setSelectedRisk(index)}>
                        <ListItemText
                            primary={es("risks.riskCategories", r.category) + ": " + r.name}
                            secondary={new MultilingualTextWrapper(r.descriptions).resolved(language)}
                            sx={{paddingRight: "5em"}}
                        />
                        <ListItemSecondaryAction>
                            <TotalRiskDisplay totalRisk={r.risk.probability * r.risk.severity} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                </Tooltip>
            ))}
        </List>
    );
}

interface RiskTooltipContentProps {
    risk: IAssetRisk;
}

function RiskTooltipContent(props: RiskTooltipContentProps) {
    const {risk} = props;
    const {t, es} = useLanguageContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <LabeledItem label={t("assets.headerFields.category")}>
                        <Typography variant="body1">
                            {es("risks.riskCategories", risk.category)}
                        </Typography>
                    </LabeledItem>
                    <LabeledItem label={t("assets.headerNames.name")}>
                        <Typography variant="body1">
                            {risk.name}
                        </Typography>
                    </LabeledItem>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <LabeledItem label={t("assets.headerNames.probability")}>
                        <ProbabilityDisplay probability={risk.risk.probability} />
                    </LabeledItem>
                    <LabeledItem label={t("assets.headerNames.severity")}>
                        <SeverityDisplay severity={risk.risk.severity} />
                    </LabeledItem>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <LabeledData label={t("assets.headerNames.description")}>
                    <Typography variant="body1">
                        <MultiLingualTextDisplay texts={risk.descriptions} />
                    </Typography>
                </LabeledData>
            </Grid>
        </Grid>
    );

}