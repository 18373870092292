import {
    AiActRiskCategory, compareAiActRiskCategories,
    IAiActHistogram,
    IAssetRisk,
    IRiskHistogram,
    NumericRiskProbability,
    probability
} from "./types";
import {HistogramSection} from "../base/charts/HistogramInlineDisplay";
import {green, orange, red, yellow} from "@mui/material/colors";
import {GridSortModel} from "@mui/x-data-grid";

export class RiskWrapper {

    readonly risk: IAssetRisk;

    constructor(risk: IAssetRisk) {
        this.risk = risk;
    }

    static compare(a: IAssetRisk, b: IAssetRisk, sortModel: GridSortModel): number {
        if (sortModel.length == 0) {
            return a.name.localeCompare(b.name);
        }
        let sortField = sortModel[0].field;
        if (sortField.startsWith("assetRisk.")) {
            sortField = sortField.substring("assetRisk.".length);
        }
        let primarySort = 0;
        switch (sortField) {
            case "name":
                primarySort = a.name.localeCompare(b.name);
                break;
            case "category":
                primarySort = a.category.localeCompare(b.category);
                break;
            case "probability":
                primarySort = a.risk.probability - b.risk.probability;
                break;
            case "severity":
                primarySort = a.risk.severity - b.risk.severity;
                break;
            case "grossRisk":
                primarySort = a.risk.probability * a.risk.severity - b.risk.probability * b.risk.severity;
                break;
        }
        if (primarySort == 0) {
            return a.category.localeCompare(b.category);
        }
        if (sortModel[0].sort == "desc") {
            return -primarySort;
        }
        return primarySort;
    }
}

export function probabilityColor(probability: NumericRiskProbability): { background: string, foreground: string } {
    switch (probability) {
        case 1:
            return {background: green[600], foreground: "white"};
        case 2:
            return {background: green[300], foreground: "black"};
        case 3:
            return {background: orange[300], foreground: "black"};
        case 4:
            return {background: red[300], foreground: "black"};
        case 5:
            return {background: red[600], foreground: "white"};
    }
}

export class RiskHistogramWrapper {

    readonly histogram: IRiskHistogram;

    constructor(histogram: IRiskHistogram) {
        this.histogram = histogram;
    }

    toHistogramInlineData(es: (key: string, e: string) => string): HistogramSection[] {
        const sections: HistogramSection[] = [];
        for (let i = 1; i <= 5; i++) {
            const severity = this.histogram.severity[i];
            if (severity) {
                const color = probabilityColor(i as NumericRiskProbability);
                sections.push({
                    label: es("probability.probabilities", probability(i as NumericRiskProbability)),
                    color: color.background,
                    value: severity
                });
            }
        }
        return sections;
    }

}

export function aiActRiskColor(aiActRiskCategory: AiActRiskCategory): { background: string, foreground: string } {
    switch (aiActRiskCategory) {
        case AiActRiskCategory.UnacceptableRisk:
            return {background: red[600], foreground: "white"};
        case AiActRiskCategory.HighRisk:
            return {background: orange[300], foreground: "black"};
        case AiActRiskCategory.LimitedRisk:
            return {background: yellow[300], foreground: "black"};
        case AiActRiskCategory.MinimalRisk:
            return {background: green[300], foreground: "black"};
    }
}

export class AiActHistogramWrapper {

    readonly histogram: IAiActHistogram;

    constructor(histogram: IAiActHistogram) {
        this.histogram = histogram;
    }

    toHistogramInlineData(es: (key: string, e: string) => string): HistogramSection[] {
        const sections: HistogramSection[] = [];
        const keys = (Object.keys(this.histogram.histogram) as AiActRiskCategory[]).sort(compareAiActRiskCategories);
        for (const key of keys) {
            const count = this.histogram.histogram[key];
            if (count) {
                const color = aiActRiskColor(key);
                sections.push({
                    label: es("risks.aiActRiskCategories", key),
                    color: color.background,
                    value: count
                });
            }
        }
        return sections;
    }

}