import React from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Grid} from "@mui/material";
import {gs} from "../../../theme";
import {TextInput} from "../../../base/inputs/TextInput";

interface UseCaseStepProps {
    useCaseDescription: string;
    onChange: (useCaseDescription: string) => void;
}

export function UseCaseStep(props: UseCaseStepProps) {
    const {useCaseDescription, onChange} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <TextInput
                    label={t("useCase")}
                    onChange={onChange}
                    value={useCaseDescription}
                    rows={10}
                />
            </Grid>
        </Grid>
    );
}