import {Api} from "../libs/Api";
import {UiTranslationRequest} from "./comments";
import {ITask, Page, PageChange, TaskModification, TaskState} from "./types";

export class TasksProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<ITask>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/tasks`, pageChange);
        return this.api.get<Page<ITask>>(url);
    }

    async create(tenantUuid: string, creation: TaskModification): Promise<ITask> {
        const url = `/api/v1/tenants/${tenantUuid}/tasks`;
        return this.api.post<ITask>(url, creation);
    }

    async get(tenantUuid: string, taskUuid: string | undefined): Promise<ITask> {
        const url = `/api/v1/tenants/${tenantUuid}/tasks/${taskUuid}`;
        return this.api.get<ITask>(url);
    }

    async update(tenantUuid: string, taskUuid: string, modification: TaskModification): Promise<ITask> {
        const url = `/api/v1/tenants/${tenantUuid}/tasks/${taskUuid}`;
        return this.api.put<ITask>(url, modification);
    }

    async updateState(tenantUuid: string, taskUuid: string, nextState: TaskState): Promise<ITask> {
        const url = `/api/v1/tenants/${tenantUuid}/tasks/${taskUuid}/task-state`;
        return this.api.put<ITask>(url, {
            taskState: nextState
        });
    }

    async translate(tenantUuid: string, assetUuid: string, taskUuid: string, request: UiTranslationRequest) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/tasks/${taskUuid}/translate`;
        return this.api.post(url, request);
    }
}
