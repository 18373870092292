import {Button, Container, Grid} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LabeledItem} from "../../base/data/LabeledItem";
import {RoleDisplay} from "../../base/displays/RoleDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {RoleWrapper} from "../../domain/permissions";
import {TenantUserInfo} from "../../domain/types";
import {UserInfoWrapper} from "../../domain/users";
import {gs} from "../../theme";

interface UserViewProps {}

export function UserView(props: UserViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tenantUser, setTenantUser] = useState<TenantUserInfo>();
    const [role, setRole] = useState<RoleWrapper>();

    useEffect(() => {
        if (routeParams.username && api.auth) {
            const usersProvider = new UsersProvider(api);
            usersProvider.getTenantUser(tenantUuid, routeParams.username).then(setTenantUser);

            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.role(tenantUuid).then((role) => {
                setRole(new RoleWrapper(role));
            });
        }
    }, [api, tenantUuid, routeParams]);

    const userInfoWrapper = useMemo<UserInfoWrapper | undefined>(() => {
        if (tenantUser) {
            return new UserInfoWrapper(tenantUser.user);
        }
        return undefined;
    }, [tenantUser]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={userInfoWrapper ? userInfoWrapper.displayName : "..."}
                        crumbs={[
                            {label: t("users.plural"), href: `/tenants/${tenantUuid}/users`},
                            {
                                label: userInfoWrapper ? userInfoWrapper.displayName : "...",
                                href: `/tenants/${tenantUuid}/users/${routeParams.username}`
                            }
                        ]}
                    >
                        {role &&
                            role.canExertPermission(`/tenants/${tenantUuid}/users/${routeParams.username}`, "WRITE") &&
                            role.canExertPermission(`/tenants/${tenantUuid}/roles`, "READ") && (
                                <Button variant="outlined" onClick={() => navigate("edit")}>
                                    {t("edit")}
                                </Button>
                            )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {tenantUser && (
                    <Grid item xs={12}>
                        <BoxedPaper>
                            <Grid container spacing={gs}>
                                <LabeledItem label={t("users.givenName")}>{tenantUser.user.givenName}</LabeledItem>
                                <LabeledItem label={t("users.name")}>{tenantUser.user.name}</LabeledItem>
                                <LabeledItem label={t("users.email")}>{tenantUser.user.email}</LabeledItem>
                                <LabeledItem label={t("roles.singular")}>
                                    <RoleDisplay role={tenantUser.tenantUser.role} />
                                </LabeledItem>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
