import {Box, Grid} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Comment, CommentSendRequest} from "../../domain/comments";
import {ChatInput} from "./ChatInput";
import {ChatMessage} from "./ChatMessage";

interface ChatWindowProps {
    us: string;
    comments: Comment[];
    performSend?: (message: CommentSendRequest) => Promise<Comment>;
    grow?: boolean;
    translationEnabled?: boolean;
    accept?: string | string[];
}

export function ChatWindow(props: ChatWindowProps) {
    const {comments, translationEnabled, us, performSend, grow, accept} = props;

    const [lines, setLines] = useState<number>(1);

    const handleLinesChanged = (l: any) => {
        if (l > 5) {
            setLines(5);
        } else {
            setLines(1);
        }
    };

    return (
        <Box>
            <ChatMessageContainer
                us={us}
                comments={comments}
                translationEnabled={translationEnabled}
                grow={grow}
                minusLines={lines - 1}
            />

            {performSend && (
                <ChatInput
                    onPerformSend={performSend}
                    onLinesChanged={(l: any) => handleLinesChanged(l)}
                    accept={accept}
                />
            )}
        </Box>
    );
}

interface ChatMessageContainerProps {
    us: string;
    comments: Comment[];
    grow?: boolean;
    minusLines: number;
    translationEnabled?: boolean;
}

export function ChatMessageContainer(props: ChatMessageContainerProps) {
    const {us, comments, grow, minusLines, translationEnabled} = props;

    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const scrollToBottom = () => {
            if (messagesEndRef.current && messagesContainerRef.current) {
                messagesEndRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "center"
                });
            }
        };
        scrollToBottom();
    }, [comments]);

    const minus = useMemo(() => {
        return `${minusLines * 19}px`;
    }, [minusLines]);

    return (
        <div
            style={{
                maxHeight: grow ? `calc(80vh - ${minus})` : `calc(500px - ${minus})`,
                minHeight: `calc(250px - ${minus})`,
                overflowY: "scroll",
                overflowX: "hidden"
            }}
            ref={messagesContainerRef}
        >
            <Grid container spacing={1}>
                {comments.map((comment) =>
                        <Grid
                            key={comment.uuid}
                            item
                            xs={12}
                            style={{paddingBottom: "1em", paddingRight: "1em"}}
                        >
                            <ChatMessage
                                key={comment.uuid}
                                comment={comment}
                                us={us}
                                translationEnabled={translationEnabled}
                            />
                        </Grid>
                )}
            </Grid>
            <div style={{float: "left", clear: "both"}} ref={messagesEndRef}></div>
        </div>
    );
}
