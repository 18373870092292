import styled from "@emotion/styled";
import {Send} from "@mui/icons-material";
import {Box, Grid, IconButton, InputBase, Snackbar} from "@mui/material";
import React, {useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {CommentSendRequest} from "../../domain/comments";

interface ChatInputProps {
    onPerformSend: (message: CommentSendRequest) => Promise<any>;
    onLinesChanged: (lines: number) => void;
    maxFileSize?: number;
    accept?: string | string[];
    stripMetaData?: boolean;
}

const SendButtonContainerGrid = styled(Grid)(({theme}) => ({
    display: "flex",
    alignItems: "flex-end"
}));

export function ChatInput(props: ChatInputProps) {
    const {onPerformSend, onLinesChanged, maxFileSize, accept, stripMetaData} = props;

    const {t, language} = useLanguageContext();

    const [message, setMessage] = useState("");
    const [transmitting, setTransmitting] = useState(false);
    const [failed, setFailed] = useState(false);

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const message = event.target.value;
        setMessage(message);
        onLinesChanged(message.split("\n").length);
    };

    const send = (performSend: (message: CommentSendRequest) => Promise<Comment>) => {
        if (transmitting) {
            console.log("Currently transmitting a message, not starting another transmission.");
            return;
        }
        if (!message) {
            console.log("Not sending an empty message.");
            return;
        }
        setTransmitting(true);
        const sendCommentRequest: CommentSendRequest = {
            comment: message,
            locale: language
        };

        performSend(sendCommentRequest)
            .then(() => {
                setMessage("");
                setTransmitting(false);
                setFailed(false);
            })
            .catch((_e) => {
                setTransmitting(false);
                setFailed(true);

                setTimeout(() => {
                    setFailed(false);
                }, 15000);
            });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            if (e.shiftKey || e.altKey) {
            } else {
                e.preventDefault();
                e.stopPropagation();
                send(onPerformSend);
            }
        }
    };

    return (
        <div style={{borderTop: "1px solid #d5dade", paddingTop: "15px"}}>
            <Grid container spacing={2}>
                <Grid
                    item
                    style={{
                        flexGrow: 1
                    }}
                >
                    <Box>
                        <InputBase
                            fullWidth
                            multiline
                            maxRows={5}
                            placeholder={t("chatWindow.inputPlaceHolder")}
                            value={message}
                            onChange={(e) => handleMessageChange(e)}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                    </Box>
                </Grid>

                <SendButtonContainerGrid item>
                    <IconButton size="small" disabled={transmitting || !message} onClick={() => send(onPerformSend)}>
                        <Send />
                    </IconButton>
                </SendButtonContainerGrid>
            </Grid>
            <Snackbar
                key={"chatInput"}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={failed}
                onClose={() => setFailed(false)}
                security="error"
                message={t("chatWindow.sendFailed")}
            />
        </div>
    );
}
