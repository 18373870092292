import * as React from "react";
import {AppRoutes} from "./AppRoutes";
import {BrowserRouter} from "react-router-dom";
import {LayoutContextProvider} from "./context/layout/LayoutContext";
import {AuthContextProvider} from "./context/auth/AuthContext";
import {LanguageContextProvider} from "./context/language/LanguageContext";

export default function App() {
    return (
        <BrowserRouter>
            <LanguageContextProvider>
                <LayoutContextProvider>
                    <AuthContextProvider notAuthenticatedUrl="/auth/login">
                        <AppRoutes />
                    </AuthContextProvider>
                </LayoutContextProvider>
            </LanguageContextProvider>
        </BrowserRouter>
    );
}
