import React, {useEffect, useMemo, useState} from "react";
import {Avatar} from "@mui/material";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {DocumentsProvider} from "../../domain/DocumentsProvider";
import {IDocumentResponse} from "../../domain/types";

interface ToolAvatarProps {
    name: string;
    faviconUrl?: string;
}

export function ToolAvatar(props: ToolAvatarProps) {
    const {name, faviconUrl} = props;

    const initials = useMemo<string>(() => {
        if (!name) {
            return "NA";
        }
        if (name.length == 2) {
            return name;
        }
        const parts = name.split(" ");
        if (parts.length == 1) {
            return parts[0].substring(0, 2).toUpperCase();
        }
        return parts[0].substring(0, 1).toUpperCase() + parts[1].substring(0, 1).toUpperCase();
    }, [name]);

    return (
        <Avatar src={faviconUrl}>{initials}</Avatar>
    );
}

interface DocumentToolAvatarProps {
    name: string;
    documentUuid?: string;
}

export function DocumentToolAvatar(props: DocumentToolAvatarProps) {
    const {name, documentUuid} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [documentResponse, setDocumentResponse] = useState<IDocumentResponse>();

    useEffect(() => {
        if (api && api.auth && tenantUuid && documentUuid) {
            const documentsProvider = new DocumentsProvider(api);
            documentsProvider.prepareDownloadUrl(tenantUuid, documentUuid).then(setDocumentResponse);
        }
    }, [api, tenantUuid, documentUuid]);

    return (
        <ToolAvatar name={name} faviconUrl={documentResponse ? documentResponse.url.url : undefined} />
    );

}