import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Chip} from "@mui/material";
import {red} from "@mui/material/colors";

interface BooleanDisplayProps {
    value?: boolean;
    colored?: boolean;
    trueIsNegative?: boolean;
}

export function BooleanDisplay(props: BooleanDisplayProps) {
    const {value, colored, trueIsNegative} = props;
    const {t} = useLanguageContext();

    const negative = trueIsNegative ? !value : value;

    let color, backgroundColor;
    if (colored && negative) {
        backgroundColor = red[600];
        color = "white";
    }

    return (
        <Chip label={value ? t("yes") : t("no")} sx={{color, backgroundColor}} />
    );
}