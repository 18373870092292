import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ConfirmDeleteButton} from "../../base/buttons/ConfirmDeleteButton";
import {LabeledItem} from "../../base/data/LabeledItem";
import {RoleSelecion} from "../../base/inputs/RoleSelecion";
import {AGridItemGrow} from "../../base/layout/AGridItemGrow";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {RolesProvider} from "../../domain/RolesProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {RoleWrapper} from "../../domain/permissions";
import {Role, TenantUserInfo} from "../../domain/types";
import {UserInfoWrapper} from "../../domain/users";
import {gs} from "../../theme";

interface UserEditProps {}

export function UserEdit(props: UserEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tenantUser, setTenantUser] = useState<TenantUserInfo>();
    const [role, setRole] = useState<RoleWrapper>();
    const [roles, setRoles] = useState<Role[]>();

    useEffect(() => {
        if (routeParams.username && api.auth) {
            const usersProvider = new UsersProvider(api);
            usersProvider.getTenantUser(tenantUuid, routeParams.username).then((user) => {
                setTenantUser(user);

                const roleWrapper = new RoleWrapper(user.tenantUser.role);
                setRole(roleWrapper);
            });

            const rolesProvider = new RolesProvider(api);
            rolesProvider
                .list(tenantUuid, {
                    page: 0,
                    pageSize: 10
                })
                .then((res) => {
                    setRoles(res.content);
                });
        }
    }, [api, tenantUuid, routeParams]);

    const userInfoWrapper = useMemo<UserInfoWrapper | undefined>(() => {
        if (tenantUser) {
            return new UserInfoWrapper(tenantUser.user);
        }
        return undefined;
    }, [tenantUser]);

    const handleSave = useCallback(() => {
        if (routeParams.username && api.auth && role) {
            const usersProvider = new UsersProvider(api);
            usersProvider
                .updateUserRole(tenantUuid, routeParams.username, {
                    roleUuid: role.role.uuid
                })
                .then(() => {
                    navigate(`/tenants/${tenantUuid}/users/${routeParams.username}`);
                });
        }
    }, [api, role, tenantUuid, routeParams]);

    const handleDelete = useCallback(() => {
        if (routeParams.username && api.auth && role) {
            const usersProvider = new UsersProvider(api);
            usersProvider.deleteUser(tenantUuid, routeParams.username).then(() => {
                navigate(`/tenants/${tenantUuid}/users`);
            });
        }
    }, [api, role, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={userInfoWrapper ? userInfoWrapper.displayName : "..."}
                        crumbs={[
                            {label: t("users.plural"), href: `/tenants/${tenantUuid}/users`},
                            {
                                label: userInfoWrapper ? userInfoWrapper.displayName : "...",
                                href: `/tenants/${tenantUuid}/users/${routeParams.username}`
                            },
                            {
                                label: t("edit"),
                                href: ""
                            }
                        ]}
                    />
                </Grid>

                {tenantUser && (
                    <Grid item xs={12}>
                        <BoxedPaper>
                            <Grid container spacing={gs}>
                                <LabeledItem label={t("users.givenName")}>{tenantUser.user.givenName}</LabeledItem>
                                <LabeledItem label={t("users.name")}>{tenantUser.user.name}</LabeledItem>
                                <LabeledItem label={t("users.email")}>{tenantUser.user.email}</LabeledItem>
                                {role && roles && (
                                    <Grid item xs={3}>
                                        <RoleSelecion
                                            onChange={(v) => {
                                                const found = roles.find((r) => r.uuid === v);
                                                if (found) {
                                                    const roleWrapper = new RoleWrapper(found);
                                                    setRole(roleWrapper);
                                                }
                                            }}
                                            roles={roles}
                                            value={role.role.uuid}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Grid container spacing={gs}>
                                        <AGridItemGrow>
                                            <Button variant="contained" onClick={() => handleSave()}>
                                                {t("save")}
                                            </Button>
                                        </AGridItemGrow>

                                        <Grid item>
                                            <ConfirmDeleteButton
                                                onConfirm={handleDelete}
                                                title={t("delete")}
                                                message={`${t("users.delete.deleteConfirmationMessage", {
                                                    name: tenantUser.user.givenName
                                                })}`}
                                            >
                                                {t("delete")}
                                            </ConfirmDeleteButton>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                onClick={() =>
                                                    navigate(`/tenants/${tenantUuid}/users/${routeParams.username}`)
                                                }
                                            >
                                                {t("cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
