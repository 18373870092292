import {Autocomplete, Checkbox, FormControlLabel, TextField} from "@mui/material";
import React, {CSSProperties, ChangeEvent, useCallback} from "react";

interface MultiStringSelectProps {
    values: string[];
    options: string[];
    lookupLabel?: (key: string) => string;
    onChange: (newValues: string[]) => void;
    size?: "small" | "medium";
    label?: string;
    placeholder?: string;
    style?: CSSProperties;
    fullWidth?: boolean;
    disableCloseOnSelect?: boolean;
}

export function MultiStringSelect({
    values,
    options,
    lookupLabel,
    onChange,
    size,
    label,
    placeholder,
    style,
    fullWidth,
    disableCloseOnSelect
}: MultiStringSelectProps) {
    const handleChange = useCallback(
        (event: ChangeEvent<any>, values: string[]) => {
            onChange(values);
        },
        [onChange]
    );

    const performLabelLookup = useCallback(
        (key: string): string => {
            if (lookupLabel) {
                return lookupLabel(key) || key;
            }
            return key;
        },
        [lookupLabel]
    );

    return (
        <Autocomplete
            multiple
            options={options}
            getOptionLabel={(o) => performLabelLookup(o)}
            onChange={handleChange}
            value={values}
            style={style}
            size={size}
            fullWidth={fullWidth}
            disableCloseOnSelect={disableCloseOnSelect}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <FormControlLabel control={<Checkbox checked={selected} />} label={performLabelLookup(option)} />
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
            )}
        />
    );
}
