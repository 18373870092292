import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MultilingualText, MultilingualTextWrapper} from "../../domain/types";
import {TranslateControl} from "./TranslateControl";
import {Comment} from "../../domain/comments";

interface TranslatableTextDisplayProps {
    comment: Comment;
    translationEnabled?: boolean;
}

export function TranslatableTextDisplay(props: TranslatableTextDisplayProps) {
    const {comment, translationEnabled} = props;
    const {language} = useLanguageContext();

    const [cachedComment, setCachedComment] = useState<Comment>(comment);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
    const [selectedText, setSelectedText] = useState<MultilingualText>();

    useEffect(() => {
        setCachedComment(comment);
        setSelectedLanguage(language);
        setSelectedText(new MultilingualTextWrapper(comment.comments).resolvedText(language));
    }, [language]);

    const handleSelectLanguage = useCallback((selectedLanguage: string) => {
        setSelectedLanguage(selectedLanguage);
        setSelectedText(new MultilingualTextWrapper(cachedComment.comments).resolvedText(selectedLanguage));
    }, [cachedComment]);

    const handleTranslated = useCallback((comment: Comment, translatedTo: string) => {
        setCachedComment(comment);
        setSelectedLanguage(translatedTo);
        setSelectedText(new MultilingualTextWrapper(comment.comments).resolvedText(translatedTo));
    }, []);

    return (
        <div>
            <div>{selectedText ? selectedText.text : ""}</div>
            {translationEnabled && (
                <div>
                    <TranslateControl
                        comment={cachedComment}
                        displayingLanguage={selectedText ? selectedText.locale : undefined}
                        selectedLanguage={selectedLanguage}
                        onSelectLanguage={handleSelectLanguage}
                        onTranslated={handleTranslated}
                    />
                </div>
            )}
        </div>
    );
}
