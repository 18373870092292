import {Button, Grid} from "@mui/material";
import Container from "@mui/material/Container";
import {GridColDef} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {IKiTool, Page, PageChange, defaultPage} from "../../domain/types";
import {gs} from "../../theme";
import {DocumentToolAvatar, ToolAvatar} from "../../base/displays/ToolAvatar";
import Box from "@mui/material/Box";

interface ToolsListProps {}

export function ToolsList(props: ToolsListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t, es} = useLanguageContext();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [kiTools, setKiTools] = useState<Page<IKiTool>>();
    const [selected, setSelected] = useState<string[]>([]);

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("tools.headers.name"),
            flex: 1,
            renderCell: (params, ) => (
                <Grid container spacing={1}>
                    <Grid item>
                        <Box paddingTop={0.5}>
                            <DocumentToolAvatar name={params.row.name} documentUuid={params.row.favicon ? params.row.favicon.uuid : undefined} />
                        </Box>
                    </Grid>
                    <Grid item>
                        {params.value}
                    </Grid>
                </Grid>
            )
        },
        {
            field: "category",
            headerName: t("tools.headers.category"),
            flex: 1,
            renderCell: (params) => es("tools.toolCategory", params.value)
        },
        {
            field: "type",
            headerName: t("tools.headers.type"),
            flex: 1,
            renderCell: (params) => es("tools.toolType", params.value)
        },
        {field: "vendor", headerName: t("tools.headers.vendor"), flex: 1}
    ];

    useEffect(() => {
        fetchTools();
    }, [api, tenantUuid, pageChange]);

    const fetchTools = useCallback(() => {
        if (api.auth) {
            const kiToolsProvider = new KiToolsProvider(api);
            kiToolsProvider.list(tenantUuid, pageChange).then(setKiTools);
        }
        return true;
    }, [api, tenantUuid, pageChange]);

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/tools`}
                onDataChangeEvent={() => fetchTools()}
                onCreated={() => fetchTools()}
                onUpdated={() => fetchTools()}
                onDeleted={() => fetchTools()}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={t("tools.plural")}
                            crumbs={[
                                {
                                    label: t("tools.plural"),
                                    href: `/tenants/${tenantUuid}/tools`
                                }
                            ]}
                        >
                            {selected.length == 1 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        navigate(`/tenants/${tenantUuid}/assets/add?toolUuid=${selected[0]}`)
                                    }
                                >
                                    {t("tools.assets.create")}
                                </Button>
                            )}
                            {selected.length == 0 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/tenants/${tenantUuid}/tools/add`)}
                                >
                                    {t("tools.create")}
                                </Button>
                            )}
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {kiTools && (
                        <Grid item xs={12}>
                            <APagingTable
                                page={kiTools}
                                columns={columns}
                                onPageChanged={setPageChange}
                                onClick={(tool) => navigate(`/tenants/${tenantUuid}/tools/${tool.uuid}`)}
                                onSelectionChanged={setSelected}
                            />
                        </Grid>
                    )}
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
