import React, {useCallback, useMemo} from "react";
import {MultiStringSelect} from "../../base/inputs/MultiStringSelect";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {MultilingualText, MultilingualTextWrapper, Question} from "../../domain/types";

interface MultipleChoiceMultipleSelectInputProps {
    label?: string;
    placeholder?: string;
    question: Question;
    value: string;
    onChange: (value: string) => void;
}

export function MultipleChoiceMultipleSelectInput(props: MultipleChoiceMultipleSelectInputProps) {
    const {label, placeholder, question, value, onChange} = props;

    const {t, language} = useLanguageContext();

    const lookupLabel = useCallback(
        (slug: string) => {
            if (question.options) {
                const option = question.options.find((o) => o.slug == slug);
                if (option) {
                    return option.label;
                }
            }
            return slug;
        },
        [question]
    );

    const values = useMemo<string[]>(
        () =>
            value
                .split(",")
                .map((v) => v.trim())
                .filter((v) => v.length > 0)
                .sort((a, b) => a.localeCompare(b)),
        [value]
    );

    const options = useMemo<string[]>(() => {
        if (question.options) {
            return question.options.map((o) => o.slug);
        }
        return [];
    }, [question]);

    const handleChange = useCallback(
        (values: string[]) => {
            onChange(values.join(","));
        },
        [onChange]
    );

    const handleTitleLookup = useCallback(
        (titles: MultilingualText[]) => {
            const wrapper = new MultilingualTextWrapper(titles);
            return wrapper.resolved(language);
        },
        [language]
    );

    return (
        <MultiStringSelect
            values={values}
            options={options}
            onChange={handleChange}
            lookupLabel={lookupLabel}
            label={label}
            placeholder={values.length == 0 ? placeholder : undefined}
        />
    );
}
