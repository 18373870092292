import {Alert, Button, Grid, Paper, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Comments} from "../../base/Comments";
import {Activities} from "../../base/activities/Activities";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {AHeader} from "../../base/layout/AHeader";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {CommentsProvider} from "../../domain/CommentsProvider";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {Comment, CommentSendRequest} from "../../domain/comments";
import {AiActRiskCategory, compareAiActRiskCategories, IAsset, IAssetRisk, UserInfo} from "../../domain/types";
import {gs} from "../../theme";
import {AssetDetails} from "./view/AssetDetails";
import {AssetMeasuresBlock} from "./view/AssetMeasuresBlock";
import {AssetRisksBlock} from "./view/AssetRisksBlock";
import {AssetRisksChart} from "./view/AssetRisksChart";
import {AssetTasksBlock} from "./view/AssetTasksBlock";

interface AssetViewProps {}

export function AssetView(props: AssetViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [risks, setRisks] = useState<IAssetRisk[]>();
    const [noRisks, setNoRisks] = useState(false);
    const [comments, setComments] = useState<Comment[]>([]);

    const [us, setUs] = useState("");

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            fetchAsset();
        }
    }, [api, tenantUuid, routeParams]);

    useEffect(() => {
        fetchComments();
    }, [asset]);

    const fetchAsset = useCallback(() => {
        if (api.auth && routeParams.assetUuid) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.profile().then((user: UserInfo) => {
                setUs(user.username);
            });
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risks(tenantUuid, routeParams.assetUuid).then((risks) => {
                setRisks(risks);
                setNoRisks(risks.length == 0);
            });
        }
        return true;
    }, [api, tenantUuid, routeParams]);

    const fetchComments = useCallback(() => {
        if (api.auth && routeParams.assetUuid) {
            const commentsProvider = new CommentsProvider(api);
            commentsProvider.listForAsset(tenantUuid, routeParams.assetUuid).then((res) => {
                setComments(res);
            });
        }
        return false;
    }, [api, routeParams.assetUuid]);

    const handleSendMessage = async (r: CommentSendRequest): Promise<Comment> => {
        const request: CommentSendRequest = {
            comment: r.comment,
            locale: r.locale,
            assetUuid: routeParams.assetUuid
        };
        const commentsProvider = new CommentsProvider(api);
        return commentsProvider.create(tenantUuid, request).then((res) => {
            fetchComments();
            return res;
        });
    };

    const aiActRiskCategory = useMemo<AiActRiskCategory | undefined>(() => {
        if (risks) {
            return risks
                .filter((r) => r.aiActRiskCategory)
                .map((r) => r.aiActRiskCategory)
                .sort(compareAiActRiskCategories)
                .pop();
        }
    }, [risks]);

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`}
                onUpdated={() => {
                    fetchAsset();
                }}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={asset ? asset.name : "..."}
                            crumbs={[
                                {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                                {
                                    label: asset ? asset.name : "...",
                                    href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                                }
                            ]}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/edit`)}
                            >
                                {t("edit")}
                            </Button>
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {asset && (
                        <>
                            {noRisks && (
                                <Grid item xs={12}>
                                    <Paper>
                                        <Alert
                                            severity="info"
                                            action={
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        navigate(
                                                            `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/questionnaire`
                                                        )
                                                    }
                                                >
                                                    {t("assets.assetRisk.noRisks.start")}
                                                </Button>
                                            }
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">
                                                        {t("assets.assetRisk.noRisks.title")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">
                                                        {t("assets.assetRisk.noRisks.info")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Alert>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <BoxedPaper>
                                    <AssetDetails asset={asset} aiActRiskCategory={aiActRiskCategory} onTranslate={() => fetchAsset()} />
                                </BoxedPaper>
                            </Grid>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks`}
                                onDataChangeEvent={() => fetchAsset()}
                                onUpdated={() => fetchAsset()}
                                onDeleted={() => fetchAsset()}
                            >
                                <Grid item xs={12} sm={6}>
                                    <AssetRisksChart asset={asset} />
                                </Grid>
                            </DataChangeConsumer>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/comments`}
                                onDataChangeEvent={() => fetchComments()}
                                onCreated={() => fetchComments()}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h2">{t("comments.plural")}</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Comments
                                                messages={comments}
                                                performSend={(m) => handleSendMessage(m)}
                                                us={us}
                                                translationEnabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DataChangeConsumer>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/activities`}
                                onDataChangeEvent={() => fetchAsset()}
                                onCreated={() => fetchAsset()}
                                onUpdated={() => fetchAsset()}
                                onDeleted={() => fetchAsset()}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Activities assetUuid={routeParams.assetUuid} />
                                </Grid>
                            </DataChangeConsumer>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks`}
                                onDataChangeEvent={() => fetchAsset()}
                                onCreated={() => fetchAsset()}
                                onUpdated={() => fetchAsset()}
                                onDeleted={() => fetchAsset()}
                            >
                                <Grid item xs={12}>
                                    <AssetRisksBlock asset={asset} />
                                </Grid>
                            </DataChangeConsumer>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/measures`}
                                onDataChangeEvent={() => fetchAsset()}
                                onCreated={() => fetchAsset()}
                                onUpdated={() => fetchAsset()}
                                onDeleted={() => fetchAsset()}
                            >
                                <Grid item xs={12}>
                                    <AssetMeasuresBlock asset={asset} />
                                </Grid>
                            </DataChangeConsumer>

                            <DataChangeConsumer
                                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks`}
                                onDataChangeEvent={() => fetchAsset()}
                                onCreated={() => fetchAsset()}
                                onUpdated={() => fetchAsset()}
                                onDeleted={() => fetchAsset()}
                            >
                                <Grid item xs={12}>
                                    <AssetTasksBlock asset={asset} />
                                </Grid>
                            </DataChangeConsumer>

                            <Grid item xs={12}>
                                <AHeader title={t("assets.header")} variant="h2"></AHeader>
                            </Grid>

                            <Grid item xs={12}>
                                <BoxedPaper>
                                    <MetaDataDisplay metaData={asset.metaData} />
                                </BoxedPaper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
