import React, {useState, MouseEvent, useMemo} from "react";
import {LanguageSupport, useLanguageContext} from "../context/language/LanguageContext";
import {Button, Hidden, Menu, MenuItem} from "@mui/material";
import {ExpandMore, Translate} from "@mui/icons-material";

interface LanguageSwitcherMenuProps {
    languages?: string[];
    languageOverrides?: string;
}

export function LanguageSwitcherMenu(props: LanguageSwitcherMenuProps) {
    const {languages, languageOverrides} = props;
    const languageContext = useLanguageContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetLanguage = (language: string) => {
        languageContext.setLanguage(language);
    };

    const handleSelectLanguage = (language: string) => {
        handleSetLanguage(language);
        handleClose();
    };

    const languageSupport = useMemo<LanguageSupport>(() => {
        const languageSupport = new LanguageSupport(languages);
        languageContext.setOverrides(languageOverrides);
        languageContext.setLanguage(languageSupport.defaultSupportedLanguage);
        return languageSupport;
    }, [languages, languageOverrides]);

    const open: boolean = anchorEl != null;
    return (
        <>
            <Button
                color="inherit"
                startIcon={<Translate />}
                endIcon={<ExpandMore fontSize="small" />}
                onClick={handleClick}
            >
                <Hidden smDown>
                    {languageContext.es("languages", languageContext.language) || languageContext.language.toLocaleUpperCase()}
                </Hidden>
                <Hidden smUp>{languageContext.language.toLocaleUpperCase()}</Hidden>
            </Button>
            <Menu
                id="i18n-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
            >
                {languageSupport.supports("de") && (
                    <MenuItem onClick={() => handleSelectLanguage("de")}>{"Deutsch"}</MenuItem>
                )}
                {languageSupport.supports("en") && (
                    <MenuItem onClick={() => handleSelectLanguage("en")}>{"English"}</MenuItem>
                )}
            </Menu>
        </>
    );
}