import React, {useCallback, useMemo, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {TenantsProvider} from "../../../domain/TenantsProvider";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {TextInput} from "../../../base/inputs/TextInput";

interface CreateTenantDialogProps {
    open: boolean;
    onClose: () => void;
}

export function CreateTenantDialog(props: CreateTenantDialogProps) {
    const {open, onClose} = props;
    const {t} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");

    const allowSubmit = useMemo<boolean>(() => Boolean(name), [name]);

    const handleCreate = useCallback(() => {
        if (allowSubmit && api.auth) {
            const tenantsProvider = new TenantsProvider(api);
            tenantsProvider.create(tenantUuid, name)
                .then((created) => {
                    onClose();
                    navigate(`/tenants/${tenantUuid}/tenants/${created.uuid}`);
                });
        }
    }, [api, name, tenantUuid, allowSubmit, navigate]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{t("tenants.create.title")}</DialogTitle>
            <DialogContent>
                <Box paddingTop={3}>
                    <TextInput label={t("tenants.name")} value={name} onChange={setName} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => handleCreate()} disabled={!allowSubmit}>
                    {t("tenants.create.create")}
                </Button>
                <Button variant="outlined" onClick={() => onClose()}>
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}