import {Api} from "../libs/Api";
import {WebSocketTicket} from "../libs/WebSocketConnection";
import {luxonDate} from "../libs/tools";

export class WebSocketsProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async ticket(tenantUuid: string): Promise<WebSocketTicket> {
        return this.api
            .get<WebSocketTicket>(`/api/v1/tenants/${tenantUuid}/web-sockets/tickets`)
            .then(mapResponse);
    }

}

function mapResponse(t: any): WebSocketTicket {
    return {
        ticketUuid: t.ticketUuid,
        validUntil: luxonDate(t.validUntil),
        tenantUuid: t.tenantUuid,
        username: t.username
    };
}