import {Add, Delete} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {LocaleSelection} from "../../../base/inputs/LocaleSelection";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {QuestionOption} from "../../../domain/types";
import {computeSlug} from "../../../libs/tools";
import {gs} from "../../../theme";

interface OptionsEditFormProps {
    open: boolean;
    options: QuestionOption[];
    requiredLanguages: string[];
    onApply: (options: QuestionOption[]) => void;
    onCancel: () => void;
}

export function OptionsEditDialog(props: OptionsEditFormProps) {
    const {open, options, requiredLanguages, onApply, onCancel} = props;
    const {t, es, language} = useLanguageContext();

    const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
    const [editingOptions, setEditingOptions] = useState<QuestionOption[]>([]);

    useEffect(() => {
        if (requiredLanguages.indexOf(language) == -1) {
            setSelectedLanguage(requiredLanguages[0]);
        } else {
            setSelectedLanguage(language);
        }
        setEditingOptions(options);
    }, [options, requiredLanguages]);

    const handleAddOption = useCallback(() => {
        if (editingOptions) {
            const copy = [...editingOptions];
            copy.push({
                slug: `option-${copy.length}`,
                label: "",
                locale: ""
            });
            setEditingOptions(copy);
        }
    }, [editingOptions]);

    const removeOption = useCallback(
        (index: number) => {
            if (editingOptions) {
                const copy = [...editingOptions];
                copy.splice(index, 1);
                setEditingOptions(copy);
            }
        },
        [editingOptions]
    );

    const handleSlugChanged = useCallback(
        (index: number, value: string) => {
            if (editingOptions) {
                const copy = [...editingOptions];
                copy[index].slug = value;
                setEditingOptions(copy);
            }
        },
        [editingOptions]
    );

    const handleLabelBlur = useCallback(
        (index: number) => {
            if (editingOptions) {
                const option = editingOptions[index];
                if (option.slug.includes("option") || option.slug === "") {
                    option.slug = computeSlug(option.label);
                    const copy = [...editingOptions];
                    copy[index] = option;
                    setEditingOptions(copy);
                }
            }
        },
        [selectedLanguage, editingOptions]
    );

    const handleLabelChanged = useCallback(
        (index: number, value: string) => {
            if (editingOptions) {
                const copy = [...editingOptions];
                copy[index].label = value;
                copy[index].locale = selectedLanguage;
                setEditingOptions(copy);
            }
        },
        [selectedLanguage, editingOptions]
    );

    const handleApply = useCallback(() => {
        onApply(editingOptions);
    }, [editingOptions]);

    const allowSubmit = useMemo<boolean>(() => {
        if (editingOptions && editingOptions.length > 0) {
            for (let option of editingOptions) {
                if (option.label.length == 0) {
                    return false;
                }
                if (option.label.trim() == "") {
                    return false;
                }

                if (option.slug == "") {
                    return false;
                }
            }
        }
        return true;
    }, [editingOptions]);

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
            <DialogTitle>{t("questionnaires.question.options.edit")}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item xs={6} sm={4} md={3}>
                                    <LocaleSelection
                                        label={t("language")}
                                        languages={["en", "de"]}
                                        value={selectedLanguage}
                                        onChange={setSelectedLanguage}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    style={{
                                        flexGrow: "1"
                                    }}
                                ></Grid>

                                <Grid item>
                                    <IconButton onClick={handleAddOption}>
                                        <Add />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        {editingOptions &&
                            editingOptions.map((option, index) => (
                                <Grid key={option.slug} item xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item style={{flexGrow: 1}}>
                                            <TextField
                                                fullWidth
                                                label={es("languages", option.locale || selectedLanguage)}
                                                value={option.label}
                                                onBlur={() => handleLabelBlur(index)}
                                                onChange={(e) => handleLabelChanged(index, e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                label={t("slug")}
                                                value={option.slug}
                                                onChange={(e) => handleSlugChanged(index, e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <IconButton onClick={() => removeOption(index)}>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => handleApply()} disabled={!allowSubmit}>
                    {t("apply")}
                </Button>
                <Button variant="outlined" onClick={() => onCancel()}>
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
