import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {RolesProvider} from "../../domain/RolesProvider";
import {Role, RoleCreation} from "../../domain/types";
import {gs} from "../../theme";
import {RoleForm} from "./edit/RoleForm";

interface RoleCreateProps {}

export function RoleCreate(props: RoleCreateProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();
    const [form, setForm] = useState<RoleCreation>({
        name: "",
        description: "",
        permissions: {
            permissions: []
        }
    });

    const [submitting, setSubmitting] = useState<boolean>(false);

    const canSave = useMemo<boolean>(() => {
        return Boolean(form.name && form.permissions.permissions.length > 0);
    }, [form]);

    const handleSaveRole = useCallback(() => {
        if (canSave && api.auth) {
            setSubmitting(true);

            const rolesProvider = new RolesProvider(api);
            rolesProvider.create(tenantUuid, form).then((role: Role) => {
                setSubmitting(false);
                navigate(`/tenants/${tenantUuid}/roles/${role.uuid}`);
            });
        }
    }, [routeParams, canSave, form, api, tenantUuid, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("create")}
                        crumbs={[
                            {label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`},
                            {
                                label: t("create"),
                                href: ""
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <RoleForm role={form} onChange={setForm} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => handleSaveRole()}
                                    color="primary"
                                    variant="contained"
                                    disabled={!canSave || submitting}
                                >
                                    {t("roles.save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
