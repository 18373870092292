import {Notifications, NotificationsActive} from "@mui/icons-material";
import {Badge, Tooltip} from "@mui/material";
import {amber, grey} from "@mui/material/colors";
import React from "react";
import {useLanguageContext} from "../context/language/LanguageContext";

interface BellIconProps {
    numberOfNotifications: number;
}

export function BellIcon(props: BellIconProps) {
    const {numberOfNotifications} = props;

    const {t} = useLanguageContext();

    if (numberOfNotifications > 0) {
        return (
            <Badge badgeContent={numberOfNotifications} color="primary">
                <Tooltip
                    style={{color: amber[500]}}
                    title={t("notifications.companies.info", {
                        count: numberOfNotifications
                    })}
                >
                    <NotificationsActive />
                </Tooltip>
            </Badge>
        );
    }

    return (
        <Tooltip
            title={t("notifications.companies.zero")}
            style={{
                color: grey[300]
            }}
        >
            <Notifications />
        </Tooltip>
    );
}
