import {Button, Chip, CircularProgress, Container, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {AddressDisplay} from "../../../base/displays/AddressDisplay";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {CurrentUserProvider} from "../../../domain/CurrentUserProvider";
import {ITenant} from "../../../domain/types";
import {gs} from "../../../theme";

interface TenantViewProps {}

export function TenantView(props: TenantViewProps) {
    const routeParams = useParams();
    const {t} = useLanguageContext();
    const navigate = useNavigate();
    const {api} = useAuthContext();

    const [tenant, setTenant] = useState<ITenant>();

    useEffect(() => {
        if (routeParams.tenantUuid) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(routeParams.tenantUuid).then((tenant) => {
                setTenant(tenant);
            });
        }
    }, [routeParams.tenantUuid, api]);

    if (!tenant) {
        return (
            <Container maxWidth="lg">
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={tenant.name}
                        crumbs={[
                            {
                                label: t("tenant.plural"),
                                href: `/tenants/${routeParams.tenantUuid}/tenants`
                            },
                            {
                                label: tenant ? tenant.name : "...",
                                href: `/tenants/${routeParams.tenantUuid}/tenants/${routeParams.tenantUuid}`
                            }
                        ]}
                    >
                        <Button variant="outlined" onClick={() => navigate(`/tenants/${routeParams.tenantUuid}/edit`)}>
                            {t("edit")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>

                <Grid item xs={12}>
                    <BoxedPaper>
                        <Grid container spacing={gs}>
                            <LabeledItem label={t("tenant.singular")}>{tenant.name}</LabeledItem>

                            <LabeledItem label={t("tenant.companySize")}>{tenant.companySize}</LabeledItem>

                            <LabeledItem label={t("tenant.industry")}>
                                <MultiLingualTextDisplay texts={tenant.industry.names} />
                            </LabeledItem>

                            {tenant.address && (
                                <LabeledItem label={t("tenant.address")}>
                                    <AddressDisplay address={tenant.address} />
                                </LabeledItem>
                            )}

                            <LabeledItem label={t("tenant.defaultLocale")}>{tenant.defaultLocale}</LabeledItem>

                            <LabeledItem label={t("tenant.locales")}>
                                <Grid container spacing={1}>
                                    {tenant.locales.map((locale, index) => (
                                        <Grid item key={index}>
                                            <Chip label={locale} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </LabeledItem>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
