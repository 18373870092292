import {Button, Chip, Container, Divider, Grid, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {LabeledItem} from "../../base/data/LabeledItem";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {IAsset, IKiTool, MultilingualText, MultilingualTextWrapper} from "../../domain/types";
import {calculateContrastRatio, stateColor} from "../../libs/tools";
import {gs} from "../../theme";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {DocumentToolAvatar} from "../../base/displays/ToolAvatar";
import {KiToolDetails} from "../tools/view/KiToolDetails";

interface CatalogEntryDetailsProps {}

export function CatalogEntryDetails(props: CatalogEntryDetailsProps) {
    const routeParams = useParams();
    const navigate = useNavigate();

    const {t, es, language} = useLanguageContext();

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [asset, setAsset] = useState<IAsset>();
    const [kiTool, setKiTool] = useState<IKiTool>();

    useEffect(() => {
        if (routeParams.entityType && routeParams.uuid) {
            if (routeParams.entityType === "asset") {
                const assetsProvider = new AssetsProvider(api);
                assetsProvider.get(tenantUuid, routeParams.uuid).then((asset: IAsset) => {
                    setAsset(asset);
                    setKiTool(asset.basedOn);
                });
            }
            if (routeParams.entityType === "tool") {
                const kiToolsProvider = new KiToolsProvider(api);
                kiToolsProvider.get(tenantUuid, routeParams.uuid).then((tool: IKiTool) => {
                    setKiTool(tool);
                });
            }
        }
    }, [api, tenantUuid, routeParams]);

    const handleRequestAdditionalUses = () => {
        let queryParam = "";
        if (asset) {
            queryParam = `?assetUuid=${asset.uuid}`;
        }
        if (kiTool) {
            queryParam = `?toolUuid=${kiTool.uuid}`;
        }

        navigate(`/tenants/${tenantUuid}/catalog/request-new${queryParam}`);
    };

    const title = useMemo<string>(() => {
        if (asset) {
            return asset.name;
        }
        if (kiTool) {
            return kiTool.name;
        }
        return "...";
    }, [asset, kiTool]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={title}
                        crumbs={[
                            {
                                label: t("catalog.singular"),
                                href: `/tenants/${tenantUuid}/catalog`
                            },
                            {
                                label: title,
                                href: ""
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                {asset && (
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={t("assets.headerNames.state")}>
                                            <Chip
                                                label={es("assets.states", asset.state)}
                                                sx={{
                                                    background: stateColor(asset.state),
                                                    color: calculateContrastRatio(stateColor(asset.state))
                                                }}
                                            />
                                        </LabeledItem>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {kiTool && (
                                    <KiToolDetails kiTool={kiTool} onTranslate={() => console.log("onTranslate")} />
                                )}
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Typography variant="h2">{t("catalog.approvedUses.title")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={() => handleRequestAdditionalUses()} variant="outlined">
                        {t("catalog.approvedUses.request")}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <BoxedPaper>
                        {asset && <MetaDataDisplay metaData={asset.metaData} />}
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
