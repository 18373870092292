import * as React from "react";
import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useLayoutContext} from "../context/layout/LayoutContext";
import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useTenantContext} from "../context/tenant/TenantContext";
import {useLocation, useNavigate} from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HandymanIcon from "@mui/icons-material/Handyman";
import TerminalIcon from "@mui/icons-material/Terminal";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import SettingsIcon from "@mui/icons-material/Settings";
import {useLanguageContext} from "../context/language/LanguageContext";
import {useAuthContext} from "../context/auth/AuthContext";
import {CurrentUserProvider} from "../domain/CurrentUserProvider";
import {RoleWrapper} from "../domain/permissions";
import {LocalGroceryStore} from "@mui/icons-material";

interface DrawerNavProps {}

export function DrawerNav(props: DrawerNavProps) {
    const {drawerNavOpen, setDrawerNavOpen} = useLayoutContext();
    const {tenantUuid, tenant} = useTenantContext();
    const {api} = useAuthContext();
    const {t} = useLanguageContext();

    const [role, setRole] = useState<RoleWrapper>();

    useEffect(() => {
        if (api && api.auth && api.auth.isLoggedIn && tenantUuid) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.role(tenantUuid)
                .then((role) => {
                    setRole(new RoleWrapper(role));
                });
        }
    }, [api, tenantUuid]);

    return (
        <Drawer open={drawerNavOpen} anchor="left" variant="persistent" onClose={() => setDrawerNavOpen(false)}>
            <Box p={5} />
            <List>
                <MenuEntry
                    title={t("dashboard.singular")}
                    icon={<DashboardIcon />}
                    href={`/tenants/${tenantUuid}`}
                    exact
                />
                <MenuEntry
                    title={t("catalog.singular")}
                    icon={<LocalGroceryStore />}
                    href={`/tenants/${tenantUuid}/catalog`}
                    exact
                />
                {role?.canExertPermission(`/tenants/${tenantUuid}/assets`, "READ") && (
                    <MenuEntry title={t("assets.plural")} icon={<TerminalIcon />} href={`/tenants/${tenantUuid}/assets`} />
                )}
                {role?.canExertPermission(`/tenants/${tenantUuid}/tools`, "READ") && (
                    <MenuEntry title={t("tools.plural")} icon={<HandymanIcon />} href={`/tenants/${tenantUuid}/tools`} />
                )}
                {role?.canExertPermission(`/tenants/${tenantUuid}/questionnaires`, "READ") && (
                    <MenuEntry title={t("questionnaires.plural")} icon={<DynamicFormIcon />} href={`/tenants/${tenantUuid}/questionnaires`} />
                )}
                {role?.canExertPermission(`/tenants/${tenantUuid}/users`, "READ") && (
                    <MenuEntry title={t("users.plural")} icon={<PersonIcon />} href={`/tenants/${tenantUuid}/users`} />
                )}
                {role?.canExertPermission(`/tenants/${tenantUuid}/roles`, "READ") && (
                    <MenuEntry title={t("roles.plural")} icon={<AdminPanelSettingsIcon />} href={`/tenants/${tenantUuid}/roles`} />
                )}
                {role?.canExertPermission(`/tenants/`, "WRITE") && (
                    <>
                        <Divider />
                        <MenuEntry title={t("tenants.plural")} icon={<SettingsIcon />} href={`/tenants/${tenantUuid}/tenants`} />
                    </>
                )}
            </List>
        </Drawer>
    );
}

interface MenuEntryProps {
    title: string;
    icon: ReactNode;
    href: string;
    exact?: boolean;
}

function MenuEntry(props: MenuEntryProps) {
    const {title, icon, href, exact} = props;
    const {setDrawerNavOpen} = useLayoutContext();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const handleClick = useCallback(() => {
        navigate(href);
        setDrawerNavOpen(false);
    }, [href, setDrawerNavOpen, navigate]);

    const selected = useMemo<boolean>(() => {
        if (exact) {
            return pathname === href;
        }
        return pathname.startsWith(href);
    }, [href, exact, pathname]);

    return (
        <ListItem disablePadding>
            <ListItemButton onClick={handleClick} selected={selected}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    );
}
