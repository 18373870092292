import React, {useCallback, useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {QuestionScoreRiskTemplate, QuestionScoreRiskTemplateModification} from "../../../domain/types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {RiskTemplateForm} from "./RiskTemplateForm";
import {ConfirmDialog} from "../../../base/dialogs/ConfirmDialog";

interface TemplateEditDialogProps {
    open: boolean;
    value: QuestionScoreRiskTemplate;
    onClose: (template?: QuestionScoreRiskTemplateModification) => void;
    onDelete: () => void;
}

export function TemplateEditDialog(props: TemplateEditDialogProps) {
    const {open, value, onClose, onDelete} = props;
    const {t} = useLanguageContext();

    const [editingTemplate, setEditingTemplate] = useState<QuestionScoreRiskTemplateModification>({
        locale: value.id.locale,
        title: value.title,
        text: value.text
    });
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    useEffect(() => {
        setEditingTemplate({
            locale: value.id.locale,
            title: value.title,
            text: value.text
        });
    }, [value]);

    const handleApply = useCallback(() => {
        onClose(editingTemplate);
    }, [editingTemplate, onClose]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
            <DialogTitle>{t("questionnaires.question.riskTemplates.add")}</DialogTitle>
            <DialogContent>
                <RiskTemplateForm
                    value={editingTemplate}
                    onApply={setEditingTemplate}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button variant="outlined" color="warning" onClick={() => setConfirmDelete(true)}>{t("delete")}</Button>
                <Button variant="contained" color="primary" onClick={() => handleApply()}>{t("apply")}</Button>
            </DialogActions>
            <ConfirmDialog
                open={confirmDelete}
                title={t("questionnaires.question.riskTemplates.delete.title")}
                message={t("questionnaires.question.riskTemplates.delete.message")}
                onConfirm={() => onDelete()}
                onCancel={() => setConfirmDelete(false)}
            />
        </Dialog>
    );
}