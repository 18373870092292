import {Button, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PriorityDisplay} from "../../../base/displays/PriorityDisplay";
import {TaskStateDisplay} from "../../../base/displays/TaskStateDisplay";
import {AHeader} from "../../../base/layout/AHeader";
import {ATable} from "../../../base/table/ATable";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, ITask} from "../../../domain/types";
import {gs} from "../../../theme";

interface AssetTasksBlockProps {
    asset: IAsset;
}

export function AssetTasksBlock(props: AssetTasksBlockProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [tasks, setTasks] = useState<ITask[]>();

    const columns: GridColDef[] = [
        {
            field: "title",
            headerName: t("tasks.title"),
            flex: 1,
            renderCell: (params) => params.row.titles.map((t: any) => t.text).join(", ")
        },
        {
            field: "priority",
            headerName: t("tasks.priority"),
            flex: 1,
            renderCell: (r) => <PriorityDisplay priority={r.row.priority} />
        },
        {
            field: "state",
            headerName: t("tasks.state"),
            flex: 1,
            renderCell: (r) => <TaskStateDisplay state={r.row.state} />
        }
    ];

    useEffect(() => {
        if (api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.tasks(tenantUuid, asset.uuid).then(setTasks);
        }
    }, [api, tenantUuid, asset]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <AHeader title={t("tasks.plural")} variant="h2">
                    <Button onClick={() => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/add`)}>
                        {t("tasks.addTask")}
                    </Button>
                </AHeader>
            </Grid>
            {tasks && (
                <Grid item xs={12}>
                    <ATable
                        rows={tasks}
                        columns={columns}
                        onClick={(r) => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/${r.uuid}`)}
                    />
                </Grid>
            )}
        </Grid>
    );
}
