import React, {useCallback, useRef, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Chip, CircularProgress, Grid, IconButton, InputBase, Tooltip, Typography} from "@mui/material";
import {CloudUpload} from "@mui/icons-material";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DocumentsProvider} from "../../domain/DocumentsProvider";
import {DocumentUploader} from "../../domain/documents";
import {IDocumentResponse} from "../../domain/types";
import Box from "@mui/material/Box";
import {AGridItemGrow} from "../layout/AGridItemGrow";
import {gs} from "../../theme";
import {maxLen} from "../../libs/tools";
import {LabeledItem} from "../data/LabeledItem";

interface DocumentUploadInputProps {
    label: string;
    delay?: number;
    onUploadComplete: (document: IDocumentResponse) => void;
}

export function DocumentUploadInput(props: DocumentUploadInputProps) {
    const {label, delay, onUploadComplete} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [progress, setProgress] = useState<number>(0);

    const handleUploadClick = useCallback(() => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click()
        }
    }, [fileInputRef]);

    const handleFileSelected = useCallback((fileList: FileList) => {
        const file = fileList.item(0);
        if (api && api.auth && file) {
            setFile(file);
            const documentsProvider = new DocumentsProvider(api);
            documentsProvider.prepareUploadUrl(tenantUuid, file)
                .then((document) => {
                    const documentUploader = new DocumentUploader(file, document);
                    documentUploader.onProgress(setProgress);
                    documentUploader.upload().then(() => {
                        if (delay) {
                            setTimeout(() => {
                                onUploadComplete(document);
                            }, delay);
                        } else {
                            onUploadComplete(document);
                        }
                    });
                });
        }
    }, [api, tenantUuid, onUploadComplete, delay]);

    return (
        <>
            <div style={{display: "none"}}>
                <input
                    type="file"
                    onChange={(e) => {
                        if (e.target.files) {
                            handleFileSelected(e.target.files);
                        }
                    }}
                    ref={fileInputRef}
                />
            </div>
            <Box sx={{ p: "4px 8px", display: "flex", alignItems: "center", border: "1px solid #ccc", borderRadius: "4px", width: "100%" }}>
                <Grid container spacing={1}>
                    <AGridItemGrow>
                        {!file &&
                            <InputBase placeholder={label} disabled fullWidth sx={{marginTop: "4px"}} />
                        }
                        {file &&
                            <Tooltip title={<ProgressContent filename={file.name} progress={progress} />} placement="top">
                                <Chip
                                    sx={{marginTop: "4px"}}
                                    avatar={<CircularProgress variant="determinate" value={progress} size="small" />}
                                    label={maxLen(file.name, 35)}
                                />
                            </Tooltip>
                        }
                    </AGridItemGrow>
                    <Grid item>
                        <IconButton onClick={() => handleUploadClick()}>
                            <CloudUpload />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

interface ProgressContentProps {
    progress: number;
    filename: string;
}

function ProgressContent(props: ProgressContentProps) {
    const {progress, filename} = props;
    const {t} = useLanguageContext();

    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("upload.filename")}>
                <Typography variant="body1">{filename}</Typography>
            </LabeledItem>
            <LabeledItem label={t("upload.progress")}>
                <Typography variant="body1">{t("upload.progressInfo", {progress})}%</Typography>
            </LabeledItem>
        </Grid>
    );
}