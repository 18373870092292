import {Button, Chip, Container, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {RolesProvider} from "../../domain/RolesProvider";
import {RoleWrapper} from "../../domain/permissions";
import {Page, PageChange, Role, defaultPage} from "../../domain/types";
import {gs} from "../../theme";

interface RolesListProps {}

export function RolesList(props: RolesListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [roles, setRoles] = useState<Page<Role>>();
    const [selected, setSelected] = useState<string[]>([]);

    const [role, setRole] = useState<RoleWrapper>();

    useEffect(() => {
        const rolesProvider = new RolesProvider(api);
        if (api.auth) {
            rolesProvider.list(tenantUuid, pageChange).then(setRoles);

            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.role(tenantUuid).then((role) => {
                setRole(new RoleWrapper(role));
            });
        }
    }, [api, tenantUuid, pageChange]);

    const {t} = useLanguageContext();
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("roles.name"),
            flex: 1,
            renderCell: (params) => (
                <>
                    {params.row.name} {params.row.defaultRole && <Chip label={t("default")} />}
                </>
            )
        },
        {field: "description", headerName: t("roles.description"), flex: 1},
        {
            field: "permissions",
            headerName: t("roles.permissions"),
            flex: 1,
            renderCell: (params) => params.row.permissions.permissions.length
        }
    ];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("roles.plural")}
                        crumbs={[{label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`}]}
                    >
                        {role && role.canExertPermission(`/tenants/${tenantUuid}/roles`, "WRITE") && (
                            <Button variant="outlined" onClick={() => navigate("add")}>
                                {t("create")}
                            </Button>
                        )}
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {roles && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={roles}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(role: Role) => navigate(`/tenants/${tenantUuid}/roles/${role.uuid}`)}
                            onSelectionChanged={setSelected}
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
