import {UserInfo} from "./types";

export class UserInfoWrapper {

    readonly userInfo: UserInfo;

    constructor(userInfo: UserInfo) {
        this.userInfo = userInfo
    }

    get displayName(): string {
        return [this.userInfo.givenName, this.userInfo.name]
            .filter((n) => n)
            .join(" ");
    }

    get initials(): string {
        if (this.userInfo.givenName && this.userInfo.name) {
            return (this.userInfo.givenName[0] + this.userInfo.name[0]).toUpperCase();
        }
        return "UU";
    }

}