import React, {useMemo} from "react";
import {AnswerType, Question} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {BooleanDisplay} from "./BooleanDisplay";
import {QuestionWrapper} from "../../domain/questionnaires";
import {DateDisplay} from "./DateDisplay";

interface AnswerDisplayProps {
    question: Question;
    answer: string;
}

export function AnswerDisplay(props: AnswerDisplayProps) {
    const {question, answer} = props;
    const {language} = useLanguageContext();

    const questionWrapper = useMemo<QuestionWrapper>(() => new QuestionWrapper(question), [question]);

    switch (question.type) {
        case AnswerType.Info:
            return null;
        case AnswerType.FormattedText:
            return <span dangerouslySetInnerHTML={{__html: answer}} />;
        case AnswerType.TextList:
            return <ul>{answer.split("\n").map((line, index) => <li key={index}>{line}</li>)}</ul>
        case AnswerType.Boolean:
        case AnswerType.YesNoUndefined:
        case AnswerType.Checkbox:
            return <BooleanDisplay value={answer === "Yes"} />;
        case AnswerType.MultipleChoiceSingleAnswer:
            return <>{questionWrapper.findAnswerLabel(answer, language)}</>;
        case AnswerType.MultipleChoiceMultipleAnswers:
            return <>
                {answer
                    .split(",")
                    .map((a, index) =>
                        <li key={index}>{questionWrapper.findAnswerLabel(a, language)}</li>
                    )}
                </>;
        case AnswerType.Date:
        case AnswerType.DateTime:
            return <DateDisplay date={answer} />;
        case AnswerType.Text:
        case AnswerType.Integer:
        case AnswerType.Float:
        default:
            return <span>{answer}</span>;
    }
}