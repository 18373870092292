import {Api} from "../libs/Api";
import {UiTranslationRequest} from "./comments";
import {IMeasure} from "./types";

export class MeasuresProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async topTen(tenantUuid: string): Promise<IMeasure[]> {
        const url = `/api/v1/tenants/${tenantUuid}/measures/top-ten`;
        return this.api.get<IMeasure[]>(url);
    }

    async translate(tenantUuid: string, assetUuid: string, measureUuid: string, request: UiTranslationRequest) {
        const url = `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures/${measureUuid}/translate`;
        return this.api.post(url, request);
    }
}
