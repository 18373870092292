import * as React from "react";
import {Button, Divider, Menu, MenuItem} from "@mui/material";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useLanguageContext} from "../context/language/LanguageContext";
import {useNavigate} from "react-router-dom";
import {useTenantContext} from "../context/tenant/TenantContext";
import {CurrentUserProvider} from "../domain/CurrentUserProvider";
import {useAuthContext} from "../context/auth/AuthContext";
import {ITenant, UserInfo} from "../domain/types";
import {UserInfoWrapper} from "../domain/users";
import Box from "@mui/material/Box";
import {UserAvatar} from "../base/avatars/UserAvatar";

interface UserMenuProps {
}

export function UserMenu(props: UserMenuProps) {
    const {t} = useLanguageContext();
    const navigate = useNavigate();
    const {tenantUuid} = useTenantContext();
    const {api} = useAuthContext();

    const [profile, setProfile] = useState<UserInfo>();
    const [tenants, setTenants] = useState<ITenant[]>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.profile().then(setProfile);
            currentUserProvider.tenants().then(setTenants);
        }
    }, [api]);

    const handleShowMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleUserProfile = useCallback(() => {
        handleCloseMenu();
        navigate(`/tenants/${tenantUuid}/profile`);
    }, [handleCloseMenu, tenantUuid, navigate]);

    const handleSwitchTenant = useCallback(() => {
        handleCloseMenu();
        navigate("/");
    }, [handleCloseMenu, navigate]);

    const handleLogout = useCallback(() => {
        handleCloseMenu();
        navigate("/auth/logout");
    }, [handleCloseMenu, navigate]);

    const initials = useMemo<string>(() => {
        if (profile) {
            return new UserInfoWrapper(profile).initials;
        }
        return "..";
    }, [profile]);

    return (
        <>
            <Button onClick={handleShowMenu}>
                <Box sx={{ml: 2}}>
                    {profile &&
                        <UserAvatar username={profile.username} />
                    }
                </Box>
            </Button>
            <Menu open={Boolean(anchorEl)} onClose={handleCloseMenu} anchorEl={anchorEl} keepMounted
                  anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                  transformOrigin={{vertical: "top", horizontal: "center"}}>
                <UserMenuItem label={t("userMenu.profile")} onClick={handleUserProfile}/>
                <Divider />
                {tenants && tenants.length > 1 && (
                    [
                        <UserMenuItem label={t("userMenu.switchTenant")} onClick={handleSwitchTenant}/>,
                        <Divider />
                    ]
                )}
                <UserMenuItem label={t("userMenu.logout")} onClick={handleLogout}/>
            </Menu>
        </>
    );
}

interface UserMenuItemProps {
    label: string;
    onClick: () => void;
}

function UserMenuItem(props: UserMenuItemProps) {
    const {label, onClick} = props;

    return (
        <MenuItem onClick={onClick}>{label}</MenuItem>
    );

}