import {NotificationsActiveRounded, NotificationsOutlined} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {UserAvatar} from "../../base/avatars/UserAvatar";
import {LabeledItem} from "../../base/data/LabeledItem";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {DateDisplay} from "../../base/displays/DateDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {NotificationsProvider} from "../../domain/NotificationsProvider";
import {Notification, NotificationCreationRequest} from "../../domain/notifications";
import {gs} from "../../theme";

export function Notifications() {
    const {t, es} = useLanguageContext();

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [markingRead, setMarkingRead] = useState<boolean>(false);

    const [selectedNotification, setSelectedNofitication] = useState<Notification>();
    const [allRead, setAllRead] = useState(false);

    useEffect(() => {
        loadNotifications();
    }, []);

    const loadNotifications = useCallback(() => {
        const notificationProvider = new NotificationsProvider(api);
        notificationProvider
            .list(tenantUuid)
            .then((res) => {
                setNotifications(res.content);

                if (!res.content.some((n) => n.readAt === undefined || n.readAt === null)) {
                    setAllRead(true);
                }
            })
            .finally(() => {
                setLoading(false);
                setMarkingRead(false);
            });
    }, [api, tenantUuid]);

    const handleMarkAllRead = () => {
        setMarkingRead(true);
        const unreadNotifications = notifications.filter((notification) => !notification.readAt);
        handleMarkSelectedRead(unreadNotifications.map((notification) => notification.uuid));
    };

    const handleMarkSelectedRead = (selected: string[]) => {
        const request: NotificationCreationRequest = {
            notificationUuids: selected
        };
        setMarkingRead(true);
        const notificationProvider = new NotificationsProvider(api);
        notificationProvider
            .markRead(tenantUuid, request)
            .then(() => {
                loadNotifications();
            })
            .finally(() => {
                setMarkingRead(false);
            });
    };

    const handleMarkReadSingleNotification = useCallback((uuid: string) => {
        setMarkingRead(true);
        const request: NotificationCreationRequest = {
            notificationUuids: [uuid]
        };

        const notificationProvider = new NotificationsProvider(api);
        notificationProvider
            .markRead(tenantUuid, request)
            .then(() => {
                loadNotifications();
            })
            .finally(() => {
                setMarkingRead(true);
            });
    }, []);

    const handleSelect = (notification: Notification) => {
        if (!notification.readAt) {
            handleMarkReadSingleNotification(notification.uuid);
        }
        setSelectedNofitication(notification);
    };

    const URLLookup = useCallback((notification: Notification) => {
        let url = `/tenants/${tenantUuid}/`;
        if (notification.tool) {
            url += `tools/${notification.tool.uuid}`;
        }

        if (notification.asset) {
            url += `assets/${notification.asset.uuid}`;
        }

        if (notification.assetRisk) {
            url += `assets/${notification.assetRisk.asset.uuid}/risks/${notification.assetRisk.uuid}`;
        }

        if (notification.measure) {
            url += `assets/${notification.measure.asset.uuid}/measures/${notification.measure.uuid}`;
        }

        if (notification.task) {
            url += `assets/${notification.measure.asset.uuid}/tasks/${notification.task.uuid}`;
        }

        return url;
    }, []);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid size={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("notifications.plural")}
                        crumbs={[
                            {
                                href: "",
                                label: t("notifications.plural")
                            }
                        ]}
                    >
                        <Button variant="outlined" onClick={() => handleMarkAllRead()} disabled={allRead}>
                            {t("notifications.markAll")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>

                <Grid size={12}>
                    <Grid container spacing={gs}>
                        {loading && (
                            <Grid size={12}>
                                <CircularProgress />
                            </Grid>
                        )}

                        {!loading && (
                            <Grid size={5}>
                                <Paper>
                                    <List
                                        disablePadding
                                        sx={{
                                            maxHeight: "80vh",
                                            overflowY: "auto"
                                        }}
                                    >
                                        {notifications.length === 0 && (
                                            <ListItem>
                                                <ListItemText primary={t("notifications.noNotifications")} />
                                            </ListItem>
                                        )}

                                        {notifications.map((notification) => (
                                            <ListItemButton
                                                alignItems="flex-start"
                                                key={notification.uuid}
                                                divider
                                                onClick={() => handleSelect(notification)}
                                            >
                                                <ListItemAvatar>
                                                    <UserAvatar username={notification.metaData.createdBy} />
                                                </ListItemAvatar>

                                                <ListItemText
                                                    primary={notification.subject}
                                                    secondary={
                                                        <Grid container>
                                                            <Grid size={12}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    sx={{color: "text.primary", display: "inline"}}
                                                                >
                                                                    {notification.shortContent}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid size={12}>
                                                                <DateDisplay date={notification.metaData.createdAt} />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    {notification.readAt ? (
                                                        <NotificationsOutlined />
                                                    ) : (
                                                        <NotificationsActiveRounded />
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>
                        )}

                        <Grid size={7}>
                            {selectedNotification && (
                                <BoxedPaper loading={markingRead}>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={t("notifications.subject")}>
                                            {selectedNotification.subject}
                                        </LabeledItem>

                                        <LabeledItem label={t("notifications.state")}>
                                            {es("notifications.states", selectedNotification.state)}
                                        </LabeledItem>

                                        <LabeledItem label={t("type")}>
                                            {es("notifications.types", selectedNotification.type)}
                                        </LabeledItem>

                                        <LabeledItem label={t("notifications.shortContent")}>
                                            {selectedNotification.shortContent}
                                        </LabeledItem>

                                        <LabeledItem label={t("notifications.content")}>
                                            {selectedNotification.content}
                                        </LabeledItem>

                                        <LabeledItem label={t("notifications.linkToReference")}>
                                            <Link to={URLLookup(selectedNotification)}>
                                                {t("notifications.goToReference")}
                                            </Link>
                                        </LabeledItem>

                                        <Grid size={12}>
                                            <MetaDataDisplay metaData={selectedNotification.metaData} />
                                        </Grid>
                                    </Grid>
                                </BoxedPaper>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
