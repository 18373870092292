import * as React from "react";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {Answer, IAssetRisk, Question} from "../../../domain/types";
import {useCallback, useMemo} from "react";
import {AnswerQuestionForm} from "../../../forms/questionnaire/AnswerQuestionForm";

interface StepQuestionsProps {
    activeQuestion: number;
    questionnaire: QuestionnaireWrapper;
    onAnswer: (questionnaire: QuestionnaireWrapper) => void;
    risks?: IAssetRisk[];
}

export function StepQuestions(props: StepQuestionsProps) {
    const {activeQuestion, questionnaire, onAnswer, risks} = props;

    const question = useMemo<Question>(() =>
        questionnaire.question(activeQuestion)
    , [activeQuestion, questionnaire]);

    const answer = useMemo<Answer | undefined>(() =>
        questionnaire.findAnswer(question.uuid)
    , [questionnaire, question]);

    const handleAnswer = useCallback((answer: Answer) => {
        onAnswer(questionnaire.answer(question.uuid, answer));
    }, [questionnaire, question, onAnswer]);

    const filteredRisks = useMemo<IAssetRisk[]>(() => {
        if (risks) {
            return risks.filter(risk => risk.answer && risk.answer.questionUuid == question.uuid);
        }
        return [];
    }, [risks, question]);

    return (
        <AnswerQuestionForm question={question} answer={answer} onAnswer={(a) => handleAnswer(a)} risks={filteredRisks} />
    );
}
