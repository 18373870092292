import {Avatar, Grid, styled} from "@mui/material";
import React, {useMemo} from "react";
import {luxonDate} from "../../libs/tools";
import {DateDisplay} from "../displays/DateDisplay";
import {TranslatableTextDisplay} from "../translations.tsx/TranslatableTexDisplay";
import {Comment} from "../../domain/comments";
import {UserAvatar} from "../avatars/UserAvatar";

interface ChatMessageProps {
    comment: Comment;
    us: string;
    translationEnabled?: boolean;
}

export function ChatMessage(props: ChatMessageProps) {
    const {comment, us, translationEnabled} = props;

    const requireAggressiveBreak = useMemo<boolean>(() => {
        for (let message of comment.comments) {
            const m = message.text;
            if (m && m.split(" ").filter((chunk) => chunk.length >= 30).length > 0) {
                return true;
            }
        }
        return false;
    }, [comment]);

    const ours = useMemo<boolean>(() => comment.metaData.createdBy == us, [comment, us])

    return (
        <Grid container>
            {!ours && (
                <AvatarGrid item>
                    <UserAvatar username={comment.metaData.createdBy} tooltip />
                </AvatarGrid>
            )}

            <BubbleGrid item style={{textAlign: ours ? "right" : "left"}}>
                {ours ? (
                    <SpeechBubbleRightDiv>
                        {!requireAggressiveBreak ? (
                            <SpeechBubbleContentDiv>
                                <TranslatableTextDisplay
                                    comment={comment}
                                    translationEnabled={translationEnabled}
                                />
                            </SpeechBubbleContentDiv>
                        ) : (
                            <SpeechBubbleContentAggressiveBreakDiv>
                                <TranslatableTextDisplay
                                    comment={comment}
                                    translationEnabled={translationEnabled}
                                />
                            </SpeechBubbleContentAggressiveBreakDiv>
                        )}
                    </SpeechBubbleRightDiv>
                ) : (
                    <SpeechBubbleLeftDiv>
                        {!requireAggressiveBreak ? (
                            <SpeechBubbleContentDiv>
                                <TranslatableTextDisplay
                                    comment={comment}
                                    translationEnabled={translationEnabled}
                                />
                            </SpeechBubbleContentDiv>
                        ) : (
                            <SpeechBubbleContentAggressiveBreakDiv>
                                <TranslatableTextDisplay
                                    comment={comment}
                                    translationEnabled={translationEnabled}
                                />
                            </SpeechBubbleContentAggressiveBreakDiv>
                        )}
                    </SpeechBubbleLeftDiv>
                )}

                <div style={{color: "#858587"}}>
                    <DateDisplay date={luxonDate(comment.metaData.createdAt)} />
                </div>
            </BubbleGrid>

            {ours && (
                <AvatarGrid
                    item
                    style={{
                        textAlign: "right"
                    }}
                >
                    <Avatar style={{float: "right"}}>
                        <UserAvatar username={comment.metaData.createdBy} tooltip />
                    </Avatar>
                </AvatarGrid>
            )}
        </Grid>
    );
}

const bubbleColor = "#D9D9D9";
const pointerSizePx = 17;
const speechBubblePadding = 2;

const AvatarGrid = styled(Grid)(({theme}) => ({
    width: "60px",
    maxWidth: "60px",
    flexBasis: "60px",
    flexGrow: 0
}));

const BubbleGrid = styled(Grid)(({theme}) => ({
    flexGrow: 1,
    maxWidth: "calc(100% - 60px)"
}));

const SpeechBubbleRightDiv = styled("div")(({theme}) => ({
    textAlign: "left",
    position: "relative",
    background: bubbleColor,
    borderRadius: "0.4em",
    hyphens: "auto",
    "&::after": {
        content: "''",
        position: "absolute",
        right: 0,
        top: theme.spacing(3),
        width: 0,
        height: 0,
        border: `${pointerSizePx}px solid transparent`,
        borderLeftColor: bubbleColor,
        borderRight: 0,
        borderTop: 0,
        marginTop: "-8.5px",
        marginRight: `-${pointerSizePx}px`
    }
}));

const SpeechBubbleLeftDiv = styled("div")(({theme}) => ({
    textAlign: "left",
    position: "relative",
    background: bubbleColor,
    borderRadius: "0.4em",
    hyphens: "auto",
    "&::before": {
        content: "''",
        position: "absolute",
        left: -theme.spacing(speechBubblePadding) || -16,
        top: theme.spacing(3),
        width: 0,
        height: 0,
        border: `${pointerSizePx}px solid transparent`,
        borderRightColor: bubbleColor,
        borderLeft: 0,
        borderTop: 0,
        marginTop: "-8.5px",
        marginRight: `-${pointerSizePx * 3}px`
    }
}));

const SpeechBubbleContentDiv = styled("div")(({theme}) => ({
    padding: theme.spacing(speechBubblePadding) || 50,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
}));

const SpeechBubbleContentAggressiveBreakDiv = styled("div")(({theme}) => ({
    padding: theme.spacing(speechBubblePadding) || 50,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "pre-wrap"
}));
