import React, {useMemo} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {ToolBaseAttributesForm} from "./ManualEntryStep";
import {IWebCrawlerResponse, IWebSearchResult} from "../../../domain/types";
import {Grid, Typography} from "@mui/material";
import {gs} from "../../../theme";
import {ToolAvatar} from "../../../base/displays/ToolAvatar";
import {LabeledData} from "../../../base/data/LabeledData";
import {ScreenshotDisplay} from "../../../base/displays/ScreenshotDisplay";

interface SummaryStepProps {
    toolBaseAttributes: ToolBaseAttributesForm;
    useCaseDescription: string;
    webSearchResult?: IWebSearchResult;
    webCrawlerResponse?: IWebCrawlerResponse;
}

export function SummaryStep(props: SummaryStepProps) {
    const {toolBaseAttributes, useCaseDescription, webSearchResult, webCrawlerResponse} = props;
    const {t} = useLanguageContext();

    const faviconUrl = useMemo<string | undefined>(() => {
        if (webCrawlerResponse && webCrawlerResponse.favicon) {
            return webCrawlerResponse.favicon;
        }
        if (webSearchResult && webSearchResult.faviconUrl) {
            return webSearchResult.faviconUrl;
        }
    }, [webSearchResult, webCrawlerResponse])

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <LabeledData label={t("tools.singular")}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ToolAvatar name={toolBaseAttributes.name} faviconUrl={faviconUrl} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" paddingTop={0.5}>{toolBaseAttributes.name}</Typography>
                        </Grid>
                    </Grid>
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={gs}>
                    {webCrawlerResponse && (
                        <Grid item xs={12}>
                            <ScreenshotDisplay name={toolBaseAttributes.name} screenshotUrl={webCrawlerResponse.screenshot} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LabeledData label={t("description")}>
                            <Typography variant="body1">
                                {toolBaseAttributes.description}
                            </Typography>
                        </LabeledData>
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledData label={t("vendor")}>
                            <Typography variant="body1">
                                {toolBaseAttributes.vendor}
                            </Typography>
                        </LabeledData>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledData label={t("useCase")}>
                    <Typography variant="body1">
                        {useCaseDescription}
                    </Typography>
                </LabeledData>
            </Grid>
        </Grid>
    );
}