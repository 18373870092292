import {Container, Grid, LinearProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LabeledItem} from "../../base/data/LabeledItem";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {NotificationsProvider} from "../../domain/NotificationsProvider";
import {Notification} from "../../domain/notifications";
import {gs} from "../../theme";

interface NotificationViewProps {}

export default function NotificationView(props: NotificationViewProps) {
    const {api} = useAuthContext();
    const routeParams = useParams();
    const {tenantUuid} = useTenantContext();

    const {t, es} = useLanguageContext();

    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState<Notification>();

    useEffect(() => {
        if (tenantUuid && api.auth && routeParams.notificationUuid) {
            setLoading(true);
            const notificationsProvider = new NotificationsProvider(api);
            notificationsProvider
                .get(tenantUuid, routeParams.notificationUuid)
                .then(setNotification)
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [tenantUuid && routeParams, api]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={notification ? notification.subject : "..."}
                        crumbs={[
                            {
                                href: `/tenants/${tenantUuid}/notifications/`,
                                label: t("notifications.plural")
                            },
                            {
                                href: "",
                                label: notification ? notification.subject : "..."
                            }
                        ]}
                    ></AHeaderWithBreadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    {loading && <LinearProgress />}
                    {!loading && notification && (
                        <BoxedPaper>
                            <Grid container spacing={gs}>
                                <LabeledItem label={t("notifications.subject")}>{notification.subject}</LabeledItem>

                                <LabeledItem label={t("notifications.state")}>
                                    {es("notifications.states", notification.state)}
                                </LabeledItem>

                                <LabeledItem label={t("type")}>
                                    {es("notifications.types", notification.type)}
                                </LabeledItem>

                                <LabeledItem label={t("notifications.shortContent")}>
                                    {notification.shortContent}
                                </LabeledItem>

                                <LabeledItem label={t("notifications.content")}>{notification.content}</LabeledItem>
                            </Grid>
                        </BoxedPaper>
                    )}
                </Grid>

                {notification && (
                    <Grid item xs={12}>
                        <MetaDataDisplay metaData={notification.metaData} />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
