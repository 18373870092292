import {Button, Grid} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ConfirmDeleteButton} from "../../base/buttons/ConfirmDeleteButton";
import {ConfirmDialog} from "../../base/dialogs/ConfirmDialog";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AssetsProvider} from "../../domain/AssetsProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {AssetModification, UserInfo} from "../../domain/types";
import {gs} from "../../theme";
import {AssetForm} from "./edit/AssetForm";

interface AssetEditProps {}

export function AssetEdit(props: AssetEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<AssetModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [dataChanged, setDataChanged] = useState<"" | "update" | "delete">("");
    const [user, setUser] = useState<UserInfo>();

    useEffect(() => {
        fetchAsset();
    }, []);

    const fetchAsset = useCallback(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
        }
        return true;
    }, [api, tenantUuid, routeParams]);

    const fetchUser = useCallback((username: string) => {
        const userprovider = new UsersProvider(api);
        userprovider.getUserInfo(username).then((res) => {
            setUser(res);
        });
    }, []);

    const handleConfirmUpdateChange = useCallback(() => {
        fetchAsset();
        setUser(undefined);
        setDataChanged("");
    }, []);

    const handleConfirmDeleteChange = useCallback((tenantUuid: string) => {
        setUser(undefined);
        navigate(`/tenants/${tenantUuid}/assets`);
    }, []);

    const canSave = useMemo<boolean>(
        () =>
            Boolean(
                asset &&
                    asset.name &&
                    asset.type &&
                    asset.category &&
                    asset.visibility &&
                    asset.descriptions.length > 0 &&
                    asset.vendor
            ),
        [asset]
    );

    const handleSaveAsset = useCallback(() => {
        if (asset && canSave && routeParams.assetUuid && api.auth) {
            setSubmitting(true);
            const assetProvider = new AssetsProvider(api);
            assetProvider
                .modify(tenantUuid, routeParams.assetUuid, asset)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, asset, api, tenantUuid, navigate]);

    const handleDeleteAsset = useCallback(() => {
        if (asset && routeParams.assetUuid && api.auth) {
            setSubmitting(true);
            const assetProvider = new AssetsProvider(api);
            assetProvider
                .delete(tenantUuid, routeParams.assetUuid)
                .then(() => {
                    setSubmitting(false);

                    navigate(`/tenants/${tenantUuid}/assets`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, asset, api, tenantUuid, navigate]);

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`}
                onDataChangeEvent={() => fetchAsset()}
                onUpdated={(message) => {
                    fetchUser(message.username);
                    setDataChanged("update");
                }}
                onDeleted={(message) => {
                    fetchUser(message.username);
                    setDataChanged("delete");
                }}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={asset ? asset.name : "..."}
                            crumbs={[
                                {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                                {
                                    label: asset ? asset.name : "...",
                                    href: asset ? `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}` : "/assets"
                                },
                                {
                                    label: t("edit"),
                                    href: asset
                                        ? `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/edit`
                                        : "/assets"
                                }
                            ]}
                        ></AHeaderWithBreadcrumbs>
                    </Grid>
                    {asset && (
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <AssetForm asset={asset} onChange={setAsset} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={gs}>
                                                <Grid item>
                                                    <Button
                                                        onClick={handleSaveAsset}
                                                        color="primary"
                                                        variant="contained"
                                                        disabled={!canSave || submitting}
                                                    >
                                                        {t("save")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <ConfirmDeleteButton
                                                        onConfirm={handleDeleteAsset}
                                                        title={t("assets.deleteAsset")}
                                                        message={`${t("assets.deleteConfirmationMessage")} ${
                                                            asset.name
                                                        }?`}
                                                    >
                                                        {t("delete")}
                                                    </ConfirmDeleteButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                    )}
                </Grid>
            </DataChangeConsumer>

            <ConfirmDialog
                open={Boolean(dataChanged == "update" && user)}
                title={t("updated")}
                message={t("assets.changeInfo.updated", {
                    username: user ? user.fullName : ""
                })}
                onConfirm={() => handleConfirmUpdateChange()}
                onCancel={() => console.log("cancel")}
            />

            <ConfirmDialog
                open={Boolean(dataChanged == "delete" && user)}
                title={t("deleted")}
                message={t("assets.changeInfo.deleted", {
                    username: user ? user.fullName : ""
                })}
                onConfirm={() => handleConfirmDeleteChange(tenantUuid)}
                onCancel={() => console.log("cancel")}
            />
        </Container>
    );
}
