import React, {useCallback, useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {
    AiActRiskCategory,
    Question,
    QuestionScore,
    QuestionScoreRiskTemplate,
    QuestionScoreRiskTemplateModification,
    RiskCategory
} from "../../../domain/types";
import {gs} from "../../../theme";
import {Box, Button, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {EnumSelectInput} from "../../../base/inputs/EnumSelectInput";
import {AnswerInput} from "../../../forms/questionnaire/AnswerInput";
import {SimpleCheckBox} from "../../../base/inputs/SimpleCheckBox";
import {SeverityInput} from "../../../base/inputs/SeverityInput";
import {ProbabilityInput} from "../../../base/inputs/ProbabilityInput";
import {AHeader} from "../../../base/layout/AHeader";
import {TemplateAddDialog} from "./TemplateAddDialog";
import {maxLen} from "../../../libs/tools";
import {TemplateEditDialog} from "./TemplateEditDialog";

interface ScoreFormProps {
    question: Question;
    score: QuestionScore;
    templates: QuestionScoreRiskTemplate[];
    onApplyScore: (score: QuestionScore) => void;
    onApplyTemplates: (templates: QuestionScoreRiskTemplate[]) => void;
}

export function ScoreForm(props: ScoreFormProps) {
    const {question, score, templates, onApplyScore, onApplyTemplates} = props;

    const {t} = useLanguageContext();

    const [answer, setAnswer] = useState<string>(score.answer);
    const [redFlag, setRedFlag] = useState<boolean>(score.redFlag);
    const [aiActRiskCategory, setAiActRiskCategory] = useState<AiActRiskCategory | undefined>(score.aiActRiskCategory);
    const [riskCategory, setRiskCategory] = useState<RiskCategory>(score.riskCategory);
    const [probability, setProbability] = useState<number>(score.risk.probability);
    const [severity, setSeverity] = useState<number>(score.risk.probability);

    const [addTemplate, setAddTemplate] = useState<boolean>(false);
    const [editTemplate, setEditTemplate] = useState<number | undefined>();

    useEffect(() => {
        setAnswer(score.answer);
        setRedFlag(score.redFlag);
        setRiskCategory(score.riskCategory);
        setAiActRiskCategory(score.aiActRiskCategory);
        setProbability(score.risk.probability);
        setSeverity(score.risk.severity);
    }, [score]);

    const handleApplyAnswer = useCallback((answer: string) => {
        setAnswer(answer);
        onApplyScore({
            ...score,
            answer
        });
        const remappedTemplates = templates.map((t) => ({
            id: {
                answer: score.answer,
                locale: t.id.locale,
                questionUuid: question.uuid,
                riskCategory: t.id.riskCategory,
            },
            title: t.title,
            text: t.text
        }));
        onApplyTemplates(remappedTemplates);
    }, [score, templates, onApplyScore, onApplyTemplates]);

    const handleApplyRedFlag = useCallback((redFlag: boolean) => {
        setRedFlag(redFlag);
        onApplyScore({
            ...score,
            redFlag
        });
    }, [score, templates, onApplyScore]);

    const handleApplyAiActRiskCategory = useCallback((aiActRiskCategory?: AiActRiskCategory) => {
        setAiActRiskCategory(aiActRiskCategory);
        let updatedRedFlag = redFlag;
        if (aiActRiskCategory && aiActRiskCategory == AiActRiskCategory.UnacceptableRisk) {
            updatedRedFlag = true;
        }
        onApplyScore({
            ...score,
            redFlag: updatedRedFlag,
            aiActRiskCategory
        });
    }, [score, redFlag, templates, onApplyScore]);

    const handleApplyRiskCategory = useCallback((riskCategory: RiskCategory) => {
        setRiskCategory(riskCategory);
        onApplyScore({
            ...score,
            riskCategory
        });
        const remappedTemplates = templates.map((t) => ({
            id: {
                answer: t.id.answer,
                locale: t.id.locale,
                questionUuid: question.uuid,
                riskCategory
            },
            title: t.title,
            text: t.text
        }));
        onApplyTemplates(remappedTemplates);
    }, [score, templates, onApplyScore, onApplyTemplates]);

    const handleApplyProbability = useCallback((probability: number) => {
        setProbability(probability);
        onApplyScore({
            ...score,
            risk: {
                probability,
                severity
            }
        });
    }, [score, onApplyScore]);

    const handleApplySeverity = useCallback((severity: number) => {
        setSeverity(severity);
        onApplyScore({
            ...score,
            risk: {
                probability,
                severity
            }
        });
    }, [score, onApplyScore]);

    const handleCloseAddTemplate = useCallback((template?: QuestionScoreRiskTemplateModification) => {
        if (template) {
            onApplyTemplates([...templates, {
                id: {
                    answer: score.answer,
                    locale: template.locale,
                    questionUuid: question.uuid,
                    riskCategory: score.riskCategory
                },
                title: template.title,
                text: template.text
            }]);
        }
        setAddTemplate(false);
    }, [templates, score, onApplyTemplates]);

    const handleCloseEditTemplate = useCallback((template?: QuestionScoreRiskTemplateModification) => {
        if (template) {
            const newTemplates = [...templates];
            newTemplates[editTemplate || 0] = {
                id: {
                    answer: score.answer,
                    locale: template.locale,
                    questionUuid: question.uuid,
                    riskCategory: score.riskCategory
                },
                title: template.title,
                text: template.text
            };
            onApplyTemplates(newTemplates);
        }
        setEditTemplate(undefined);
    }, [templates, editTemplate, score, onApplyTemplates]);

    const handleDeleteTemplate = useCallback(() => {
        const newTemplates = [...templates.filter((_, index) => index !== editTemplate)];
        onApplyTemplates(newTemplates);
        setEditTemplate(undefined);
    }, [templates, editTemplate, onApplyTemplates]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <Grid item xs={12} sm={6} md={4}>
                        <AnswerInput question={question} value={answer} onChange={handleApplyAnswer} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <EnumSelectInput
                            label={t("questionnaires.question.aiActRiskCategory")}
                            options={Object.keys(AiActRiskCategory)}
                            lookupKey={"risks.aiActRiskCategories"}
                            value={aiActRiskCategory}
                            onChange={handleApplyAiActRiskCategory}
                            allowNone
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <SimpleCheckBox label={t("questionnaires.question.redFlag")} checked={redFlag} onChange={handleApplyRedFlag} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <Grid item xs={12} sm={12} md={4}>
                        <EnumSelectInput
                            label={t("assets.headerNames.category")}
                            options={Object.keys(RiskCategory)}
                            lookupKey={"risks.riskCategories"}
                            value={riskCategory}
                            onChange={handleApplyRiskCategory}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <ProbabilityInput
                            label={t("assets.headerNames.probability")}
                            value={probability}
                            onChange={handleApplyProbability}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <SeverityInput
                            label={t("assets.headerNames.severity")}
                            value={severity}
                            onChange={handleApplySeverity}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <AHeader variant="h6" title={t("questionnaires.question.riskTemplates.title")}>
                    <Button variant="outlined" onClick={() => setAddTemplate(true)}>{t("questionnaires.question.riskTemplates.add")}</Button>
                </AHeader>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableCell>{t("questionnaires.question.riskTemplates.riskTitle")}</TableCell>
                        <TableCell>{t("questionnaires.question.riskTemplates.template")}</TableCell>
                        <TableCell>{t("language")}</TableCell>
                    </TableHead>
                    <TableBody>
                        {templates.length == 0 &&
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Box p={3}>
                                        <Typography variant="body1" align="center">
                                            {t("questionnaires.question.riskTemplates.none")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        }
                        {templates
                            .map((template, index) =>
                                <TableRow key={index} hover onClick={() => setEditTemplate(index)}>
                                    <TableCell>
                                        {maxLen(template.title, 30)}
                                    </TableCell>
                                    <TableCell>
                                        {maxLen(template.text, 60)}
                                    </TableCell>
                                    <TableCell>
                                        {template.id.locale}
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Grid>
            <TemplateAddDialog open={addTemplate} onClose={handleCloseAddTemplate} />
            {editTemplate != undefined &&
                <TemplateEditDialog
                    open={true}
                    value={templates[editTemplate]}
                    onClose={handleCloseEditTemplate}
                    onDelete={handleDeleteTemplate}
                />
            }
        </Grid>
    );
}