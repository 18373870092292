import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {ActivitiesProvider} from "../../domain/ActivitiesProvider";
import {IActivity} from "../../domain/types";
import {gs} from "../../theme";
import {DateDisplay} from "../displays/DateDisplay";
import {UserDisplay} from "../displays/UserDisplay";
import {BoxedPaper} from "../layout/BoxedPaper";

interface ActivitiesProps {
    assetUuid?: string;
    toolUuid?: string;
}

export function Activities(props: ActivitiesProps) {
    const {assetUuid, toolUuid} = props;

    const {tenantUuid} = useTenantContext();
    const {api} = useAuthContext();

    const {t, es} = useLanguageContext();

    const [activities, setActivities] = useState<IActivity[]>([]);

    useEffect(() => {
        if (api.auth) {
            const activitiesProvider = new ActivitiesProvider(api);
            if (assetUuid) {
                activitiesProvider
                    .listAssetActivities(tenantUuid, assetUuid)
                    .then(setActivities)
                    .catch((error) => console.log(error));
            }
            if (toolUuid) {
                activitiesProvider
                    .listToolActivities(tenantUuid, toolUuid)
                    .then(setActivities)
                    .catch((error) => console.log(error));
            }
        }
    }, [tenantUuid, api, assetUuid, toolUuid]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Typography variant="h2">{t("activities.plural")}</Typography>
            </Grid>

            <Grid item xs={12}>
                <BoxedPaper>
                    <Grid container spacing={gs}>
                        <Timeline position="left">
                            {activities.map((activity, index) => (
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent color="textSecondary">
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <UserDisplay username={activity.createdBy} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DateDisplay date={activity.createdAt} />
                                            </Grid>
                                        </Grid>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        {index !== activities.length - 1 && <TimelineConnector />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="body1">
                                            {es("activities.types", activity.type)}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                </BoxedPaper>
            </Grid>
        </Grid>
    );
}
