import * as React from "react";
import Box from "@mui/material/Box";
import theme, {gs} from "../theme";
import {Grid, Typography} from "@mui/material";
import {AGridItemGrow} from "../base/layout/AGridItemGrow";
import {useMemo} from "react";
import {useLanguageContext} from "../context/language/LanguageContext";
import {VersionDisplay} from "../base/displays/VersionDisplay";

interface BottomNavProps {
}

export function BottomNav(props: BottomNavProps) {
    const {t} = useLanguageContext();
    const currentYear = useMemo<number>(() => new Date().getFullYear(), []);

    return (
        <Box p={2} sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            display: {xs: "none"}
        }}>
            <Grid container spacing={gs}>
                <AGridItemGrow>
                    <Typography variant="body2">© 2024 - {currentYear}: {t("appName")}</Typography>
                </AGridItemGrow>
                <Grid item>
                    <VersionDisplay />
                </Grid>
            </Grid>
        </Box>
    );
}
