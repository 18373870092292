import {Autocomplete, Divider, Grid, TextField} from "@mui/material";
import * as locales from "@mui/material/locale";
import React, {useCallback, useState} from "react";
import {EnumSelectInput} from "../../../base/inputs/EnumSelectInput";
import {TextInput} from "../../../base/inputs/TextInput";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Address, CompanySize, TenantModification} from "../../../domain/types";
import {gs} from "../../../theme";

type SupportedLocales = keyof typeof locales;

const materialLocales = locales;

interface TenantFormProps {
    tenant: TenantModification;
    onChange: (tenant: TenantModification) => void;
}

export function TenantForm(props: TenantFormProps) {
    const {tenant, onChange} = props;

    const {t} = useLanguageContext();

    const [name, setName] = useState(tenant.name);
    const [companySize, setCompanySize] = useState<CompanySize>(tenant.companySize);
    const [industryCode, setIndustryCode] = useState(tenant.industryCode);
    const [address, setAddress] = useState(tenant.address || {});
    const [defaultLocale, setDefaultLocale] = useState(tenant.defaultLocale);
    const [locales, setLocales] = useState(tenant.locales);

    const handleNameChanged = useCallback(
        (name: string) => {
            setName(name);
            onChange({
                name,
                address: address,
                companySize: companySize,
                defaultLocale: defaultLocale,
                industryCode: industryCode,
                locales: locales
            });
        },
        [address, companySize, defaultLocale, industryCode, locales, onChange]
    );

    const handleCompanySizeChanged = useCallback(
        (size: CompanySize) => {
            setCompanySize(size);
            onChange({
                name: name,
                address: address,
                companySize: size,
                defaultLocale: defaultLocale,
                industryCode: industryCode,
                locales: locales
            });
        },
        [name, address, defaultLocale, industryCode, locales, onChange]
    );

    const handleIndustryCodeChanged = useCallback(
        (code: string) => {
            setIndustryCode(code);
            onChange({
                name: name,
                address: address,
                companySize: companySize,
                defaultLocale: defaultLocale,
                industryCode: code,
                locales: locales
            });
        },
        [name, address, companySize, defaultLocale, locales, onChange]
    );

    const handleAddressChanged = useCallback(
        (field: keyof Address, value: string) => {
            const prevAddress = {...address};
            prevAddress[field] = value;
            setAddress(prevAddress);
            onChange({
                name: name,
                address: prevAddress,
                companySize: companySize,
                defaultLocale: defaultLocale,
                industryCode: industryCode,
                locales: locales
            });
        },
        [name, companySize, defaultLocale, industryCode, locales, onChange]
    );

    const handleDefaultLocaleChanged = useCallback(
        (locale: string) => {
            setDefaultLocale(locale);
            onChange({
                name: name,
                address: address,
                companySize: companySize,
                defaultLocale: locale,
                industryCode: industryCode,
                locales: locales
            });
        },
        [name, address, companySize, industryCode, locales, onChange]
    );

    const handleLocalesChanged = useCallback(
        (locals: string[]) => {
            setLocales(locales);
            onChange({
                name: name,
                address: address,
                companySize: companySize,
                defaultLocale: defaultLocale,
                industryCode: industryCode,
                locales: locals
            });
        },
        [name, address, companySize, defaultLocale, industryCode, onChange]
    );

    return (
        <Grid item xs={12}>
            <BoxedPaper>
                <Grid container spacing={gs}>
                    <Grid item xs={12} sm={6} md={8}>
                        <TextInput label={t("name")} onChange={(v) => handleNameChanged(v)} value={tenant.name} />
                    </Grid>

                    <Grid item xs={12} sm={3} md={2}>
                        <EnumSelectInput
                            label={t("tenant.companySize")}
                            onChange={(v) => handleCompanySizeChanged(v)}
                            options={Object.keys(CompanySize)}
                            value={tenant.companySize}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} md={2}>
                        <TextInput
                            label={t("tenant.industry")}
                            onChange={(v) => handleIndustryCodeChanged(v)}
                            value={tenant.industryCode}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {/* address */}
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <TextInput
                                    label={t("tenant.line1")}
                                    onChange={(v) => handleAddressChanged("line1", v)}
                                    value={tenant.address.line1 || ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    label={t("tenant.line2")}
                                    onChange={(v) => handleAddressChanged("line2", v)}
                                    value={tenant.address.line2 || ""}
                                />
                            </Grid>

                            <Grid item xs={6} sm={3} md={2}>
                                <TextInput
                                    label={t("tenant.countryCode")}
                                    onChange={(v) => handleAddressChanged("countryCode", v)}
                                    value={tenant.address.countryCode || ""}
                                />
                            </Grid>

                            <Grid item xs={6} sm={3} md={2}>
                                <TextInput
                                    label={t("tenant.zip")}
                                    onChange={(v) => handleAddressChanged("zip", v)}
                                    value={tenant.address.zip || ""}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={8}>
                                <TextInput
                                    label={t("tenant.city")}
                                    onChange={(v) => handleAddressChanged("city", v)}
                                    value={tenant.address.city || ""}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {/* locales */}
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={Object.keys(materialLocales)}
                                    getOptionLabel={(key) => `${key.substring(0, 2)}-${key.substring(2, 4)}`}
                                    value={tenant.defaultLocale}
                                    disableClearable
                                    onChange={(event: any, newValue: string | null) => {
                                        handleDefaultLocaleChanged(newValue as SupportedLocales);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("tenant.defaultLocale")} fullWidth />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    options={Object.keys(materialLocales)}
                                    getOptionLabel={(key) => `${key.substring(0, 2)}-${key.substring(2, 4)}`}
                                    value={tenant.locales}
                                    disableClearable
                                    multiple
                                    disableCloseOnSelect
                                    onChange={(event: any, newValue) => {
                                        handleLocalesChanged(newValue as SupportedLocales[]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("tenant.locales")} fullWidth />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BoxedPaper>
        </Grid>
    );
}
