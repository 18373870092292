import React, {useCallback, useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Permission} from "../../../domain/types";
import {Grid} from "@mui/material";
import {gs} from "../../../theme";
import {TextInput} from "../../../base/inputs/TextInput";
import {SimpleCheckBox} from "../../../base/inputs/SimpleCheckBox";

interface PermissionEditFormProps {
    permission: Permission;
    onChange: (permission: Permission) => void;
}

export function PermissionEditForm(props: PermissionEditFormProps) {
    const {permission, onChange} = props;

    const handleChangeEntity = useCallback((entity: string) => {
        onChange({...permission, entity});
    }, [onChange, permission]);

    const isChecked = useCallback((checked: string) => {
        return Boolean(permission.permissions.find((p) => checked == p));
    }, [permission]);

    const handlePermissionChanged = useCallback((checked: string, value: boolean) => {
        if (value) {
            onChange({...permission, permissions: [...permission.permissions, checked]});
        } else {
            onChange({...permission, permissions: permission.permissions.filter((p) => p != checked)});
        }
    }, [onChange, permission]);

    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <TextInput
                    label={t("roles.entity")}
                    value={permission.entity}
                    onChange={handleChangeEntity}
                />
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={gs}>
                    <Grid item>
                        <SimpleCheckBox label={"READ"} checked={isChecked("READ")} onChange={(v) => handlePermissionChanged("READ", v)} />
                    </Grid>
                    <Grid item>
                        <SimpleCheckBox label={"WRITE"} checked={isChecked("WRITE")} onChange={(v) => handlePermissionChanged("WRITE", v)} />
                    </Grid>
                    <Grid item>
                        <SimpleCheckBox label={"DELETE"} checked={isChecked("DELETE")} onChange={(v) => handlePermissionChanged("DELETE", v)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}