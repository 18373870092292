import React, {useCallback} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {gs} from "../../../theme";
import {Grid} from "@mui/material";
import {TextInput} from "../../../base/inputs/TextInput";

export interface ToolBaseAttributesForm {
    name: string;
    description: string;
    vendor: string;
}

interface ManualEntryStepProps {
    form: ToolBaseAttributesForm;
    onChange: (form: ToolBaseAttributesForm) => void;
}

export function ManualEntryStep(props: ManualEntryStepProps) {
    const {form, onChange} = props;
    const {t} = useLanguageContext();

    const handleFormChange = useCallback((field: string, value: string) => {
        onChange({
            ... form,
            [field]: value
        });
    }, [form]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={6} md={8}>
                <TextInput
                    label={t("name")}
                    onChange={(v) => handleFormChange("name", v)}
                    value={form.name}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextInput label={t("vendor")} value={form.vendor} onChange={(v) => handleFormChange("vendor", v)} />
            </Grid>
            <Grid item xs={12}>
                <TextInput label={t("description")} value={form.description} onChange={(v) => handleFormChange("description", v)} rows={10} />
            </Grid>
        </Grid>
    );
}