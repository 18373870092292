import {DateTime} from "luxon";
import {v4 as uuid} from "uuid";
import {Api} from "./Api";

export interface WebSocketTicket {
    ticketUuid: string;
    validUntil: DateTime;
    tenantUuid: string;
    username: string;
}

export enum DataChangeType {
    Created = "Created",
    Updated = "Updated",
    Deleted = "Deleted"
}

export interface DataChangedEvent {
    tenantUuid: string;
    username: string;
    entityPath: {
        entityPath: string;
    };
    changeType: DataChangeType;
}

export interface WebSocketListener {
    onMessage(message: DataChangedEvent): boolean;
    entityPath?: string;
    filter?: DataChangeType[];
}

export class WebSocketConnection {
    readonly api: Api;
    readonly webSocketUrl: string;
    readonly listeners: Map<string, WebSocketListener>;
    readonly webSocket: WebSocket;
    readonly debug: boolean;

    private static toWsUrl(url: string): string {
        return url.replace("http://", "ws://").replace("https://", "wss://");
    }

    constructor(api: Api, ticketUuid: string) {
        this.api = api;
        this.webSocketUrl = WebSocketConnection.toWsUrl(api.apiBaseUrl) + `/data-changes?ticketUuid=${ticketUuid}`;
        this.listeners = new Map<string, WebSocketListener>();
        this.webSocket = new WebSocket(this.webSocketUrl);
        this.debug = localStorage.getItem("debug-websocket") === "true";
        this.webSocket.onmessage = (event) => {
            const message: DataChangedEvent = JSON.parse(event.data);
            let count = 0;
            if (this.debug) {
                console.log("Processing DataChangedEvent", message);
            }
            for (let listener of this.listeners.values()) {
                if (this.debug) {
                    console.log(`Checking listener ${count + 1} for ${listener.entityPath} and ${listener.filter}`);
                }
                if (!listener.entityPath || message.entityPath.entityPath.startsWith(listener.entityPath)) {
                    if (!listener.filter || listener.filter.includes(message.changeType)) {
                        count++;
                        if (!listener.onMessage(message)) {
                            console.log(`Listener ${count} prevented further distribution`);
                            break;
                        }
                    }
                }
            }
            console.log(
                `Received DataChangedEvent ${message.changeType} for ${message.entityPath.entityPath}, distributed to ${count} of ${this.listeners.size} listeners`
            );
        };
    }

    addListener(listener: WebSocketListener): string {
        const listenerUuid = uuid();
        this.listeners.set(listenerUuid, listener);
        return listenerUuid;
    }

    removeListener(listenerUuid: string) {
        this.listeners.delete(listenerUuid);
    }

    close() {
        this.listeners.clear();
        this.webSocket.close();
        console.log("WebSocketConnection closed");
    }
}
