import {Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import React, {useMemo} from "react";
import {LabeledData} from "../../../base/data/LabeledData";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {ALoading} from "../../../base/loading/ALoading";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {AiActRiskCategory, compareAiActRiskCategories, IAssetRisk, IKiTool} from "../../../domain/types";
import {gs} from "../../../theme";
import {RisksList} from "./RisksList";
import {AiActRiskCategoryDisplay} from "../../../base/displays/AiActRiskCategoryDisplay";

interface StepSummaryProps {
    kiTool?: IKiTool;
    name?: string;
    description?: string;
    questionnaire?: QuestionnaireWrapper;
    risks?: IAssetRisk[];
}

export function StepSummary(props: StepSummaryProps) {
    const {kiTool, name, description, questionnaire, risks} = props;
    const {t} = useLanguageContext();

    const aiActRiskCategory = useMemo<AiActRiskCategory | undefined>(() => {
        if (risks) {
            return risks
                .filter((r) => r.aiActRiskCategory)
                .map((r) => r.aiActRiskCategory)
                .sort(compareAiActRiskCategories)
                .pop();
        }
    }, [risks]);

    return (
        <Grid container spacing={gs}>
            {name && <LabeledItem label={t("name")}>{name}</LabeledItem>}
            {kiTool && name && kiTool.name != name && (
                <LabeledItem label={t("tools.kiTool.singular")}>{kiTool.name}</LabeledItem>
            )}
            {aiActRiskCategory &&
                <LabeledItem label={t("questionnaires.question.aiActRiskCategory")}>
                    <AiActRiskCategoryDisplay riskCategory={aiActRiskCategory} />
                </LabeledItem>
            }
            {description && <LabeledItem label={t("tools.description")}>{description}</LabeledItem>}
            {!risks && (
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <ALoading />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                                {t("risks.wizard.computing")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {risks && (
                <Grid item xs={12}>
                    <LabeledData label={t("risks.plural")}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Typography variant="body2">{t("risks.wizard.generatedRisks")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RisksList risks={risks} />
                            </Grid>
                        </Grid>
                    </LabeledData>
                </Grid>
            )}
        </Grid>
    );
}
