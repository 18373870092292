import {Button, Grid} from "@mui/material";
import {GridColDef, GridSortModel} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {AHeader} from "../../../base/layout/AHeader";
import {ATable} from "../../../base/table/ATable";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IAssetRisk, IMeasure} from "../../../domain/types";
import {gs} from "../../../theme";
import {RiskWrapper} from "../../../domain/risks";
import {AiActRiskCategoryDisplay} from "../../../base/displays/AiActRiskCategoryDisplay";

interface AssetRisksBlockProps {
    asset: IAsset;
}

interface AssetRisksAligned {
    assetRisk: IAssetRisk;
    measure?: IMeasure;
}

export function AssetRisksBlock(props: AssetRisksBlockProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, es} = useLanguageContext();
    const navigate = useNavigate();

    const [risks, setRisks] = useState<IAssetRisk[]>();
    const [alignedRisks, setAlignedRisks] = useState<AssetRisksAligned[]>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "assetRisk.grossRisk",
            sort: "desc"
        }
    ]);

    const columns: GridColDef[] = [
        {
            field: "assetRisk.name",
            headerName: t("assets.headerNames.name"),
            flex: 1,
            renderCell: (r) => r.row.assetRisk.name
        },
        {
            field: "assetRisk.category",
            headerName: t("assets.headerNames.category"),
            flex: 1,
            renderCell: (params) => es("risks.riskCategories", params.row.assetRisk.category)
        },
        {
            field: "assetRisk.aiActRiskCategory",
            headerName: t("assets.headerNames.aiActRiskCategory"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <AiActRiskCategoryDisplay riskCategory={r.row.assetRisk.aiActRiskCategory} />
        },
        {
            field: "assetRisk.grossRisk",
            headerName: t("assets.headerNames.grossRisk"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => (
                <TotalRiskDisplay totalRisk={r.row.assetRisk.risk.probability * r.row.assetRisk.risk.severity} />
            )
        },
        {
            field: "measures.netRisk",
            headerName: t("assets.headerNames.netRisk"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) =>
                r.row.measure ? (
                    <TotalRiskDisplay totalRisk={r.row.measure.risk.probability * r.row.measure.risk.severity} />
                ) : null
        }
    ];

    useEffect(() => {
        if (api.auth) {
            fetchRisks();
            // const assetsProvider = new AssetsProvider(api);
            // assetsProvider.risks(tenantUuid, asset.uuid).then(setRisks);
        }
    }, [api, tenantUuid, asset]);

    const fetchRisks = useCallback(() => {
        const assetsProvider = new AssetsProvider(api);
        assetsProvider.risks(tenantUuid, asset.uuid).then(setRisks);
    }, [api, tenantUuid, asset]);

    useEffect(() => {
        if (risks) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.measures(tenantUuid, asset.uuid).then((measures) => {
                const aligned: AssetRisksAligned[] = risks.map((r) => {
                    const measure = measures
                        .filter((m) => m.assetRisk && m.assetRisk.uuid === r.uuid)
                        .sort((a, b) => a.priority - b.priority)
                        .pop();
                    return {assetRisk: r, measure};
                });
                setAlignedRisks(aligned);
            });
        }
    }, [api, tenantUuid, asset, risks]);

    const sorted = useMemo<AssetRisksAligned[] | undefined>(() => {
        if (alignedRisks && sortModel) {
            return [...alignedRisks].sort((a, b) => RiskWrapper.compare(a.assetRisk, b.assetRisk, sortModel));
        }
    }, [alignedRisks, sortModel]);

    return (
        <DataChangeConsumer
            entityPath={`/tenants/${tenantUuid}/assets/${asset.uuid}`}
            onUpdated={() => fetchRisks()}
            onDeleted={() => fetchRisks()}
        >
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeader title={t("risks.plural")} variant="h2">
                        <Button onClick={() => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/risks/add`)}>
                            {t("risks.addRisk")}
                        </Button>
                    </AHeader>
                </Grid>
                {sorted && (
                    <Grid item xs={12}>
                        <ATable
                            rows={sorted}
                            columns={columns}
                            onClick={(row) =>
                                navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/risks/${row.assetRisk.uuid}`)
                            }
                            getRowId={(r) => r.assetRisk.uuid}
                            onSortModelChanged={setSortModel}
                        />
                    </Grid>
                )}
            </Grid>
        </DataChangeConsumer>
    );
}
