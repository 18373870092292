import {StorableFilter} from "./filters";
import {IAsset, IAssetRisk, IKiTool, IMeasure, ITask, ITenant, MetaData} from "./types";

export enum NotificationType {
    CommentAdded = "CommentAdded",
    AssetRequested = "AssetRequested",
    TaskCreated = "TaskCreated",
    TaskCompleted = "TaskCompleted",
    TaskUpdated = "TaskUpdated",
    TaskStarted = "TaskStarted",
    TaskDeleted = "TaskDeleted",
    AssetCreated = "AssetCreated",
    AssetUpdated = "AssetUpdated",
    AssetStateUpdated = "AssetStateUpdated",
    AssetDeleted = "AssetDeleted",
    AssetRiskCreated = "AssetRiskCreated",
    AssetRiskUpdated = "AssetRiskUpdated",
    AssetRiskDeleted = "AssetRiskDeleted",
    MeasureCreated = "MeasureCreated",
    MeasureUpdated = "MeasureUpdated",
    MeasureStateUpdated = "MeasureStateUpdated",
    MeasureDeleted = "MeasureDeleted"
}

export interface NotificationHistory {
    createdAt: string;
    state: NotificationState;
    info: string;
}

export enum NotificationState {
    Created = "Created",
    Sent = "Sent",
    Delivered = "Delivered",
    Read = "Read",
    Failed = "Failed"
}

export interface NotificationCreationRequest {
    notificationUuids: string[];
}

export interface Notification {
    uuid: string;
    version: number;
    owner: ITenant;
    recipient: string;
    type: NotificationType;
    state: NotificationState;
    subject: string;
    readAt: string;
    shortContent: string;
    content: string;
    tool: IKiTool;
    asset: IAsset;
    assetRisk: IAssetRisk;
    measure: IMeasure;
    task: ITask;
    comment: Comment;
    entityPath: string;
    metaData: MetaData;
}

export class NotificationWrapper {
    readonly notification: Notification;

    constructor(notification: Notification) {
        this.notification = notification;
    }

    get read(): boolean {
        return Boolean(this.notification.readAt);
    }
}

export class NotificationsWrapper {
    readonly notifications: NotificationWrapper[];

    constructor(notifications: Notification[]) {
        this.notifications = notifications.map((n) => new NotificationWrapper(n));
    }

    get unread(): NotificationWrapper[] {
        return this.notifications.filter((n) => !n.read);
    }

    findUnreadByPath(path: string): NotificationWrapper[] {
        return this.unread.filter((n) => n.notification.content === path);
    }
}

export interface NotificationsFilter {
    needle: string;
    notificationTypes: NotificationType[];
}

export const emptyFilter: NotificationsFilter = {
    needle: "",
    notificationTypes: []
};

export class NotificationsFilterWrapper extends StorableFilter<NotificationsFilter, Notification> {
    constructor(filter?: NotificationsFilter) {
        super(filter || emptyFilter, "notifications-filter");
    }

    matches(model: Notification): boolean {
        if (this.filter.needle) {
            if (!model.subject.toLowerCase().includes(this.filter.needle.toLowerCase())) {
                return false;
            }
        }
        if (this.filter.notificationTypes.length > 0) {
            if (!this.filter.notificationTypes.includes(model.type)) {
                return false;
            }
        }
        return true;
    }
}
