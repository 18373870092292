import {Grid} from "@mui/material";
import React, {useCallback} from "react";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {ALink} from "../../../base/layout/ALink";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {KiToolsProvider} from "../../../domain/KiToolsProvider";
import {IKiTool} from "../../../domain/types";
import {gs} from "../../../theme";
import {DocumentToolAvatar, ToolAvatar} from "../../../base/displays/ToolAvatar";
import {DocumentScreenshotDisplay} from "../../../base/displays/ScreenshotDisplay";
import {LabeledData} from "../../../base/data/LabeledData";

interface KiToolDetails {
    kiTool: IKiTool;
    onTranslate: () => void;
}

export function KiToolDetails(props: KiToolDetails) {
    const {kiTool, onTranslate} = props;

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, es} = useLanguageContext();

    const handleTranslate = useCallback(
        async (target: string) => {
            if (api.auth && kiTool.uuid) {
                const toolsProvider = new KiToolsProvider(api);
                await toolsProvider
                    .translate(tenantUuid, kiTool.uuid, {
                        targetLanguage: target
                    })
                    .then(() => {
                        onTranslate();
                    });
            }
        },
        [api, kiTool]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={kiTool.screenshot ? 6 : 12} md={kiTool.screenshot ? 8 : 12}>
                <Grid container spacing={gs}>
                    {kiTool.favicon && (
                        <LabeledItem label={t("favicon.title")}>
                            <DocumentToolAvatar name={kiTool.name} documentUuid={kiTool.favicon.uuid} />
                        </LabeledItem>
                    )}
                    <LabeledItem label={t("tools.headers.type")}>{es("tools.toolType", kiTool.type)}</LabeledItem>
                    <LabeledItem label={t("tools.headers.category")}>
                        {es("tools.toolCategory", kiTool.category)}
                    </LabeledItem>
                    <LabeledItem label={t("tools.headers.visibility")}>
                        {es("tools.toolVisibility", kiTool.visibility)}
                    </LabeledItem>
                    <LabeledItem label={t("tools.headers.vendor")}>{kiTool.vendor}</LabeledItem>
                    {kiTool.basedOn && (
                        <LabeledItem label={t("tools.headers.basedOn")}>
                            <ALink href={`/tenants/${tenantUuid}/tools/${kiTool.basedOn.uuid}`}>
                                {kiTool.basedOn.name}
                            </ALink>
                        </LabeledItem>
                    )}
                    <Grid item xs={12}>
                        <LabeledData label={t("tools.description")}>
                            <MultiLingualTextDisplay
                                texts={kiTool.descriptions}
                                onTranslate={(target) => handleTranslate(target)}
                            ></MultiLingualTextDisplay>
                        </LabeledData>
                    </Grid>
                    {kiTool.url && (
                        <Grid item xs={12}>
                            <LabeledData label={t("url")}>
                                <ALink href={kiTool.url}>{kiTool.url}</ALink>
                            </LabeledData>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {kiTool.screenshot && (
                <Grid item xs={12} sm={6} md={4}>
                    <DocumentScreenshotDisplay name={kiTool.name} documentUuid={kiTool.screenshot.uuid} />
                </Grid>
            )}
        </Grid>
    );
}
