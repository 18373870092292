import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Container, Grid} from "@mui/material";
import {gs} from "../../../theme";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {IAsset} from "../../../domain/types";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {AssetRiskWizard, AssetRiskWizardMode} from "../add/AssetRiskWizard";
import {ALoading} from "../../../base/loading/ALoading";

interface AssetAssetsRisksProps {

}

export function AssetAssetsRisks(props: AssetAssetsRisksProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [asset, setAsset] = useState<IAsset>();

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("assets.assessAsset")}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                            },
                            {
                                label: t("assets.assessAsset"),
                                href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}/risks/questionnaire` : "/assets"
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!asset && <ALoading />}
                    {asset &&
                        <AssetRiskWizard mode={AssetRiskWizardMode.AssessAsset} asset={asset} />
                    }
                </Grid>
            </Grid>
        </Container>
    );
}