import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {RolesProvider} from "../../domain/RolesProvider";
import {RoleModification} from "../../domain/types";
import {gs} from "../../theme";
import {RoleForm} from "./edit/RoleForm";

interface RoleEditProps {}

export function RoleEdit(props: RoleEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [role, setRole] = useState<RoleModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (routeParams.roleUuid && api.auth) {
            const rolesProvider = new RolesProvider(api);
            rolesProvider.get(tenantUuid, routeParams.roleUuid).then((role) => {
                setRole(role);
            });
        }
    }, [api, routeParams]);

    const canSave = useMemo<boolean>(() => {
        return Boolean(role && role.name && role.permissions.permissions.length > 0);
    }, [role]);

    const handleSaveRole = useCallback(() => {
        if (role && canSave && routeParams.roleUuid && api.auth) {
            setSubmitting(true);
            const rolesProvider = new RolesProvider(api);
            rolesProvider
                .modify(tenantUuid, routeParams.roleUuid, role)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, role, api, tenantUuid, navigate]);

    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={role ? role.name : "..."}
                        crumbs={[
                            {label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`},
                            {
                                label: role ? role.name : "...",
                                href: `/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`
                            },
                            {label: t("roles.edit"), href: `/tenants/${tenantUuid}/roles/${routeParams.roleUuid}/edit`}
                        ]}
                    />
                </Grid>
                {role && (
                    <Grid item xs={12}>
                        <BoxedPaper loading={submitting}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <RoleForm role={role} onChange={setRole} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={handleSaveRole}
                                        color="primary"
                                        variant="contained"
                                        disabled={!canSave || submitting}
                                    >
                                        {t("roles.save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
