import {Edit} from "@mui/icons-material";
import {Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {LabeledData} from "../../base/data/LabeledData";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {QuestionnairesProvider} from "../../domain/QuestionnairesProvider";
import {QuestionnaireWrapper} from "../../domain/questionnaires";
import {MultilingualTextWrapper, Question, QuestionScoreRiskTemplate, Questionnaire} from "../../domain/types";
import {gs} from "../../theme";
import {QuestionsTree} from "./QuestionsTree";

interface QuestionnaireViewProps {}

export function QuestionnaireView(props: QuestionnaireViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid, role} = useTenantContext();
    const navigate = useNavigate();
    const {t, es, language} = useLanguageContext();

    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    const [questions, setQuestions] = useState<Question[]>();
    const [selectedQuestion, setSelectedQuestion] = useState<Question>();
    const [riskTemplates, setRiskTemplates] = useState<QuestionScoreRiskTemplate[]>();

    const [search, setSearch] = useSearchParams();

    const fetchQuestionnaire = useCallback(() => {
        if (routeParams.slug && api.auth) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.get(tenantUuid, routeParams.slug).then(setQuestionnaire);
            questionnairesProvider.questions(tenantUuid, routeParams.slug).then((questions) => {
                setQuestions(questions);
                const selectQuestionUuid = search.get("selectQuestionUuid");
                if (selectQuestionUuid) {
                    const question = questions.find((q) => q.uuid === selectQuestionUuid);
                    if (question) {
                        setSelectedQuestion(question);
                    }
                }
            });
        }
    }, [api, tenantUuid, routeParams, search]);

    useEffect(() => {
        fetchQuestionnaire();
    }, [fetchQuestionnaire]);

    const wrapper = useMemo<QuestionnaireWrapper | undefined>(() => {
        if (questionnaire && questions) {
            return new QuestionnaireWrapper(questionnaire, questions);
        }
        return undefined;
    }, [questionnaire, questions]);

    const handleSelectQuestion = useCallback(
        (question: Question) => {
            setSelectedQuestion(question);
            if (routeParams.slug && api.auth) {
                const questionnairesProvider = new QuestionnairesProvider(api);
                questionnairesProvider
                    .listRiskTemplates(tenantUuid, routeParams.slug, question.uuid)
                    .then(setRiskTemplates);
            }
        },
        [api, tenantUuid, routeParams]
    );

    const handleTranslate = useCallback(
        async (target: string) => {
            if (api.auth && questionnaire) {
                const questionnairesProvider = new QuestionnairesProvider(api);
                await questionnairesProvider
                    .translate(tenantUuid, questionnaire.slug, {
                        targetLanguage: target
                    })
                    .then(() => {
                        fetchQuestionnaire();
                    });
            }
        },
        [api, questionnaire]
    );

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={
                            questionnaire ? new MultilingualTextWrapper(questionnaire.titles).resolved(language) : "..."
                        }
                        crumbs={[
                            {label: t("questionnaires.plural"), href: `/tenants/${tenantUuid}/questionnaires`},
                            {
                                label: questionnaire
                                    ? new MultilingualTextWrapper(questionnaire.titles).resolved(language)
                                    : "...",
                                href: `/tenants/${tenantUuid}/questionnaires/${routeParams.slug}`
                            }
                        ]}
                    />
                </Grid>
                {wrapper && (
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper>
                                <QuestionsTree questionnaire={wrapper} onSelect={handleSelectQuestion} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <BoxedPaper>
                                {!selectedQuestion && (
                                    <Box p={2}>
                                        <Typography variant="body2" align="center">
                                            {t("questionnaires.noQuestionSelected")}
                                        </Typography>
                                    </Box>
                                )}
                                {selectedQuestion && (
                                    <Grid
                                        container
                                        spacing={gs}
                                        sx={{
                                            position: "relative"
                                        }}
                                    >
                                        {role?.canExertPermission(
                                            `/tenants/${wrapper.questionnaire.owner.uuid}/questionnaires/${wrapper.questionnaire.slug}`,
                                            "WRITE"
                                        ) && (
                                            <IconButton
                                                onClick={() => {
                                                    navigate(`${selectedQuestion.uuid}/edit`);
                                                }}
                                                sx={{
                                                    position: "absolute",
                                                    right: "0",
                                                    top: "1rem"
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        )}
                                        <Grid item xs={12}>
                                            <LabeledData label={t("questionnaires.title")}>
                                                <MultiLingualTextDisplay
                                                    texts={selectedQuestion.titles}
                                                    onTranslate={(target) => handleTranslate(target)}
                                                />
                                            </LabeledData>
                                        </Grid>
                                        {selectedQuestion.descriptions.length > 0 && (
                                            <Grid item xs={12}>
                                                <LabeledData label={t("questionnaires.description")}>
                                                    <MultiLingualTextDisplay
                                                        texts={selectedQuestion.descriptions}
                                                        onTranslate={(target) => handleTranslate(target)}
                                                    />
                                                </LabeledData>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <LabeledData label={t("questionnaires.headers.type")}>
                                                {es("questionnaires.types", selectedQuestion.type)}
                                            </LabeledData>
                                        </Grid>
                                        {selectedQuestion.options && selectedQuestion.options.length > 0 && (
                                            <Grid item>
                                                <LabeledData label={t("questionnaires.headers.numberOfOptions")}>
                                                    {selectedQuestion.options.length}
                                                </LabeledData>
                                            </Grid>
                                        )}
                                        {selectedQuestion.scores && selectedQuestion.scores.length > 0 && (
                                            <Grid item>
                                                <LabeledData label={t("questionnaires.headers.numberOfScores")}>
                                                    {selectedQuestion.scores.length}
                                                </LabeledData>
                                            </Grid>
                                        )}
                                        {riskTemplates && riskTemplates.length > 0 && (
                                            <Grid item>
                                                <LabeledData label={t("questionnaires.question.riskTemplates.title")}>
                                                    {riskTemplates.length}
                                                </LabeledData>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </BoxedPaper>
                        </Grid>
                    </>
                )}
                {questionnaire && (
                    <Grid item xs={12}>
                        <BoxedPaper>
                            <MetaDataDisplay metaData={questionnaire?.metaData} />
                        </BoxedPaper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
