import React from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {IWebSearchResult} from "../../../domain/types";
import {maxLen} from "../../../libs/tools";

interface WebSearchResultListProps {
    webSearchResults: IWebSearchResult[];
    limit: number;
    onSelect: (result: IWebSearchResult) => void;
}

export function WebSearchResultList(props: WebSearchResultListProps) {
    const {webSearchResults, limit, onSelect} = props;
    const {t} = useLanguageContext();
    return (
        <List>
            {webSearchResults.slice(0, limit).map((r) => (
                <ListItemButton key={r.url} onClick={() => onSelect(r)}>
                    <ListItemAvatar>
                        <Avatar src={r.faviconUrl} />
                    </ListItemAvatar>
                    <ListItemText primary={r.title} secondary={<span dangerouslySetInnerHTML={{__html: maxLen(r.description, 200)}}></span>} />
                </ListItemButton>
            ))}
        </List>
    );
}