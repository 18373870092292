import {Button, Container, Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Activities} from "../../base/activities/Activities";
import {LabeledItem} from "../../base/data/LabeledItem";
import {MetaDataDisplay} from "../../base/data/MetaDataDisplay";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {ALink} from "../../base/layout/ALink";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {IAsset, IKiTool} from "../../domain/types";
import {gs} from "../../theme";
import {KiToolDetails} from "./view/KiToolDetails";

interface ToolViewProps {}

export function ToolView(props: ToolViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [kiTool, setKiTool] = useState<IKiTool>();
    const [asset, setAsset] = useState<IAsset>();

    useEffect(() => {
        fetchTool();
    }, [api, routeParams]);

    const fetchTool = useCallback(() => {
        if (routeParams.kiToolUuid && api.auth) {
            const kiToolsProvider = new KiToolsProvider(api);
            kiToolsProvider.get(tenantUuid, routeParams.kiToolUuid).then(setKiTool);
            kiToolsProvider.asset(tenantUuid, routeParams.kiToolUuid).then(setAsset);
        }

        return true;
    }, [api, routeParams]);

    const {t} = useLanguageContext();

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`}
                onDataChangeEvent={() => fetchTool()}
                onCreated={() => fetchTool()}
                onUpdated={() => fetchTool()}
                onDeleted={() => fetchTool()}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={kiTool ? kiTool.name : "..."}
                            crumbs={[
                                {
                                    label: t("tools.plural"),
                                    href: `/tenants/${tenantUuid}/tools`
                                },
                                {
                                    label: kiTool ? kiTool.name : "...",
                                    href: `/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`
                                }
                            ]}
                        >
                            {kiTool && kiTool.owner.uuid == tenantUuid && (
                                <Button variant="outlined" href={`/tenants/${tenantUuid}/tools/${kiTool.uuid}/edit`}>
                                    {t("tools.edit")}
                                </Button>
                            )}
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {kiTool && (
                        <>
                            <Grid item xs={12} sm={8}>
                                <BoxedPaper>
                                    <KiToolDetails kiTool={kiTool} onTranslate={() => fetchTool()} />
                                </BoxedPaper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <BoxedPaper>
                                    <Grid container spacing={gs}>
                                        {!asset && (
                                            <Grid item xs={12}>
                                                {t("tools.assets.none")}
                                            </Grid>
                                        )}
                                        {asset && (
                                            <LabeledItem label={t("tools.assets.associatedAsset")}>
                                                <ALink href={`/tenants/${tenantUuid}/assets/${asset.uuid}`}>
                                                    {asset.name}
                                                </ALink>
                                            </LabeledItem>
                                        )}
                                    </Grid>
                                </BoxedPaper>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Activities toolUuid={kiTool.uuid} />
                            </Grid>

                            <Grid item xs={12}>
                                <BoxedPaper>
                                    <MetaDataDisplay metaData={kiTool.metaData} />
                                </BoxedPaper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
