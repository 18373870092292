import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Hidden, IconButton, Toolbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../context/auth/AuthContext";
import {useLayoutContext} from "../context/layout/LayoutContext";
import {useTenantContext} from "../context/tenant/TenantContext";
import {GlobalSearchInput} from "./GlobalSearchInput";
import {LanguageSwitcherMenu} from "./LanguageSwitcherMenu";
import {NotificationsBubble} from "./NotificationsBubble";
import {UserMenu} from "./UserMenu";
import HiddenCss from "@mui/material/Hidden/HiddenCss";

interface TopNavProps {}

export function TopNav(props: TopNavProps) {
    const {title, drawerNavOpen, setDrawerNavOpen} = useLayoutContext();
    const {auth} = useAuthContext();
    const navigate = useNavigate();
    const {tenantUuid} = useTenantContext();

    const toggleNavDrawer = useCallback(() => {
        setDrawerNavOpen(!drawerNavOpen);
    }, [drawerNavOpen, setDrawerNavOpen]);

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    {auth.isLoggedIn && (
                        <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}} onClick={toggleNavDrawer}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <img
                        onClick={() => navigate(`/tenants/${tenantUuid}`)}
                        src="/artiligence-logo-white.png"
                        alt="Artiligence"
                        style={{height: "24px", marginRight: "16px", cursor: "pointer"}}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: "none", sm: "block"}}}
                    >
                        {title}
                    </Typography>

                    {auth.isLoggedIn && (
                        <>
                            <Box sx={{display: {xs: "block", sm: "none"}, flexGrow: 1}} />
                            <Box sx={{display: {xs: "none", sm: "block"}}}>
                                <GlobalSearchInput />
                            </Box>
                            <Box p={2} />
                            <LanguageSwitcherMenu />
                            <NotificationsBubble />
                            <UserMenu />
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
