import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    DataChangedEvent,
    DataChangeType,
    WebSocketConnection,
    WebSocketListener,
    WebSocketTicket
} from "../../libs/WebSocketConnection";
import {useAuthContext} from "../auth/AuthContext";
import {useTenantContext} from "../tenant/TenantContext";
import {WebSocketsProvider} from "../../domain/WebSocketsProvider";

interface DataChangeContextProps extends PropsWithChildren<{}> {}

export interface DataChangeContextData {
    addListener: (listener: WebSocketListener) => string;
    removeListener: (listenerUuid: string) => void;
}

const emptyContext: DataChangeContextData = {
    addListener: (listener: WebSocketListener) => "no-connection",
    removeListener: (listenerUuid: string) => {
        /* ignore */
    }
};

export const DataChangeContext = createContext<DataChangeContextData>(emptyContext);

export function DataChangeContextProvider(props: DataChangeContextProps) {
    const {children} = props;

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [webSocketTicket, setWebSocketTicket] = useState<WebSocketTicket>();
    const [webSocketConnection, setWebSocketConnection] = useState<WebSocketConnection>();

    useEffect(() => {
        if (api && api.auth && tenantUuid) {
            const webSocketsProvider = new WebSocketsProvider(api);
            webSocketsProvider.ticket(tenantUuid).then(setWebSocketTicket);
        }
    }, [api, tenantUuid]);

    useEffect(() => {
        if (api && api.auth && webSocketTicket) {
            const webSocketConnection = new WebSocketConnection(api, webSocketTicket.ticketUuid);
            setWebSocketConnection(webSocketConnection);
        }
        return () => {
            if (webSocketConnection) {
                webSocketConnection.close();
            }
        };
    }, [api, webSocketTicket]);

    const addListener = useCallback(
        (listener: WebSocketListener) => {
            if (webSocketConnection) {
                return webSocketConnection.addListener(listener);
            }
            return "no-connection";
        },
        [webSocketConnection]
    );

    const removeListener = useCallback(
        (listenerUuid: string) => {
            if (webSocketConnection) {
                webSocketConnection.removeListener(listenerUuid);
            }
        },
        [webSocketConnection]
    );

    const currentContext = useMemo<DataChangeContextData>(
        () => ({
            addListener,
            removeListener
        }),
        [addListener, removeListener]
    );

    return <DataChangeContext.Provider value={currentContext}>{children}</DataChangeContext.Provider>;
}

export function useDataChangeContext(): DataChangeContextData {
    return useContext(DataChangeContext);
}

interface DataChangeConsumerProps
    extends PropsWithChildren<{
        entityPath?: string;
        filter?: DataChangeType[];
        onDataChangeEvent?: (message: DataChangedEvent) => boolean;
        onCreated?: (message: DataChangedEvent) => void;
        onUpdated?: (message: DataChangedEvent) => void;
        onDeleted?: (message: DataChangedEvent) => void;
    }> {}

export function DataChangeConsumer(props: DataChangeConsumerProps) {
    const {children, entityPath, filter, onDataChangeEvent, onCreated, onUpdated, onDeleted} = props;
    const {addListener, removeListener} = useDataChangeContext();

    useEffect(() => {
        const webSocketListener: WebSocketListener = {
            onMessage: (message: DataChangedEvent) => {
                let retValue = true;
                if (onDataChangeEvent) {
                    if (!onDataChangeEvent(message)) {
                        retValue = false;
                    }
                }
                if (onCreated && message.changeType === DataChangeType.Created) {
                    onCreated(message);
                }
                if (onUpdated && message.changeType === DataChangeType.Updated) {
                    onUpdated(message);
                }
                if (onDeleted && message.changeType === DataChangeType.Deleted) {
                    onDeleted(message);
                }
                return retValue;
            },
            entityPath: entityPath,
            filter: filter
        };
        const id = addListener(webSocketListener);
        return () => {
            removeListener(id);
        };
    }, [addListener, removeListener, onDataChangeEvent, onCreated, onUpdated, onDeleted, entityPath, filter]);

    return <>{children}</>;
}
