import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {RoleWrapper} from "../../domain/permissions";
import {ITenant} from "../../domain/types";
import {useAuthContext} from "../auth/AuthContext";
import {useLanguageContext} from "../language/LanguageContext";

export interface TenantContextData {
    tenantUuid: string;
    tenant?: ITenant;
    role?: RoleWrapper;
}

const emptyContext: TenantContextData = {
    tenantUuid: ""
};

export const TenantContext = createContext<TenantContextData>(emptyContext);

interface TenantContextProviderProps
    extends PropsWithChildren<{
        tenantUuid: string;
    }> {}

export function TenantContextProvider(props: TenantContextProviderProps) {
    const {children} = props;
    const {api} = useAuthContext();
    const [tenantUuid, setTenantUuid] = useState<string>(props.tenantUuid);
    const [tenant, setTenant] = useState<ITenant>();
    const [role, setRole] = useState<RoleWrapper>();

    const {language} = useLanguageContext();

    useEffect(() => {
        if (api.auth && tenantUuid) {
            console.log("TenantContextProvider useEffect(api, tenantUuid)", tenantUuid);
            setTenantUuid(tenantUuid);
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(tenantUuid).then(setTenant);
            currentUserProvider.role(tenantUuid).then((role) => {
                setRole(new RoleWrapper(role));
            });
        }
    }, [api, tenantUuid]);

    useEffect(() => {
        if (tenant && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider
                .updateCurentUserLocale(tenant.uuid, {
                    locale: language
                })
                .then(() => {
                    console.log("changed");
                });
        }
    }, [api, tenant, language]);

    const currentContext = useMemo<TenantContextData>(() => {
        console.log("TenantContextProvider useMemo(tenantUuid, tenant, role)", tenantUuid, tenant, role);
        return {
            tenantUuid,
            tenant,
            role
        };
    }, [tenantUuid, tenant, role]);

    console.log("TenantContextProvider render", currentContext);

    return <TenantContext.Provider value={currentContext}>{children}</TenantContext.Provider>;
}

export function useTenantContext(): TenantContextData {
    return useContext<TenantContextData>(TenantContext);
}
