import React, {useCallback, useEffect, useState} from "react";
import {Question, QuestionScore, QuestionScoreRiskTemplate} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ScoreForm} from "./ScoreForm";
import {ConfirmDialog} from "../../../base/dialogs/ConfirmDialog";

interface ScoreEditDialogProps {
    open: boolean;
    question: Question;
    score: QuestionScore;
    templates: QuestionScoreRiskTemplate[];
    onClose: (score?: QuestionScore, templates?: QuestionScoreRiskTemplate[]) => void;
    onDelete: () => void;
}

export function ScoreEditDialog(props: ScoreEditDialogProps) {
    const {open, question, score, templates, onClose, onDelete} = props;
    const {t} = useLanguageContext();

    const [editingScore, setEditingScore] = useState<QuestionScore>(score);
    const [editingTemplates, setEditingTemplates] = useState<QuestionScoreRiskTemplate[]>(templates);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    useEffect(() => {
        setEditingScore(score);
        setEditingTemplates(templates);
        setConfirmDelete(false);
    }, [score, templates]);

    const handleApply = useCallback(() => {
        onClose(editingScore, editingTemplates);
    }, [editingScore, editingTemplates, onClose]);

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
            <DialogTitle>{t("questionnaires.scores.edit")}</DialogTitle>
            <DialogContent>
                <ScoreForm
                    question={question}
                    score={editingScore}
                    onApplyScore={setEditingScore}
                    templates={editingTemplates}
                    onApplyTemplates={setEditingTemplates}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button variant="outlined" color="warning" onClick={() => setConfirmDelete(true)}>{t("delete")}</Button>
                <Button variant="contained" color="primary" onClick={() => handleApply()}>{t("apply")}</Button>
            </DialogActions>
            <ConfirmDialog
                open={confirmDelete}
                title={t("questionnaires.question.scores.delete.title")}
                message={t("questionnaires.question.scores.delete.message")}
                onConfirm={() => onDelete()}
                onCancel={() => setConfirmDelete(false)}
            />
        </Dialog>
    );
}