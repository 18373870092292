import {Button, Container, Grid, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Comments} from "../../../base/Comments";
import {MetaDataDisplay} from "../../../base/data/MetaDataDisplay";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {CommentsProvider} from "../../../domain/CommentsProvider";
import {CurrentUserProvider} from "../../../domain/CurrentUserProvider";
import {Comment, CommentSendRequest} from "../../../domain/comments";
import {IAsset, IAssetRisk, UserInfo} from "../../../domain/types";
import {gs} from "../../../theme";
import {AssetRiskQuestion} from "./AssetRiskQuestion";
import {RiskView} from "./RiskView";

interface AssetViewRiskProps {}

export function AssetViewRisk(props: AssetViewRiskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [assetRisk, setAssetRisk] = useState<IAssetRisk>();

    const [comments, setComments] = useState<Comment[]>([]);

    const [us, setUs] = useState("");

    useEffect(() => {
        if (routeParams.assetUuid && routeParams.riskUuid && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.profile().then((user: UserInfo) => {
                setUs(user.username);
            });

            fetchAssetRisk();
        }
    }, [api, tenantUuid, routeParams]);

    const fetchAssetRisk = useCallback(() => {
        if (routeParams.assetUuid && routeParams.riskUuid && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            assetsProvider.risk(tenantUuid, routeParams.assetUuid, routeParams.riskUuid).then(setAssetRisk);
        }
    }, [api, tenantUuid, routeParams]);

    useEffect(() => {
        fetchComments();
    }, [asset && assetRisk]);

    const fetchComments = useCallback(() => {
        if (api.auth && routeParams.assetUuid && routeParams.riskUuid) {
            const commentsProvider = new CommentsProvider(api);
            commentsProvider.listForAssetRisk(tenantUuid, routeParams.assetUuid, routeParams.riskUuid).then((res) => {
                setComments(res);
            });
        }
    }, [api, routeParams.assetUuid, routeParams.riskUuid]);

    const handleSendMessage = (r: CommentSendRequest): Promise<Comment> => {
        const request: CommentSendRequest = {
            comment: r.comment,
            locale: r.locale,
            assetRiskUuid: routeParams.riskUuid
        };
        const commentsProvider = new CommentsProvider(api);
        return commentsProvider.create(tenantUuid, request).then((res) => {
            fetchComments();
            return res;
        });
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={assetRisk ? assetRisk.name : "..."}
                        crumbs={[
                            {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                            {
                                label: asset ? asset.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}`
                            },
                            {
                                label: assetRisk ? assetRisk.name : "...",
                                href: `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}`
                            }
                        ]}
                    >
                        <Button
                            variant="outlined"
                            onClick={() =>
                                navigate(
                                    `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}/edit`
                                )
                            }
                        >
                            {t("edit")}
                        </Button>
                    </AHeaderWithBreadcrumbs>
                </Grid>
                {assetRisk && (
                    <>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <RiskView assetRisk={assetRisk} onTranslate={() => fetchAssetRisk()} />
                            </BoxedPaper>
                        </Grid>

                        <DataChangeConsumer
                            entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/risks/${routeParams.riskUuid}/comments`}
                            onCreated={() => fetchComments()}
                        >
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">{t("comments.plural")}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Comments
                                            messages={comments}
                                            performSend={(m) => handleSendMessage(m)}
                                            us={us}
                                            translationEnabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DataChangeConsumer>

                        {assetRisk.questionnaire && assetRisk.answer && (
                            <Grid item xs={12} lg={6}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            {t("assets.assetRisk.questionnaire.title")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BoxedPaper>
                                            <AssetRiskQuestion
                                                questionnaire={assetRisk.questionnaire}
                                                answer={assetRisk.answer}
                                            />
                                        </BoxedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <BoxedPaper>
                                <MetaDataDisplay metaData={assetRisk.metaData} />
                            </BoxedPaper>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
}
