import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {CatalogProvider} from "../../../domain/CatalogProvider";
import {IWebSearchResult} from "../../../domain/types";
import {gs} from "../../../theme";
import {Grid} from "@mui/material";
import {ALoading} from "../../../base/loading/ALoading";
import {WebSearchResultList} from "./WebSearchResultList";

interface WebSearchStepProps {
    needle: string;
    onSelect: (result: IWebSearchResult) => void;
}

export function WebSearchStep(props: WebSearchStepProps) {
    const {needle, onSelect} = props;
    const {t} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [webSearchResults, setWebSearchResults] = useState<IWebSearchResult[]>();

    useEffect(() => {
        if (needle && api && api.auth) {
            const catalogProvider = new CatalogProvider(api);
            catalogProvider.webSearch(tenantUuid, needle).then(setWebSearchResults);
        }
    }, [needle, api, tenantUuid]);

    return (
        <Grid container spacing={gs}>
            {!webSearchResults &&
                <Grid item xs={12}>
                    <ALoading />
                </Grid>
            }
            {webSearchResults && (
                <Grid item xs={12}>
                    <WebSearchResultList webSearchResults={webSearchResults} limit={10} onSelect={onSelect} />
                </Grid>
            )}
        </Grid>
    );
}