import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Backdrop} from "@mui/material";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {IDocumentResponse} from "../../domain/types";
import {DocumentsProvider} from "../../domain/DocumentsProvider";

interface ScreenshotDisplayProps {
    name: string;
    screenshotUrl: string;
}

export function ScreenshotDisplay(props: ScreenshotDisplayProps) {
    const {name, screenshotUrl} = props;
    const {t} = useLanguageContext();

    const [fullScreen, setFullScreen] = useState(false);

    return (
        <>
            <img
                alt={t("screenshot.info", {toolName: name})}
                onClick={() => setFullScreen(true)}
                src={screenshotUrl} style={{
                    width: "100%",
                    height: "auto",
                    border: "1px solid #e0e0e0",
                    borderRadius: 4,
                    marginTop: 16,
                    cursor: "pointer"
                }}
            />
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={fullScreen}
                onClick={() => setFullScreen(false)}
            >
                <img
                    alt={t("screenshot.info", {toolName: name})}
                    src={screenshotUrl}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        borderRadius: 4,
                }}
                />
            </Backdrop>
        </>
    );
}

export interface DocumentScreenshotDisplayProps {
    name: string;
    documentUuid?: string;
}

export function DocumentScreenshotDisplay(props: DocumentScreenshotDisplayProps) {
    const {name, documentUuid} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [documentResponse, setDocumentResponse] = useState<IDocumentResponse>();

    useEffect(() => {
        if (api && api.auth && tenantUuid && documentUuid) {
            const documentsProvider = new DocumentsProvider(api);
            documentsProvider.prepareDownloadUrl(tenantUuid, documentUuid).then(setDocumentResponse);
        }
    }, [api, tenantUuid, documentUuid]);

    if (!documentResponse) {
        return null;
    }

    return (
        <ScreenshotDisplay name={name} screenshotUrl={documentResponse.url.url} />
    );
}