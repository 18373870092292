import {Chip, Grid} from "@mui/material";
import React, {useCallback} from "react";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {ALink} from "../../../base/layout/ALink";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {AiActRiskCategory, IAsset} from "../../../domain/types";
import {calculateContrastRatio, stateColor} from "../../../libs/tools";
import {gs} from "../../../theme";
import {AiActRiskCategoryDisplay} from "../../../base/displays/AiActRiskCategoryDisplay";

interface AssetDetailsProps {
    asset: IAsset;
    aiActRiskCategory?: AiActRiskCategory;
    onTranslate: () => void;
}

export function AssetDetails(props: AssetDetailsProps) {
    const {asset, aiActRiskCategory, onTranslate} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t, es} = useLanguageContext();

    const handleTranslate = useCallback(
        async (target: string) => {
            if (api.auth && asset.uuid) {
                const assetProvider = new AssetsProvider(api);
                await assetProvider
                    .translate(tenantUuid, asset.uuid, {
                        targetLanguage: target
                    })
                    .then(() => {
                        onTranslate();
                    });
            }
        },
        [api, asset]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={gs}>
                    <LabeledItem label={t("assets.headerNames.state")}>
                        <Chip
                            label={es("assets.states", asset.state)}
                            sx={{
                                background: stateColor(asset.state),
                                color: calculateContrastRatio(stateColor(asset.state))
                            }}
                        />
                    </LabeledItem>
                    {aiActRiskCategory &&
                        <LabeledItem label={t("questionnaires.question.aiActRiskCategory")}>
                            <AiActRiskCategoryDisplay riskCategory={aiActRiskCategory} />
                        </LabeledItem>
                    }
                    <LabeledItem label={t("assets.headerNames.type")}>{es("tools.toolType", asset.type)}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.category")}>
                        {es("tools.toolCategory", asset.category)}
                    </LabeledItem>
                    <LabeledItem label={t("assets.headerNames.visibility")}>
                        {es("tools.toolVisibility", asset.visibility)}
                    </LabeledItem>
                    <LabeledItem label={t("assets.headerNames.vendor")}>{asset.vendor}</LabeledItem>
                    <LabeledItem label={t("assets.headerNames.basedOn")}>
                        <ALink href={`/tenants/${tenantUuid}/tools/${asset.basedOn.uuid}`}>{asset.basedOn.name}</ALink>
                    </LabeledItem>
                </Grid>
            </Grid>
            <LabeledItem label={t("assets.headerNames.description")}>
                <MultiLingualTextDisplay texts={asset.descriptions} onTranslate={(lang) => handleTranslate(lang)} />
            </LabeledItem>
        </Grid>
    );
}
