import {ExpandMore, Translate} from "@mui/icons-material";
import {Button, Hidden, Menu, MenuItem} from "@mui/material";
import React, {MouseEvent, useEffect, useMemo, useState} from "react";
import {LanguageSupport, useLanguageContext} from "../../context/language/LanguageContext";

interface LanguageSwitcherMenuProps {
    onChange: (language: string, full: string) => void;
    languages?: string[];
    customLanguageSelected?: string;
}

export function LanguageSwitcher(props: LanguageSwitcherMenuProps) {
    const {onChange, languages, customLanguageSelected} = props;
    const languageContext = useLanguageContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [selectedLanguage, setSelectedLanguage] = useState("");

    useEffect(() => {
        let language = "Deutsch";
        if (!customLanguageSelected) {
            switch (languageContext.language) {
                case "en":
                    language = "English";
                    break;
                case "de":
                default:
                    language = "Deutsch";
                    break;
            }
            setSelectedLanguage(language);
        } else {
            console.log(customLanguageSelected);
            setSelectedLanguage(languageContext.es("languages", customLanguageSelected));
            handleSelectLanguage(customLanguageSelected, languageContext.es("languages", customLanguageSelected));
        }
    }, []);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectLanguage = (language: string, full: string) => {
        setSelectedLanguage(full);
        onChange(language, full);
        handleClose();
    };

    const languageSupport = useMemo<LanguageSupport>(() => {
        const languageSupport = new LanguageSupport();
        return languageSupport;
    }, []);

    const defaultLanguages = ["de", "en"];

    const open: boolean = anchorEl != null;
    return (
        <>
            <Button
                variant="outlined"
                color="inherit"
                startIcon={<Translate />}
                endIcon={<ExpandMore fontSize="small" />}
                onClick={handleClick}
            >
                <Hidden xsDown>{selectedLanguage}</Hidden>
                <Hidden smUp>{selectedLanguage.toLocaleUpperCase()}</Hidden>
            </Button>
            <Menu
                id="i18n-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
            >
                {!languages &&
                    defaultLanguages.map(
                        (language, index) =>
                            languageSupport.supports(language) && (
                                <MenuItem
                                    key={index}
                                    onClick={() =>
                                        handleSelectLanguage(language, languageContext.es("languages", language))
                                    }
                                >
                                    {languageContext.es("languages", language)}
                                </MenuItem>
                            )
                    )}

                {languages &&
                    languages.map(
                        (language, index) =>
                            languageSupport.supports(language) && (
                                <MenuItem
                                    key={index}
                                    onClick={() =>
                                        handleSelectLanguage(language, languageContext.es("languages", language))
                                    }
                                >
                                    {languageContext.es("languages", language)}
                                </MenuItem>
                            )
                    )}
            </Menu>
        </>
    );
}
