import {Button, Container, Grid, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Comments} from "../../../base/Comments";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {CommentsProvider} from "../../../domain/CommentsProvider";
import {CurrentUserProvider} from "../../../domain/CurrentUserProvider";
import {TasksProvider} from "../../../domain/TasksProvider";
import {Comment, CommentSendRequest} from "../../../domain/comments";
import {TaskWrapper} from "../../../domain/tasks";
import {IAsset, ITask, TaskState, UserInfo} from "../../../domain/types";
import {gs} from "../../../theme";
import {TaskView} from "../../tasks/TaskView";

interface AssetViewTaskProps {}

export function AssetViewTask(props: AssetViewTaskProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t, language} = useLanguageContext();

    const [asset, setAsset] = useState<IAsset>();
    const [task, setTask] = useState<ITask>();

    const [comments, setComments] = useState<Comment[]>([]);

    const [us, setUs] = useState("");

    useEffect(() => {
        if (routeParams.assetUuid && api.auth) {
            fetchTask();
        }
    }, [api, tenantUuid, routeParams]);

    const fetchTask = useCallback(() => {
        if (routeParams.assetUuid && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.profile().then((user: UserInfo) => {
                setUs(user.username);
            });
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.get(tenantUuid, routeParams.assetUuid).then(setAsset);
            const tasksProvider = new TasksProvider(api);
            tasksProvider.get(tenantUuid, routeParams.taskUuid).then(setTask);
        }
        return true;
    }, [api, tenantUuid, routeParams]);

    useEffect(() => {
        fetchComments();
    }, [task]);

    const taskWrapper = useMemo<TaskWrapper | undefined>(() => (task ? new TaskWrapper(task) : undefined), [task]);

    const handleStateChange = useCallback(
        (nextState: TaskState) => {
            if (routeParams.assetUuid && task && api.auth) {
                const tasksProvider = new TasksProvider(api);
                return tasksProvider.updateState(tenantUuid, task.uuid, nextState).then((updatedTask) => {
                    setTask(updatedTask);
                });
            }
            throw new Error(`React component in an illegal state to handleStateChange`);
        },
        [task]
    );

    const fetchComments = useCallback(() => {
        if (api.auth && task && routeParams.assetUuid) {
            const commentsProvider = new CommentsProvider(api);
            commentsProvider.listForTasks(tenantUuid, routeParams.assetUuid, task.uuid).then((res) => {
                setComments(res);
            });
        }
        return true;
    }, [api, task, routeParams.assetUuid]);

    const handleSendMessage = (r: CommentSendRequest, taskUuid: string): Promise<Comment> => {
        const request: CommentSendRequest = {
            comment: r.comment,
            locale: r.locale,
            taskUuid: taskUuid
        };
        const commentsProvider = new CommentsProvider(api);
        return commentsProvider.create(tenantUuid, request).then((res) => {
            fetchComments();
            return res;
        });
    };

    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks/${routeParams.taskUuid}`}
                onDataChangeEvent={() => fetchTask()}
                onUpdated={() => fetchTask()}
                onCreated={() => fetchTask()}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={taskWrapper ? taskWrapper.title(language) : "..."}
                            crumbs={[
                                {label: t("assets.plural"), href: `/tenants/${tenantUuid}/assets`},
                                {
                                    label: asset ? asset.name : "...",
                                    href: asset ? `/tenants/${tenantUuid}/assets/${asset.uuid}` : "/assets"
                                },
                                {
                                    label: taskWrapper ? taskWrapper.title(language) : "...",
                                    href:
                                        asset && task
                                            ? `/tenants/${tenantUuid}/assets/${asset.uuid}/tasks/${task.uuid}`
                                            : "/assets"
                                }
                            ]}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    navigate(
                                        `/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/tasks/${routeParams.taskUuid}/edit`
                                    )
                                }
                            >
                                {t("edit")}
                            </Button>
                        </AHeaderWithBreadcrumbs>
                    </Grid>
                    {task && (
                        <>
                            <Grid item xs={12}>
                                <BoxedPaper>
                                    <TaskView
                                        task={task}
                                        onStateChange={handleStateChange}
                                        onTranslate={() => fetchTask()}
                                    />
                                </BoxedPaper>
                            </Grid>
                        </>
                    )}

                    <DataChangeConsumer
                        entityPath={`/tenants/${tenantUuid}/assets/${routeParams.assetUuid}/comments`}
                        onDataChangeEvent={() => fetchComments()}
                        onCreated={() => fetchComments()}
                    >
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <Typography variant="h2">{t("comments.plural")}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Comments
                                        messages={comments}
                                        performSend={(m) => handleSendMessage(m, task!.uuid)}
                                        us={us}
                                        translationEnabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DataChangeConsumer>
                </Grid>
            </DataChangeConsumer>
        </Container>
    );
}
