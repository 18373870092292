import {Avatar, Grid, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {UsersProvider} from "../../domain/UsersProvider";
import {UserInfoWrapper} from "../../domain/users";
import {initials} from "../../libs/tools";
import {IDocumentResponse, UserInfo} from "../../domain/types";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {UserProfile} from "../../pages/current-user/UserProfile";
import {gs} from "../../theme";
import {LabeledItem} from "../data/LabeledItem";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface UserAvatarProps {
    username: string;
    onClick?: () => void;
    noCache?: boolean;
    tooltip?: boolean;
}

export function UserAvatar(props: UserAvatarProps) {
    const {username, onClick, noCache, tooltip} = props;

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [profilePicture, setProfilePicture] = useState<IDocumentResponse>();

    useEffect(() => {
        if (api.auth && tenantUuid && username) {
            const usersProvider = new UsersProvider(api);
            usersProvider
                .getUserInfo(username)
                .then(setUserInfo);
            usersProvider
                .getUserProfilePicture(tenantUuid, username, noCache)
                .then(setProfilePicture)
                .catch(() => { /* Ignoring 404 errors */ });

        }
    }, [tenantUuid, username, noCache, api]);

    return (
        <UserAvatarDisplay
            userInfo={userInfo}
            profilePicture={profilePicture ? profilePicture.url.url : undefined}
            onClick={onClick}
            tooltip={tooltip}
        />
    );
}

interface UserAvatarDisplayProps {
    userInfo?: UserInfo;
    profilePicture?: string;
    onClick?: () => void;
    tooltip?: boolean;
}

export function UserAvatarDisplay(props: UserAvatarDisplayProps) {
    const {userInfo, profilePicture, onClick, tooltip} = props;

    const wrapper = useMemo<UserInfoWrapper | undefined>(() => {
        if (userInfo) {
            return new UserInfoWrapper(userInfo);
        }
        return undefined;
    }, [userInfo]);

    if (tooltip) {
        return (
            <Tooltip title={<UserTooltipContent userInfo={userInfo} />}>
                <Avatar onClick={onClick}
                        alt={wrapper ? wrapper.displayName : ""}
                        sx={{cursor: onClick ? "pointer" : "default"}}
                        src={profilePicture}
                >
                    {wrapper ? wrapper.initials : ".."}
                </Avatar>
            </Tooltip>
        );
    }
    return (
        <Avatar onClick={onClick}
                alt={wrapper ? wrapper.displayName : ""}
                sx={{cursor: onClick ? "pointer" : "default"}}
                src={profilePicture}
        >
            {wrapper ? wrapper.initials : ".."}
        </Avatar>
    );
}

interface UserTooltipContentProps {
    userInfo?: UserInfo;
    profilePicture?: string;
}

export function UserTooltipContent(props: UserTooltipContentProps) {
    const {userInfo, profilePicture} = props;
    const {t} = useLanguageContext();

    const wrapper = useMemo<UserInfoWrapper | undefined>(() => {
        if (userInfo) {
            return new UserInfoWrapper(userInfo);
        }
        return undefined;
    }, [userInfo]);

    return (
        <Grid container spacing={1}>
            <LabeledItem label={t("userProfile.name")}>
                <Typography variant="body1">{wrapper ? wrapper.displayName : "..."}</Typography>
            </LabeledItem>
            {userInfo && userInfo.email && (
                <LabeledItem label={t("userProfile.email")}>
                    <Typography variant="body1">{userInfo.email}</Typography>
                </LabeledItem>
            )}
        </Grid>
    );
}