import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface ConfirmDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const {message, onConfirm, onCancel, open, title} = props;
    const {t} = useLanguageContext();
    return (
        <Dialog open={open} onClose={() => onConfirm()} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onCancel()}>
                    {t("cancel")}
                </Button>
                <Button variant="contained" color="primary" onClick={() => onConfirm()}>
                    {t("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
