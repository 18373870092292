import {Api} from "../libs/Api";
import {Comment, CommentSendRequest, UiTranslationRequest} from "./comments";

export class CommentsProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async create(tenantUuid: string, request: CommentSendRequest): Promise<Comment> {
        return this.api.post<Comment>(`/api/v1/tenants/${tenantUuid}/comments`, request);
    }

    async listForAsset(tenantUuid: string, assetUuid: string): Promise<Comment[]> {
        return this.api.get<Comment[]>(`/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/comments`);
    }

    async listForAssetRisk(tenantUuid: string, assetUuid: string, assetRiskUuid: string): Promise<Comment[]> {
        return this.api.get<Comment[]>(
            `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/risks/${assetRiskUuid}/comments`
        );
    }

    async listForMeasure(tenantUuid: string, assetUuid: string, measureUuid: string): Promise<Comment[]> {
        return this.api.get<Comment[]>(
            `/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/measures/${measureUuid}/comments`
        );
    }

    async listForTasks(tenantUuid: string, assetUuid: string, taskUuid: string): Promise<Comment[]> {
        return this.api.get<Comment[]>(`/api/v1/tenants/${tenantUuid}/assets/${assetUuid}/tasks/${taskUuid}/comments`);
    }

    async translate(tenantUuid: string, commentUuid: string, request: UiTranslationRequest): Promise<Comment> {
        return this.api.post<Comment>(`/api/v1/tenants/${tenantUuid}/comments/${commentUuid}/translate`, request);
    }
}
