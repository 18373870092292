import {Grid} from "@mui/material";
import React, {useCallback} from "react";
import {LabeledData} from "../../../base/data/LabeledData";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {MeasureStateDisplay} from "../../../base/displays/MeasureStateDisplay";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {PriorityDisplay} from "../../../base/displays/PriorityDisplay";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {MeasuresProvider} from "../../../domain/MeasuresProvider";
import {IMeasure} from "../../../domain/types";
import {gs} from "../../../theme";

interface MeasureViewProps {
    measure: IMeasure;
    onTranslate: () => void;
}

export function MeasureView(props: MeasureViewProps) {
    const {measure, onTranslate} = props;
    const {t} = useLanguageContext();

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const handleTranslate = useCallback(
        async (target: string) => {
            if (api.auth && measure) {
                const measuresProvider = new MeasuresProvider(api);
                await measuresProvider
                    .translate(tenantUuid, measure.asset.uuid, measure.uuid, {
                        targetLanguage: target
                    })
                    .then(() => {
                        onTranslate();
                    });
            }
        },
        [api, measure]
    );

    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("measures.formLabels.Title")}>
                <MultiLingualTextDisplay texts={measure.titles} onTranslate={(target) => handleTranslate(target)} />
            </LabeledItem>
            <LabeledItem label={t("measures.formLabels.State")}>
                <MeasureStateDisplay state={measure.state} />
            </LabeledItem>
            <AGridItemGrow>
                <LabeledData label={t("measures.formLabels.Priority")}>
                    <PriorityDisplay priority={measure.priority} />
                </LabeledData>
            </AGridItemGrow>
            {measure.risk && (
                <>
                    <LabeledItem label={t("measures.formLabels.Probability")}>
                        <ProbabilityDisplay probability={measure.risk.probability} />
                    </LabeledItem>
                    <LabeledItem label={t("measures.formLabels.Severity")}>
                        <SeverityDisplay severity={measure.risk.severity} />
                    </LabeledItem>
                </>
            )}

            <Grid item xs={12}>
                <LabeledData label={t("measures.formLabels.Description")}>
                    <MultiLingualTextDisplay
                        texts={measure.descriptions}
                        onTranslate={(target) => handleTranslate(target)}
                    />
                </LabeledData>
            </Grid>
        </Grid>
    );
}
