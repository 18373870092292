import {Button, Container, Grid} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ConfirmDeleteButton} from "../../base/buttons/ConfirmDeleteButton";
import {ConfirmDialog} from "../../base/dialogs/ConfirmDialog";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DataChangeConsumer} from "../../context/data-changes/DataChangeContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {KiToolsProvider} from "../../domain/KiToolsProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {ToolModification, UserInfo} from "../../domain/types";
import {gs} from "../../theme";
import {ToolForm} from "./edit/ToolForm";

interface ToolEditProps {}

export function ToolEdit(props: ToolEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [tool, setTool] = useState<ToolModification>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [dataChanged, setDataChanged] = useState<"" | "update" | "delete">("");
    const [user, setUser] = useState<UserInfo>();

    useEffect(() => {
        fetchTool();
    }, [api, routeParams]);

    const fetchTool = useCallback(() => {
        if (routeParams.kiToolUuid && api.auth) {
            const kiToolsProvider = new KiToolsProvider(api);
            kiToolsProvider.get(tenantUuid, routeParams.kiToolUuid).then(setTool);
        }
        return true;
    }, [api, routeParams]);

    const fetchUser = useCallback((username: string) => {
        const usersProvider = new UsersProvider(api);
        usersProvider.getUserInfo(username).then(setUser);
    }, []);

    const canSave = useMemo<boolean>(
        () =>
            Boolean(
                tool &&
                    tool.name &&
                    tool.type &&
                    tool.category &&
                    tool.visibility &&
                    tool.descriptions.length > 0 &&
                    tool.vendor
            ),
        [tool]
    );

    const handleSaveTool = useCallback(() => {
        if (tool && canSave && routeParams.kiToolUuid && api.auth) {
            setSubmitting(true);
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider
                .modify(tenantUuid, routeParams.kiToolUuid, tool)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, canSave, tool, api, tenantUuid, navigate]);

    const handleDeleteTool = useCallback(() => {
        if (tool && routeParams.kiToolUuid && api.auth) {
            setSubmitting(true);
            const toolsProvider = new KiToolsProvider(api);
            toolsProvider
                .delete(tenantUuid, routeParams.kiToolUuid)
                .then(() => {
                    setSubmitting(false);
                    navigate(`/tenants/${tenantUuid}/tools`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    }, [routeParams, tool, api, tenantUuid, navigate]);

    const handleConfirmUpdateChange = useCallback(() => {
        fetchTool();
        setUser(undefined);
        setDataChanged("");
    }, []);

    const handleConfirmDeleteChange = useCallback((tenantUuid: string) => {
        setUser(undefined);
        navigate(`/tenants/${tenantUuid}/tools`);
    }, []);

    const {t} = useLanguageContext();
    return (
        <Container maxWidth="lg">
            <DataChangeConsumer
                entityPath={`/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`}
                onDataChangeEvent={() => fetchTool()}
                onUpdated={(message) => {
                    fetchUser(message.username);
                    setDataChanged("update");
                }}
                onDeleted={(message) => {
                    fetchUser(message.username);
                    setDataChanged("delete");
                }}
            >
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs
                            title={tool ? tool.name : "..."}
                            crumbs={[
                                {
                                    label: t("tools.plural"),
                                    href: `/tenants/${tenantUuid}/tools`
                                },
                                {
                                    label: tool ? tool.name : "...",
                                    href: `/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}`
                                },
                                {
                                    label: t("tools.edit"),
                                    href: `/tenants/${tenantUuid}/tools/${routeParams.kiToolUuid}/edit`
                                }
                            ]}
                        ></AHeaderWithBreadcrumbs>
                    </Grid>
                    {tool && (
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <ToolForm tool={tool} onChange={setTool} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={gs}>
                                                <Grid item>
                                                    <Button
                                                        onClick={handleSaveTool}
                                                        color="primary"
                                                        variant="contained"
                                                        disabled={!canSave || submitting}
                                                    >
                                                        {t("tools.save")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <ConfirmDeleteButton
                                                        onConfirm={handleDeleteTool}
                                                        title={t("tools.deleteTitle")}
                                                        message={`${t("tools.deleteMessage")} ${tool.name}?`}
                                                    >
                                                        {t("tools.delete")}
                                                    </ConfirmDeleteButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                    )}
                </Grid>

                <ConfirmDialog
                    open={Boolean(dataChanged == "update" && user)}
                    title={t("updated")}
                    message={t("tools.changeInfo.updated", {
                        username: user ? user.fullName : ""
                    })}
                    onConfirm={() => handleConfirmUpdateChange()}
                    onCancel={() => console.log("cancel")}
                />

                <ConfirmDialog
                    open={Boolean(dataChanged == "delete" && user)}
                    title={t("deleted")}
                    message={t("tools.changeInfo.deleted", {
                        username: user ? user.fullName : ""
                    })}
                    onConfirm={() => handleConfirmDeleteChange(tenantUuid)}
                    onCancel={() => console.log("cancel")}
                />
            </DataChangeConsumer>
        </Container>
    );
}
