import { Link } from "@mui/material";
import * as React from "react";
import {PropsWithChildren, useCallback} from "react";
import {useNavigate} from "react-router-dom";

interface ALinkProps extends PropsWithChildren<{
    href?: string;
    onClick?: () => void;
}> { }

export function ALink(props: ALinkProps) {
    const {children, href, onClick} = props;
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
        if (href) {
            if (href.startsWith("http")) {
                window.open(href, "href");
            } else {
                navigate(href);
            }
        }
    }, [href, onClick, navigate]);

    return (
        <Link onClick={handleClick} sx={{cursor: "pointer"}}>{children}</Link>
    );
}
