import {Api} from "../libs/Api";
import {Notification, NotificationCreationRequest} from "./notifications";
import {Page} from "./types";

export class NotificationsProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string): Promise<Page<Notification>> {
        return this.api.get<Page<Notification>>(`/api/v1/tenants/${tenantUuid}/notifications`);
    }

    async get(tenantUuid: string, notificationUuid: string): Promise<Notification> {
        return this.api.get<Notification>(`/api/v1/tenants/${tenantUuid}/notifications/${notificationUuid}`);
    }

    async markRead(tenantUuid: string, request: NotificationCreationRequest): Promise<Page<Notification>> {
        return this.api.post<Page<Notification>>(`/api/v1/tenants/${tenantUuid}/notifications/mark-read`, request);
    }
}
