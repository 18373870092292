import React from "react";

interface BlockSvgProps {
    src: string;
    large?: boolean;
    maxWidth?: string;
    maxHeight?: string;
}

export function BlockSvg({src, large, maxWidth, maxHeight}: BlockSvgProps) {
    return (
        <img
            src={src}
            style={
                large
                    ? {
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxWidth: maxWidth ? maxWidth : "400px",
                          maxHeight: maxHeight ? maxHeight : "400px",
                          width: "40%",
                          height: "40%"
                      }
                    : {
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          maxWidth: maxWidth ? maxWidth : "400px",
                          maxHeight: maxHeight ? maxHeight : "400px"
                      }
            }
        />
    );
}
