import {Api} from "../libs/Api";
import {IDocumentResponse} from "./types";

export class DocumentsProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async prepareUploadUrl(tenantUuid: string, file: File): Promise<IDocumentResponse> {
        return this.api.post<IDocumentResponse>(`/api/v1/tenants/${tenantUuid}/documents`, {
            name: file.name,
            mimeType: file.type,
            size: file.size
        });
    }

    async prepareDownloadUrl(tenantUuid: string, documentUuid: string): Promise<IDocumentResponse> {
        return this.api.get<IDocumentResponse>(`/api/v1/tenants/${tenantUuid}/documents/${documentUuid}`);
    }

}