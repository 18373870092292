import React, {useCallback, useMemo} from "react";
import {BooleanInput} from "../../base/inputs/BooleanInput";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {AnswerType, MultilingualText, MultilingualTextWrapper, Question} from "../../domain/types";
import {MultipleChoiceMultipleSelectInput} from "./MultipleChoiceMultipleSelectInput";
import {MultipleChoiceSingleAnswerInput} from "./MultipleChoiceSingleAnswerInput";

interface AnswerInputProps {
    question: Question;
    value: string;
    onChange: (value: string) => void;
}

export function AnswerInput(props: AnswerInputProps) {
    const {question, value, onChange} = props;

    const {t, language} = useLanguageContext();

    const handleBooleanChanged = useCallback(
        (value: boolean) => {
            onChange(value ? "Yes" : "No");
        }, [onChange]
    );

    const title = useMemo<string>(() => new MultilingualTextWrapper(question.titles).resolved(language), [language, question]);

    switch (question.type) {
        case AnswerType.MultipleChoiceSingleAnswer:
            return <MultipleChoiceSingleAnswerInput label={title} placeholder={t("inputPlaceholders.singleOption")} question={question} value={value} onChange={onChange} />;
        case AnswerType.MultipleChoiceMultipleAnswers:
            return <MultipleChoiceMultipleSelectInput label={title} placeholder={t("inputPlaceholders.multipleOptions")} question={question} value={value} onChange={onChange} />;
        case AnswerType.Boolean:
            return (
                <BooleanInput
                    value={value != undefined && value != "" ? value == "Yes" : undefined}
                    onChange={handleBooleanChanged}
                    label={title}
                    placeholder={t("inputPlaceholders.singleOption")}
                />
            );
        default:
            throw new Error(`AnswerInput not implemented for AnswerType: ${question.type}`);
    }
}
