import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#004682",
    },
    secondary: {
      main: "#5684AC",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#efefef"
    }
  },
  typography: {
    fontSize: 15,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500
    },
    h2: {
      fontSize: "2.2rem",
    },
    h3: {
      fontSize: "1.7rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.3rem",
    },
    h6: {
      fontSize: "1.2rem",
    }
  }
});

export const gs = 3;

export default responsiveFontSizes(theme);
