export interface IApiVersion {
    apiVersion: string;
}

export interface IVersion extends IApiVersion {
    appVersion: string;
}

export interface IDocument {
    uuid: string;
    version: number;
    name: string;
    size: number;
    mimeType: string;
    width: number;
    height: number;
    metaData: MetaData;
}

export interface IS3Url {
    bucket: string;
    key: string;
    url: string;
    readonly: boolean;
    validUntil: string;
}

export interface IDocumentResponse {
    document: IDocument;
    url: IS3Url;
}

export type NumericRiskProbability = 1 | 2 | 3 | 4 | 5;

export enum RiskProbability {
    VeryLow = "VeryLow",
    Low = "Low",
    Medium = "Medium",
    High = "High",
    VeryHigh = "VeryHigh"
}

export function probability(probability: NumericRiskProbability): RiskProbability {
    switch (probability) {
        case 1:
            return RiskProbability.VeryLow;
        case 2:
            return RiskProbability.Low;
        case 3:
            return RiskProbability.Medium;
        case 4:
            return RiskProbability.High;
        case 5:
            return RiskProbability.VeryHigh;
    }
}

export interface Page<T> {
    content: T[];
    pageable: {
        pageNumber: number;
        pageSize: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
    last: boolean;
    totalPages: number;
    totalElements: number;
    first: boolean;
    size: number;
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    numberOfElements: number;
    empty: boolean;
}

export interface PageChange {
    page: number;
    pageSize: number;
}

export const defaultPage: PageChange = {
    page: 0,
    pageSize: 25
};

export interface MetaData {
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}

export interface MultilingualText {
    locale: string;
    text: string;
}

export class MultilingualTextWrapper {
    readonly texts: MultilingualText[];

    constructor(texts: MultilingualText[]) {
        this.texts = texts;
    }

    resolvedText(language: string): MultilingualText | undefined {
        let resolved = this.texts.filter((text) => text.locale === language).pop();
        if (resolved) {
            return resolved;
        }
        resolved = this.texts.filter((text) => text.locale === "en").pop();
        if (resolved) {
            return resolved;
        }
        if (this.texts.length > 0) {
            return this.texts[0];
        }
        return undefined;
    }

    resolved(language: string): string {
        const resolvedText = this.resolvedText(language);
        if (resolvedText) {
            return resolvedText.text;
        }
        return `Cannot resolve text in locale ${language} or default locale "en"`;
    }
}

export interface Address {
    line1?: string;
    line2?: string;
    zip?: string;
    city?: string;
    countryCode?: string;
}

export interface TenantUserInviteRequest {
    email: string;
    roleUuid: string;
}

export interface UserInfo {
    email: string;
    emailTo: string;
    emailVerified: boolean;
    fullName: string;
    givenName: string;
    name: string;
    providerPrefix: string;
    providerUsername: string;
    sub: string;
    username: string;
}

export interface TenantUserAssignRoleRequest {
    roleUuid: string;
}

export interface TenantUserUpdateLocaleRequest {
    locale: string;
}

export interface TenantUserInfo {
    id: {
        tenantUuid: string;
        username: string;
    };
    metaData: MetaData;
    role: Role;
}

export interface TenantUserInfo {
    user: UserInfo;
    tenantUser: TenantUserInfo;
}

export interface IIndustry {
    code: string;
    version: string;
    names: MultilingualText[];
}

export interface ITenant {
    uuid: string;
    version: number;
    name: string;
    address?: Address;
    industry: IIndustry;
    companySize: CompanySize;
    defaultLocale: string;
    locales: string[];
    metaData: MetaData;
}

export enum CompanySize {
    R1to9 = "R1to9",
    R10to49 = "R10to49",
    R50to249 = "R50to249",
    R250to499 = "R250to499",
    M500 = "M500",
    M1000 = "M1000",
    M3000 = "M3000"
}

export interface TenantModification {
    name: string;
    address: Address;
    companySize: CompanySize;
    industryCode: string;
    defaultLocale: string;
    locales: string[];
}

export interface Permission {
    entity: string;
    permissions: string[];
}

export interface Permissions {
    permissions: Permission[];
}

export interface RoleCreation {
    name: string;
    description: string;
    permissions: Permissions;
    defaultRole?: boolean;
}

export interface RoleModification extends RoleCreation {}

export interface Role extends RoleCreation {
    uuid: string;
    version: number;
    metaData: MetaData;
}

export enum KiToolType {
    Library = "Library",
    Tool = "Tool",
    Platform = "Platform"
}

export enum ToolCategory {
    TextGeneration = "TextGeneration",
    Business = "Business",
    Coding = "Coding",
    ImageGeneration = "ImageGeneration",
    DesignGeneration = "DesignGeneration",
    Entertainment = "Entertainment",
    Education = "Education",
    Misc = "Misc",
    SEO = "SEO",
    AvatarGeneration = "AvatarGeneration",
    Fashion = "Fashion",
    AudioGeneration = "AudioGeneration",
    Health = "Health",
    News = "News",
    Science = "Science",
    Finance = "Finance",
    Prompts = "Prompts"
}

export enum Visibility {
    CreatingUser = "CreatingUser",
    CreatingTenant = "CreatingTenant",
    All = "All"
}

export enum RiskCategory {
    AiAct = "AiAct",
    Privacy = "Privacy",
    InformationSecurity = "InformationSecurity",
    TradeSecrets = "TradeSecrets",
    Copyright = "Copyright",
    EmploymentLaw = "EmploymentLaw",
    Ethics = "Ethics",
    Discrimination = "Discrimination",
    ProductLiability = "ProductLiability"
}

export enum AiActRiskCategory {
    UnacceptableRisk = "UnacceptableRisk",
    HighRisk = "HighRisk",
    LimitedRisk = "LimitedRisk",
    MinimalRisk = "MinimalRisk"
}

export function compareAiActRiskCategories(a?: AiActRiskCategory, b?: AiActRiskCategory): number {
    const aIndex = a ? Object.values(AiActRiskCategory).indexOf(a) : 100;
    const bIndex = b ? Object.values(AiActRiskCategory).indexOf(b) : 100;
    return bIndex - aIndex;
}

export enum MeasureState {
    Identified = "Identified",
    InProgress = "InProgress",
    Completed = "Completed"
}

export enum TaskState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed"
}

export interface ToolModification {
    name: string;
    type: KiToolType;
    category: ToolCategory;
    visibility: Visibility;
    descriptions: MultilingualText[];
    vendor: string;
    url: string;
    basedOnUuid?: string;
}

export const emptyToolModification: ToolModification = {
    name: "",
    type: KiToolType.Tool,
    category: ToolCategory.Misc,
    visibility: Visibility.CreatingTenant,
    descriptions: [],
    vendor: "",
    url: ""
};

export interface IKiTool extends Omit<ToolModification, "basedOnUuid"> {
    uuid: string;
    version: number;
    basedOn?: IKiTool;
    owner: ITenant;
    favicon: IDocument;
    screenshot: IDocument;
    metaData: MetaData;
}

export interface AssetModification {
    name: string;
    type: KiToolType;
    category: ToolCategory;
    visibility: Visibility;
    descriptions: MultilingualText[];
    vendor: string;
}

export enum AssetState {
    New = "New",
    Review = "Review",
    Approved = "Approved",
    ConditionallyApproved = "ConditionallyApproved",
    Declined = "Declined"
}

export interface IAsset extends AssetModification {
    uuid: string;
    version: number;
    basedOn: IKiTool;
    owner: ITenant;
    state: AssetState;
    metaData: MetaData;
}

export interface AssetRiskModification {
    name: string;
    category: RiskCategory;
    aiActRiskCategory?: AiActRiskCategory;
    descriptions: MultilingualText[];
    risk: {
        probability: number;
        severity: number;
    };
}

export interface IAssetRisk extends AssetRiskModification {
    uuid: string;
    version: number;
    asset: IAsset;
    questionnaire?: Questionnaire;
    answer?: Answer;
    metaData: MetaData;
}

export interface IRiskHistogram {
    severity: {[key: number]: number};
    probability: {[key: number]: number};
}

export interface IAiActHistogram {
    histogram: {[key: string]: number};
}

export interface MeasureModification {
    priority: number;
    state: MeasureState;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    risk: {
        probability: number;
        severity: number;
    };
    assetRisk?: IAssetRisk;
}

export interface IMeasure extends MeasureModification {
    uuid: string;
    version: number;
    owner: ITenant;
    asset: IAsset;
    metaData: MetaData;
}

export interface ITask {
    uuid: string;
    version: number;
    priority: number;
    state: TaskState;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    owner: ITenant;
    asset: IAsset;
    assetRisk?: IAssetRisk;
    measure?: IMeasure;
    metaData: MetaData;
}

export interface TaskModification {
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    assetUuid?: string;
    assetRiskUuid?: string;
    measureUuid?: string;
}

export interface Questionnaire {
    slug: string;
    version: number;
    type?: KiToolType;
    category?: ToolCategory;
    visibility: Visibility;
    owner: ITenant;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    metaData: MetaData;
}

export enum AnswerType {
    Info = "Info",
    Text = "Text",
    FormattedText = "FormattedText",
    MultilineText = "MultilineText",
    TextList = "TextList",
    Integer = "Integer",
    Float = "Float",
    Boolean = "Boolean",
    YesNoUndefined = "YesNoUndefined",
    Checkbox = "Checkbox",
    SingleFileUpload = "SingleFileUpload",
    MultipleFileUpload = "MultipleFileUpload",
    HumanReadableProbabilities = "HumanReadableProbabilities",
    MultipleChoiceSingleAnswer = "MultipleChoiceSingleAnswer",
    MultipleChoiceMultipleAnswers = "MultipleChoiceMultipleAnswers",
    Unit = "Unit",
    Date = "Date",
    DateTime = "DateTime"
}

export enum AnswerScoreModifier {
    Equals = "Equals",
    NotEquals = "NotEquals",
    TextMatches = "TextMatches",
    TextContains = "TextContains",
    Greater = "Greater",
    GreaterOrEquals = "GreaterOrEquals",
    Less = "Less",
    LessOrEquals = "LessOrEquals",
    Multiply = "Multiply",
    Filled = "Filled"
}

export interface QuestionOption {
    slug: string;
    locale: string;
    label: string;
}

export interface QuestionShowSubQuestions {
    always: boolean;
    answer?: string;
    modifier: AnswerScoreModifier;
}

export interface QuestionScore {
    answer: string;
    redFlag: boolean;
    riskCategory: RiskCategory;
    aiActRiskCategory?: AiActRiskCategory;
    risk: {
        probability: number;
        severity: number;
    };
}

export function emptyQuestionScore(): QuestionScore {
    return {
        answer: "",
        redFlag: false,
        riskCategory: RiskCategory.AiAct,
        risk: {
            probability: 3,
            severity: 3
        }
    };
}

export interface QuestionScoreRiskTemplate {
    id: {
        answer: string;
        locale: string;
        questionUuid: string;
        riskCategory: RiskCategory;
    };
    title: string;
    text: string;
}

export interface QuestionScoreRiskTemplateModification {
    locale: string;
    title: string;
    text: string;
}

export function emptyQuestionScoreRiskTemplateModification(): QuestionScoreRiskTemplateModification {
    return {
        locale: "de",
        title: "",
        text: ""
    };
}

export interface Question {
    uuid: string;
    version: number;
    parent?: Question;
    children?: Question[];
    position: number;
    type: AnswerType;
    required: boolean;
    showSubQuestions: QuestionShowSubQuestions;
    titles: MultilingualText[];
    descriptions: MultilingualText[];
    options?: QuestionOption[];
    scores?: QuestionScore[];
    metaData: MetaData;
}

export interface QuestionEditRequest {
    position: number;
    type: AnswerType;
    required: boolean;
    titles: MultilingualText[];
    options: QuestionOption[];
    scores: QuestionScore[];
    riskTemplates?: QuestionScoreRiskTemplate[];
    descriptions: MultilingualText[];
}

export interface Answer {
    questionUuid: string;
    answer: string;
}

export interface SearchRequest {
    language: string;
    needle: string;
}

export interface SearchResponseElement {
    entity: string;
    path: string;
    display: string;
}

export interface SearchResponse {
    results: SearchResponseElement[];
}

export class SearchElementWrapper {
    readonly element: SearchResponseElement;

    constructor(element: SearchResponseElement) {
        this.element = element;
    }

    route(tenantUuid: string): string {
        return `/tenants/${tenantUuid}${this.element.path}`;
    }
}

export interface CatalogEntry {
    state: AssetState;
    entity: string;
    uuid: string;
    name: string;
    descriptions: MultilingualText[];
    favicon?: IDocument;
    screenshot?: IDocument;
}

export interface CatalogResult {
    entries?: CatalogEntry[];
    limit: number;
    more: number;
}

export interface CatalogRequest {
    name: string;
    description?: string;
    vendor?: string;
    useCase: string;
    locale: string;
    assetUuid?: string;
    toolUuid?: string;
    url?: string;
}

export enum ActivityType {
    AssetCreated = "AssetCreated",
    AssetUpdated = "AssetUpdated",
    AssetDeleted = "AssetDeleted",
    AssetAssessment = "AssetAssessment",
    AssetUseCaseRequested = "AssetUseCaseRequested",
    ToolCreated = "ToolCreated",
    ToolUpdated = "ToolUpdated",
    ToolCrawled = "ToolCrawled",
    ToolDeleted = "ToolDeleted"
}

export interface IActivity {
    uuid: string;
    version: number;
    type: ActivityType;
    owner: ITenant;
    asset?: IAsset;
    assetRisk?: IAssetRisk;
    measure?: IMeasure;
    task?: ITask;
    createdBy: string;
    createdAt: string;
}

export interface IWebSearchResult {
    title: string;
    url: string;
    description: string;
    faviconUrl: string;
}

export interface IWebSearchResponse {
    results: IWebSearchResult[];
}

export interface IWebCrawlerResponse {
    url: string;
    language: string;
    crawledAt: string;
    title: string;
    description: string;
    favicon: string;
    screenshot: string;
}