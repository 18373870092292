import {green, orange, red, yellow} from "@mui/material/colors";
import {DateTime} from "luxon";
import {MouseEvent} from "react";
import {AssetState} from "../domain/types";

export function appBaseUrl(): string {
    const formattedPort = window.location.port ? `:${window.location.port}` : "";
    return `${window.location.protocol}//${window.location.hostname}${formattedPort}`;
}

export function randomBlue(): number[] {
    return [Math.floor(Math.random() * 150), Math.floor(Math.random() * 150), 200];
}

export function luxonDate(date?: any): DateTime {
    if (!date) {
        return DateTime.local();
    } else if (typeof date === "string") {
        return DateTime.fromISO(date);
    } else if (date instanceof DateTime) {
        return date;
    } else if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    }
    throw Error("Date was in a not supported type");
}

export function stopMouseEvent(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
}

export enum DateDisplayFormat {
    Timestamp = "Timestamp",
    Age = "Age",
    Date = "Date",
    Time = "Time"
}

export function computeSlug(input: string): string {
    return input
        .toLowerCase()
        .trim()
        .replace(/[àáâãäå]/g, "a")
        .replace(/[èéêë]/g, "e")
        .replace(/[ìíîï]/g, "i")
        .replace(/[òóôõö]/g, "o")
        .replace(/[ùúûü]/g, "u")
        .replace(/[ýÿ]/g, "y")
        .replace(/[ñ]/g, "n")
        .replace(/[ç]/g, "c")
        .replace(/[ß]/g, "sz")
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-|-$/g, "");
}

export const calculateContrastRatio = (bgColor: string) => {
    const bgHex = bgColor.replace("#", "");

    const bgR = parseInt(bgHex.slice(0, 2), 16);
    const bgG = parseInt(bgHex.slice(2, 4), 16);
    const bgB = parseInt(bgHex.slice(4, 6), 16);

    const luminance = (r: number, g: number, b: number) => {
        const a = [r, g, b].map((v) => {
            v = v / 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const bgLuminance = luminance(bgR, bgG, bgB);

    return bgLuminance > 0.179 ? "#000000" : "#FFFFFF";
};

export const stateColor = (state: AssetState) => {
    switch (state) {
        case AssetState.New:
            return green[300];
        case AssetState.Review:
            return yellow[700];
        case AssetState.Approved:
            return green[700];
        case AssetState.ConditionallyApproved:
            return orange[700];
        case AssetState.Declined:
            return red[700];
    }
};

export function isEmail(email?: string): boolean {
    if (!email) {
        return false;
    }
    const re = /^(?=[a-z0-9][a-z0-9@._%+-]{5,253}$)[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}\.){1,8}[a-z]{2,63}$/;
    return re.test(email.toLowerCase());
}

export function initials(name: string, length?: number) {
    if (!length) {
        length = 2;
    }
    if (name) {
        return name
            .split(" ")
            .map((n) => n[0])
            .join("")
            .substr(0, length);
    }
    return "-";
}

export function maxLen(value: string, len: number, end?: string): string {
    if (!value) {
        return "";
    }
    if (value.length <= len) {
        return value;
    }
    if (!end) {
        end = " ...";
    }
    const keep = value.substr(0, len - end.length);
    return `${keep}${end}`;
}

export function stripHtml(html: string) {
    let tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}