import {Button, LinearProgress, MobileStepper, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {KiToolsProvider} from "../../../domain/KiToolsProvider";
import {QuestionnairesProvider} from "../../../domain/QuestionnairesProvider";
import {QuestionnaireWrapper} from "../../../domain/questionnaires";
import {IAsset, IAssetRisk, IKiTool} from "../../../domain/types";
import {StepDescriptionInput} from "./StepDescriptionInput";
import {StepNameInput} from "./StepNameInput";
import {StepQuestions} from "./StepQuestions";
import {StepSummary} from "./StepSummary";
import {StepToolSelection} from "./StepToolSelection";

enum Steps {
    SelectKiTool = "SelectKiTool",
    Name = "Name",
    Description = "Description",
    Questionnaire = "Questionnaire",
    Summary = "Summary"
}

export enum AssetRiskWizardMode {
    AddAsset = "AddAsset",
    AssessAsset = "AssessAsset"
}

interface AssetRiskWizardProps {
    mode: AssetRiskWizardMode;
    asset?: IAsset;
}

export function AssetRiskWizard(props: AssetRiskWizardProps) {
    const {mode, asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const [totalSteps, setTotalSteps] = useState<number>(10);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [kiTool, setKiTool] = useState<IKiTool>();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [questionnaire, setQuestionnaire] = useState<QuestionnaireWrapper>();
    const [risks, setRisks] = useState<IAssetRisk[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const {t} = useLanguageContext();

    useEffect(() => {
        if (api.auth) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.findDefault(tenantUuid).then((questionnaire) => {
                questionnairesProvider.questions(tenantUuid, questionnaire.slug).then((questions) => {
                    setQuestionnaire(new QuestionnaireWrapper(questionnaire, questions));
                });
            });
            const toolUuid = search.get("toolUuid");
            if (toolUuid) {
                const kiToolsProvider = new KiToolsProvider(api);
                kiToolsProvider.get(tenantUuid, toolUuid).then(setKiTool);
            }
        }
    }, [api, tenantUuid, search]);

    useEffect(() => {
        if (kiTool) {
            setName(kiTool.name);
            if (kiTool.descriptions && kiTool.descriptions.length > 0) {
                setDescription(kiTool.descriptions[0].text);
            }
        }
    }, [kiTool]);

    useEffect(() => {
        if (questionnaire) {
            switch (mode) {
                case AssetRiskWizardMode.AddAsset:
                    setTotalSteps(3 + questionnaire.numberOfQuestions);
                    break;
                case AssetRiskWizardMode.AssessAsset:
                    setTotalSteps(questionnaire.numberOfQuestions);
                    break;
                default:
                    throw new Error(`Unknown mode: ${mode}`);
            }
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.computeRisks(tenantUuid, questionnaire).then(setRisks);
        }
    }, [api, tenantUuid, mode, questionnaire]);

    const handleStep = useCallback(
        (step: number) => {
            if (step < 0 || step >= totalSteps) {
                return;
            }
            if (step == totalSteps - 1 && questionnaire && api && api.auth) {
                const assetsProvider = new AssetsProvider(api);
                setRisks(undefined);
                assetsProvider.computeRisks(tenantUuid, questionnaire).then(setRisks);
            }
            setActiveStep(step);
        },
        [api, tenantUuid, totalSteps, questionnaire]
    );

    const handleSubmit = useCallback(() => {
        if (api && api.auth) {
            const assetsProvider = new AssetsProvider(api);
            if (mode == AssetRiskWizardMode.AddAsset && api.auth && kiTool && name && questionnaire) {
                setSubmitting(true);
                assetsProvider
                    .create(tenantUuid, name, description, kiTool.uuid, questionnaire)
                    .then((asset) => {
                        setKiTool(undefined);
                        setName("");
                        navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                    })
                    .finally(() => setSubmitting(false));
            }
            if (mode == AssetRiskWizardMode.AssessAsset && api.auth && asset && questionnaire) {
                setSubmitting(true);
                assetsProvider
                    .assess(tenantUuid, asset.uuid, questionnaire)
                    .then(() => {
                        navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}`);
                    })
                    .finally(() => setSubmitting(false));
            }
        }
    }, [mode, api, tenantUuid, kiTool, asset, name, description, questionnaire, navigate]);

    const stepEnum = useMemo<Steps>(() => {
        switch (mode) {
            case AssetRiskWizardMode.AddAsset:
                if (activeStep >= 0 && activeStep <= 2) {
                    switch (activeStep) {
                        case 0:
                            return Steps.SelectKiTool;
                        case 1:
                            return Steps.Name;
                        case 2:
                            return Steps.Description;
                        default:
                            throw new Error(`Cannot map step ${activeStep} to enum`);
                    }
                } else if (activeStep == totalSteps - 1) {
                    return Steps.Summary;
                } else {
                    return Steps.Questionnaire;
                }
            case AssetRiskWizardMode.AssessAsset:
                if (activeStep == totalSteps - 1) {
                    return Steps.Summary;
                } else {
                    return Steps.Questionnaire;
                }
        }
    }, [mode, activeStep, totalSteps]);

    const stepTitle = useMemo<string>(() => {
        switch (stepEnum) {
            case Steps.SelectKiTool:
                return t("tools.kiTool.select");
            case Steps.Name:
                return t("tools.kiTool.enterName");
            case Steps.Description:
                return t("tools.kiTool.enterDescription");
            case Steps.Questionnaire:
                return t("tools.kiTool.answerQuestions");
            case Steps.Summary:
                return t("tools.kiTool.summary");
            default:
                throw new Error(`Cannot map step ${stepEnum} to title`);
        }
    }, [stepEnum]);

    const activeQuestion = useMemo<number>(() => activeStep - (mode == AssetRiskWizardMode.AddAsset ? 3 : 0), [activeStep, mode]);

    const allowNext = useMemo<boolean>(() => {
        switch (stepEnum) {
            case Steps.SelectKiTool:
                return Boolean(kiTool);
            case Steps.Name:
                return Boolean(name);
            case Steps.Description:
                return Boolean(description);
            case Steps.Questionnaire:
                if (questionnaire) {
                    return questionnaire.questionIsAnswered(activeQuestion);
                }
                return false;
            case Steps.Summary:
                return true;
            default:
                throw new Error(`Cannot compute allowNext for step ${stepEnum}`);
        }
    }, [stepEnum, kiTool, name, description, questionnaire, activeQuestion]);

    return (
        <Paper>
            <Box p={3}>
                <Typography variant="h3" align="center">
                    {stepTitle}
                </Typography>
            </Box>
            <Box sx={{minHeight: "50vh", maxHeight: "90vh"}} p={2}>
                {stepEnum == Steps.SelectKiTool && <StepToolSelection kiTool={kiTool} onKiToolChange={setKiTool} />}
                {stepEnum == Steps.Name && <StepNameInput name={name} onNameChanged={setName} />}
                {stepEnum == Steps.Description && (
                    <StepDescriptionInput description={description} onDescriptionChanged={setDescription} />
                )}
                {stepEnum == Steps.Questionnaire && questionnaire && (
                    <StepQuestions
                        activeQuestion={activeQuestion}
                        questionnaire={questionnaire}
                        onAnswer={setQuestionnaire}
                        risks={risks}
                    />
                )}
                {stepEnum == Steps.Summary && (
                    <StepSummary
                        kiTool={kiTool}
                        name={name}
                        description={description}
                        questionnaire={questionnaire}
                        risks={risks}
                    />
                )}
            </Box>
            <Box>
                <MobileStepper
                    position="static"
                    activeStep={activeStep}
                    steps={totalSteps}
                    variant="progress"
                    backButton={
                        <Button onClick={() => handleStep(activeStep - 1)} disabled={activeStep == 0}>
                            {t("back")}
                        </Button>
                    }
                    nextButton={
                        activeStep != totalSteps - 1 ? (
                            <Button onClick={() => handleStep(activeStep + 1)} disabled={!allowNext}>
                                {t("next")}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => handleSubmit()}
                                disabled={submitting}
                                variant="contained"
                                color="primary"
                            >
                                {mode == AssetRiskWizardMode.AddAsset && <>{t("assets.addAsset")}</>}
                                {mode == AssetRiskWizardMode.AssessAsset && <>{t("assets.assessAsset")}</>}
                            </Button>
                        )
                    }
                />
            </Box>
            {submitting && <LinearProgress />}
        </Paper>
    );
}
