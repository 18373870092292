import React from "react";
import {AiActRiskCategory} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Chip} from "@mui/material";
import {green, orange, red, yellow} from "@mui/material/colors";

interface AiActRiskCategoryDisplayProps {
    riskCategory?: AiActRiskCategory;
}

export function AiActRiskCategoryDisplay(props: AiActRiskCategoryDisplayProps) {
    const {riskCategory} = props;
    const {t, es} = useLanguageContext();

    if (!riskCategory) {
        return <Chip variant="outlined" label={t("risks.aiActRiskCategories.Undefined")} />;
    }

    let backgroundColor;
    let color;

    switch (riskCategory) {
        case AiActRiskCategory.UnacceptableRisk:
            backgroundColor = red[600];
            color = "white";
            break;
        case AiActRiskCategory.HighRisk:
            backgroundColor = orange[300];
            break;
        case AiActRiskCategory.LimitedRisk:
            backgroundColor = yellow[300];
            break;
        case AiActRiskCategory.MinimalRisk:
            backgroundColor = green[300];
            break;
    }

    return (
        <Chip variant="filled" label={es("risks.aiActRiskCategories", riskCategory)} sx={{backgroundColor, color}} />
    );
}