import {Grid} from "@mui/material";
import React, {useCallback} from "react";
import {LabeledData} from "../../../base/data/LabeledData";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {MultiLingualTextDisplay} from "../../../base/displays/MultiLingualTextDisplay";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {TotalRiskDisplay} from "../../../base/displays/TotalRiskDisplay";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {RisksProvider} from "../../../domain/RisksProvider";
import {IAssetRisk} from "../../../domain/types";
import {gs} from "../../../theme";
import {AiActRiskCategoryDisplay} from "../../../base/displays/AiActRiskCategoryDisplay";

interface RiskViewProps {
    assetRisk: IAssetRisk;
    onTranslate: () => void;
}

export function RiskView(props: RiskViewProps) {
    const {assetRisk, onTranslate} = props;
    const {t, es} = useLanguageContext();

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const handleTranslate = useCallback(
        async (target: string) => {
            if (api.auth && assetRisk) {
                const risksProvider = new RisksProvider(api);
                await risksProvider
                    .translate(tenantUuid, assetRisk.asset.uuid, assetRisk.uuid, {
                        targetLanguage: target
                    })
                    .then(() => {
                        onTranslate();
                    });
            }
        },
        [api, assetRisk]
    );

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <LabeledData label={t("name")}>{assetRisk.name}</LabeledData>
            </Grid>
            <AGridItemGrow>
                <LabeledData label={t("tools.headerFields.category")}>
                    {es("risks.riskCategories", assetRisk.category)}
                </LabeledData>
            </AGridItemGrow>
            <LabeledItem label={t("assets.headerNames.aiActRiskCategory")}>
                <AiActRiskCategoryDisplay riskCategory={assetRisk.aiActRiskCategory} />
            </LabeledItem>
            <LabeledItem label={t("assets.headerNames.probability")}>
                <ProbabilityDisplay probability={assetRisk.risk.probability} />
            </LabeledItem>
            <LabeledItem label={t("assets.headerNames.severity")}>
                <SeverityDisplay severity={assetRisk.risk.severity} />
            </LabeledItem>
            <LabeledItem label={t("assets.headerNames.grossRisk")}>
                <TotalRiskDisplay totalRisk={assetRisk.risk.probability * assetRisk.risk.severity} />
            </LabeledItem>

            {assetRisk.descriptions.length > 0 && (
                <Grid item xs={12}>
                    <LabeledData label={t("assets.headerNames.description")}>
                        <MultiLingualTextDisplay
                            texts={assetRisk.descriptions}
                            onTranslate={(target) => handleTranslate(target)}
                        />
                    </LabeledData>
                </Grid>
            )}
        </Grid>
    );
}
