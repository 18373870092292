import {Alert, Button, Container, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {RoleSelecion} from "../../base/inputs/RoleSelecion";
import {TextInput} from "../../base/inputs/TextInput";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {RolesProvider} from "../../domain/RolesProvider";
import {UsersProvider} from "../../domain/UsersProvider";
import {Page, Role, TenantUserInviteRequest} from "../../domain/types";
import {isEmail} from "../../libs/tools";
import {gs} from "../../theme";

interface UserInviteProps {}

export function UserInvite(props: UserInviteProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [roles, setRoles] = useState<Role[]>();

    useEffect(() => {
        const fetchRoles = async (tenantUuid: string) => {
            const rolesProvider = new RolesProvider(api);
            await rolesProvider
                .list(tenantUuid, {
                    page: 0,
                    pageSize: 10
                })
                .then((res: Page<Role>) => {
                    setRoles(res.content);
                    const defaultRole = res.content.find((role) => role.defaultRole === true);
                    if (defaultRole) {
                        setForm({
                            email: "",
                            roleUuid: defaultRole.uuid
                        });
                    }
                });
        };

        if (api.auth && tenantUuid) {
            fetchRoles(tenantUuid);
        }
    }, [api, tenantUuid, routeParams]);

    const defaultForm: TenantUserInviteRequest = {
        email: "",
        roleUuid: ""
    };
    const [form, setForm] = useState(defaultForm);
    const [error, setError] = useState<string | undefined>();

    const handleFormChange = (field: string, value: any) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value
        }));
    };

    const handleInviteUser = () => {
        const usersProvider = new UsersProvider(api);
        setSubmitting(true);
        setError(undefined);
        usersProvider
            .inviteUser(tenantUuid, form)
            .then(() => navigate(`/tenants/${tenantUuid}/users`))
            .catch((e) => setError(e.message || "Unknown error"))
            .finally(() => setSubmitting(false));
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("users.inviteUser")}
                        crumbs={[
                            {label: t("users.plural"), href: `/tenants/${tenantUuid}/users`},
                            {
                                label: t("users.inviteUser"),
                                href: `/tenants/${tenantUuid}/users/invite`
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            <Grid item xs={6}>
                                <TextInput
                                    label={t("users.email")}
                                    value={form.email}
                                    onChange={(e) => handleFormChange("email", e)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <RoleSelecion
                                    onChange={(v) => handleFormChange("roleUuid", v)}
                                    roles={roles || []}
                                    value={form.roleUuid}
                                />
                            </Grid>
                            {error && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        <Grid container spacing={gs}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">{t("users.error")}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">{error}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleInviteUser()}
                                    disabled={Boolean(!isEmail(form.email) || !form.roleUuid || submitting)}
                                >
                                    {t("users.inviteUser")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
