import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Button, LinearProgress, MobileStepper, Paper, Typography} from "@mui/material";
import {
    CatalogRequest,
    IAsset,
    IKiTool,
    IWebCrawlerResponse,
    IWebSearchResult,
    MultilingualTextWrapper
} from "../../domain/types";
import Box from "@mui/material/Box";
import {AssetRiskWizardMode} from "../assets/add/AssetRiskWizard";
import {WebSearchStep} from "./steps/WebSearchStep";
import {ManualEntryStep, ToolBaseAttributesForm} from "./steps/ManualEntryStep";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CatalogProvider} from "../../domain/CatalogProvider";
import {UseCaseStep} from "./steps/UseCaseStep";
import {SummaryStep} from "./steps/SummaryStep";
import {stripHtml} from "../../libs/tools";

enum Steps {
    WebSearch = "WebSearch",
    ManualEntry = "ManualEntry",
    UseCase = "UseCase",
    Summary = "Summary"
}

interface CatalogRequestNewWizardProps {
    tool?: IKiTool;
    asset?: IAsset;
    needle?: string;
    onSubmit: (request: CatalogRequest) => Promise<void>;
}

export function CatalogRequestNewWizard(props: CatalogRequestNewWizardProps) {
    const {tool, asset, needle, onSubmit} = props;
    const {t, language} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [steps, setSteps] = useState<Steps[]>([]);
    const [step, setStep] = useState<Steps>();

    const [webSearchResult, setWebSearchResult] = useState<IWebSearchResult>();
    const [webCrawlerResponse, setWebCrawlerResponse] = useState<IWebCrawlerResponse>();
    const [baseAttributes, setBaseAttributes] = useState<ToolBaseAttributesForm>({
        name: "",
        description: "",
        vendor: ""
    });
    const [useCaseDescription, setUseCaseDescription] = useState<string>("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tool || asset) {
            setSteps([Steps.UseCase, Steps.Summary]);
            if (asset) {
                setBaseAttributes({
                    name: asset.name,
                    description: new MultilingualTextWrapper(asset.descriptions).resolved(language),
                    vendor: asset.vendor
                });
            } else if (tool) {
                setBaseAttributes({
                    name: tool.name,
                    description: new MultilingualTextWrapper(tool.descriptions).resolved(language),
                    vendor: tool.vendor
                });
            }
            return;
        }
        if (needle) {
            setSteps([Steps.WebSearch, Steps.ManualEntry, Steps.UseCase, Steps.Summary]);
            return;
        }
        setSteps([Steps.ManualEntry, Steps.UseCase, Steps.Summary]);
    }, [tool, asset, needle, language]);

    useEffect(() => {
        setStep(steps[0]);
    }, [steps]);

    const activeStep = useMemo<number>(() => step ? steps.indexOf(step) : 0, [steps, step]);

    const allowNext = useMemo<boolean>(() => {
        switch (step) {
            case Steps.ManualEntry:
                return Boolean(baseAttributes.name && baseAttributes.description && baseAttributes.vendor);
            case Steps.UseCase:
                return Boolean(useCaseDescription);
            default:
                return true;
        }
    }, [step, baseAttributes, useCaseDescription]);

    const stepTitle = useMemo<string>(() => step || "", [step]);

    const handleStep = useCallback((step: number) => {
        setStep(steps[step]);
    }, [steps]);

    const handleWebResult = useCallback((result: IWebSearchResult) => {
        setWebSearchResult(result);
        setBaseAttributes({
            name: result.title,
            description: stripHtml(result.description),
            vendor: ""
        });
        if (api && api.auth && tenantUuid) {
            const catalogProvider = new CatalogProvider(api);
            catalogProvider.crawl(tenantUuid, result.url).then(setWebCrawlerResponse);
        }
        setStep(Steps.ManualEntry);
    }, [api, tenantUuid]);

    const handleSubmit = useCallback(() => {
        let url: string | undefined;
        if (webCrawlerResponse) {
            url = webCrawlerResponse.url;
        } else if (webSearchResult) {
            url = webSearchResult.url;
        }
        const request: CatalogRequest = {
            name: baseAttributes.name,
            description: baseAttributes.description,
            vendor: baseAttributes.vendor,
            useCase: useCaseDescription,
            locale: language,
            assetUuid: asset ? asset.uuid : undefined,
            toolUuid: tool ? tool.uuid : undefined,
            url: url
        };
        setLoading(true);
        onSubmit(request).finally(() => {
            setLoading(false);
        });
    }, [asset, tool, baseAttributes, useCaseDescription, webSearchResult, webCrawlerResponse, language, onSubmit]);

    return (
        <Paper>
            <Box p={3}>
                <Typography variant="h3" align="center">
                    {stepTitle}
                </Typography>
            </Box>
            <Box sx={{minHeight: "50vh", maxHeight: "90vh", overflowY: "scroll"}} p={2}>
                {(step == Steps.WebSearch && needle) && (
                    <WebSearchStep needle={needle} onSelect={handleWebResult} />
                )}
                {step == Steps.ManualEntry && (
                    <ManualEntryStep form={baseAttributes} onChange={setBaseAttributes} />
                )}
                {step == Steps.UseCase && (
                    <UseCaseStep useCaseDescription={useCaseDescription} onChange={setUseCaseDescription} />
                )}
                {step == Steps.Summary && (
                    <SummaryStep
                        toolBaseAttributes={baseAttributes}
                        useCaseDescription={useCaseDescription}
                        webSearchResult={webSearchResult}
                        webCrawlerResponse={webCrawlerResponse}
                    />
                )}
            </Box>
            <Box>
                <MobileStepper
                    position="static"
                    activeStep={activeStep}
                    steps={steps.length}
                    variant="progress"
                    backButton={
                        <Button onClick={() => handleStep(activeStep - 1)} disabled={activeStep == 0}>
                            {t("back")}
                        </Button>
                    }
                    nextButton={
                        activeStep != steps.length - 1 ? (
                            <Button onClick={() => handleStep(activeStep + 1)} disabled={!allowNext}>
                                {t("next")}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => handleSubmit()}
                                disabled={loading}
                                variant="contained"
                                color="primary"
                            >
                                {t("catalog.request.singular")}
                            </Button>
                        )
                    }
                />
                {loading && <LinearProgress />}
            </Box>
        </Paper>
    );
}