import {Button, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MeasureStateDisplay} from "../../../base/displays/MeasureStateDisplay";
import {PriorityDisplay} from "../../../base/displays/PriorityDisplay";
import {ProbabilityDisplay} from "../../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../../base/displays/SeverityDisplay";
import {AHeader} from "../../../base/layout/AHeader";
import {ATable} from "../../../base/table/ATable";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {AssetsProvider} from "../../../domain/AssetsProvider";
import {IAsset, IMeasure} from "../../../domain/types";
import {gs} from "../../../theme";

interface AssetMeasuresBlockProps {
    asset: IAsset;
}

export function AssetMeasuresBlock(props: AssetMeasuresBlockProps) {
    const {asset} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [measures, setMeasures] = useState<IMeasure[]>();

    const columns: GridColDef[] = [
        {
            field: t("assets.headerFields.title"),
            headerName: t("assets.headerNames.title"),
            flex: 1,
            renderCell: (params) => params.row.titles.map((t: any) => t.text).join(", ")
        },
        {
            field: t("assets.headerFields.priority"),
            headerName: t("assets.headerNames.priority"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <PriorityDisplay priority={r.row.priority} />
        },
        {
            field: t("assets.headerFields.state"),
            headerName: t("assets.headerNames.state"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <MeasureStateDisplay state={r.row.state} />
        },
        {
            field: t("assets.headerFields.probability"),
            headerName: t("assets.headerNames.probability"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <ProbabilityDisplay probability={r.row.risk.probability} />
        },
        {
            field: t("assets.headerFields.severity"),
            headerName: t("assets.headerNames.severity"),
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (r) => <SeverityDisplay severity={r.row.risk.severity} />
        }
    ];

    useEffect(() => {
        if (api.auth) {
            const assetsProvider = new AssetsProvider(api);
            assetsProvider.measures(tenantUuid, asset.uuid).then(setMeasures);
        }
    }, [api, tenantUuid, asset]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <AHeader title={t("measures.plural")} variant="h2">
                    <Button onClick={() => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/measures/add`)}>
                        {t("measures.addMeasure")}
                    </Button>
                </AHeader>
            </Grid>
            {measures && (
                <Grid item xs={12}>
                    <ATable
                        rows={measures}
                        columns={columns}
                        onClick={(row) => navigate(`/tenants/${tenantUuid}/assets/${asset.uuid}/measures/${row.uuid}`)}
                    />
                </Grid>
            )}
        </Grid>
    );
}
