import React from "react";
import {Comment, CommentSendRequest} from "../domain/comments";
import {ChatWindow} from "./chat/ChatWindow";
import {BoxedPaper} from "./layout/BoxedPaper";

interface CommentsProps {
    us: string;
    messages: Comment[];
    translationEnabled?: boolean;
    performSend: (message: CommentSendRequest) => Promise<Comment>;
}

export function Comments(props: CommentsProps) {
    const {messages, performSend, translationEnabled, us} = props;

    return (
        <BoxedPaper>
            <ChatWindow
                us={us}
                comments={messages}
                translationEnabled={translationEnabled}
                performSend={performSend}
            />
        </BoxedPaper>
    );
}
