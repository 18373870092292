import {Api} from "../libs/Api";
import {CatalogRequest, CatalogResult, IWebCrawlerResponse, IWebSearchResponse, IWebSearchResult} from "./types";

export class CatalogProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async search(tenantUuid: string, needle: string): Promise<CatalogResult> {
        const url = `/api/v1/tenants/${tenantUuid}/catalog?needle=${needle}`;
        return this.api.get<CatalogResult>(url);
    }

    async webSearch(tenantUuid: string, needle: string): Promise<IWebSearchResult[]> {
        const url = `/api/v1/tenants/${tenantUuid}/catalog/web-search?needle=${needle}`;
        const response = await this.api.get<IWebSearchResponse>(url);
        return response.results;
    };

    async crawl(tenantUuid: string, tooLUrl: string): Promise<IWebCrawlerResponse> {
        const request = {url: tooLUrl};
        const url = `/api/v1/tenants/${tenantUuid}/catalog/crawl`;
        return this.api.post<IWebCrawlerResponse>(url, request);
    }

    async create(tenantUuid: string, request: CatalogRequest): Promise<CatalogResult> {
        const url = `/api/v1/tenants/${tenantUuid}/catalog`;
        return this.api.post<CatalogResult>(url, request);
    }
}
