import {Api} from "../libs/Api";
import {
    IDocumentResponse,
    Page,
    PageChange,
    TenantUserAssignRoleRequest,
    TenantUserInfo,
    TenantUserInviteRequest,
    UserInfo
} from "./types";

export class UsersProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async getUserInfo(username: string): Promise<UserInfo> {
        return this.api.get<UserInfo>(`/api/v1/users/${username}`);
    }

    async listTenantUsers(tenantUuid: string, pageChange: PageChange): Promise<Page<TenantUserInfo>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/users`, pageChange);
        return this.api.get<Page<TenantUserInfo>>(url);
    }

    async getTenantUser(tenantUuid: string, username: string): Promise<TenantUserInfo> {
        return this.api.get<TenantUserInfo>(`/api/v1/tenants/${tenantUuid}/users/${username}`);
    }

    async getUserProfilePicture(tenantUuid: string, username: string, noCache?: boolean): Promise<IDocumentResponse> {
        let url = `/api/v1/tenants/${tenantUuid}/users/${username}/profile-picture`;
        if (noCache) {
            url = this.api.appendParams(url, `no-cache=${Date.now()}`);
        }
        return this.api.get<IDocumentResponse>(url);
    }

    async updateUserRole(
        tenantUuid: string,
        username: string,
        request: TenantUserAssignRoleRequest
    ): Promise<TenantUserInfo> {
        return this.api.put<TenantUserInfo>(`/api/v1/tenants/${tenantUuid}/users/${username}/role`, request);
    }

    async deleteUser(tenantUuid: string, username: string): Promise<void> {
        return this.api.delete(`/api/v1/tenants/${tenantUuid}/users/${username}`);
    }

    async inviteUser(tenantUuid: string, request: TenantUserInviteRequest): Promise<TenantUserInfo> {
        return this.api.post<TenantUserInfo>(`/api/v1/tenants/${tenantUuid}/users/invite`, request);
    }
}
