import {Tooltip, styled} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {CommentsProvider} from "../../domain/CommentsProvider";
import {UiTranslationRequest} from "../../domain/comments";
import {Comment} from "../../domain/comments";

interface TranslateControlProps {
    comment: Comment;
    displayingLanguage?: string;
    selectedLanguage: string;
    onSelectLanguage: (language: string) => void;
    onTranslated: (comment: Comment, translatedTo: string) => void;
}

const MiniControlSpan = styled("span")(({theme}) => ({
    cursor: "pointer",
    fontSize: "0.75em",
    color: theme.palette.secondary.main
}));

export function TranslateControl(props: TranslateControlProps) {
    const {comment, displayingLanguage, selectedLanguage, onSelectLanguage, onTranslated} = props;
    const {t, es, language} = useLanguageContext();

    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [processing, setProcessing] = useState<boolean>();

    const resolveLanguage = useCallback((language: string) => {
        return es("languages", language);
    }, [es]);

    const handleTranslate = useCallback(() => {
        setProcessing(true);
        if (api.auth) {
            const commentsProvider = new CommentsProvider(api);
            const request: UiTranslationRequest = {
                targetLanguage: language
            };
            commentsProvider
                .translate(tenantUuid, comment.uuid, request)
                .then((comment) => {
                    onTranslated(comment, language);
                })
                .finally(() => {
                    setProcessing(false);
                });
        }
    }, [comment, language, api, tenantUuid]);

    const offerTranslate = useMemo<boolean>(() => {
        if (displayingLanguage != language) {
            return comment.comments.filter((c) => c.locale == language).length == 0;
        }
        return false;
    }, [displayingLanguage, language, comment]);

    const showingOriginal = useMemo<boolean>(() => {
        return displayingLanguage == comment.originalLocale;
    }, [displayingLanguage, comment]);

    if (language == comment.originalLocale || !displayingLanguage) {
        return null;
    }

    if (processing) {
        return (
            <MiniControlSpan>
                {t("translations.translating", {
                    source: resolveLanguage(comment.originalLocale),
                    target: resolveLanguage(language)
                })}
            </MiniControlSpan>
        );
    }

    if (offerTranslate) {
        return (
            <Tooltip title={t("translations.translateInfo", {
                source: resolveLanguage(comment.originalLocale),
                target: resolveLanguage(language)
            })}>
                <MiniControlSpan onClick={handleTranslate}>
                    {t("translations.translate", {
                        target: resolveLanguage(language)
                    })}
                </MiniControlSpan>
            </Tooltip>
        );
    }

    if (showingOriginal) {
        return (
            <MiniControlSpan onClick={() => onSelectLanguage(language)}>
                {t("translations.showTranslated", {
                    target: resolveLanguage(language)
                })}
            </MiniControlSpan>
        );
    } else {
        return (
            <MiniControlSpan onClick={() => onSelectLanguage(comment.originalLocale)}>
                {t("translations.showOriginal", {
                    source: resolveLanguage(comment.originalLocale)
                })}
            </MiniControlSpan>
        );
    }
}
