import React, {useCallback, useEffect, useState} from "react";
import {QuestionScoreRiskTemplateModification} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {gs} from "../../../theme";
import {Grid} from "@mui/material";
import {TextInput} from "../../../base/inputs/TextInput";

interface RiskTemplateFormProps {
    value: QuestionScoreRiskTemplateModification;
    onApply: (value: QuestionScoreRiskTemplateModification) => void;
}

export function RiskTemplateForm(props: RiskTemplateFormProps) {
    const {value, onApply} = props;
    const {t} = useLanguageContext();

    const [locale, setLocale] = useState<string>(value.locale);
    const [title, setTitle] = useState<string>(value.title);
    const [text, setText] = useState<string>(value.text);

    useEffect(() => {
        setLocale(value.locale);
        setTitle(value.title);
        setText(value.text);
    }, [value]);

    const handleApplyLocale = useCallback((locale: string) => {
        setLocale(locale);
        onApply({
            ...value,
            locale
        });
    }, [onApply, value]);

    const handleApplyTitle = useCallback((title: string) => {
        setTitle(title);
        onApply({
            ...value,
            title
        });
    }, [onApply, value]);

    const handleApplyText = useCallback((text: string) => {
        setText(text);
        onApply({
            ...value,
            text
        });
    }, [onApply, value]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={6} md={8}>
                <TextInput
                    label={t("questionnaires.question.riskTemplates.riskTitle")}
                    value={title}
                    onChange={handleApplyTitle}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <TextInput
                    label={t("language")}
                    value={locale}
                    onChange={handleApplyLocale}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={t("questionnaires.question.riskTemplates.text")}
                    value={text}
                    onChange={handleApplyText}
                    rows={10}
                />
            </Grid>
        </Grid>
    );
}