import {Button, Container, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AGridItemGrow} from "../../../base/layout/AGridItemGrow";
import {AHeaderWithBreadcrumbs} from "../../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../../base/layout/BoxedPaper";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {CurrentUserProvider} from "../../../domain/CurrentUserProvider";
import {CompanySize, ITenant, TenantModification} from "../../../domain/types";
import {gs} from "../../../theme";
import {TenantForm} from "./TenantForm";

interface TenantEditProps {}

export function TenantEdit(props: TenantEditProps) {
    const routeParams = useParams();
    const {t} = useLanguageContext();
    const navigate = useNavigate();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [tenant, setTenant] = useState<ITenant>();

    const [submitting, setSubmitting] = useState(false);

    const defaultForm: TenantModification = {
        name: "",
        address: {
            city: "",
            countryCode: "",
            line1: "",
            line2: "",
            zip: ""
        },
        companySize: CompanySize.R1to9,
        defaultLocale: "",
        industryCode: "",
        locales: []
    };

    const [form, setForm] = useState<TenantModification>(defaultForm);

    useEffect(() => {
        if (routeParams.tenantUuid) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(routeParams.tenantUuid).then((tenant) => {
                setTenant(tenant);
                setForm({
                    companySize: tenant.companySize,
                    defaultLocale: tenant.defaultLocale,
                    industryCode: tenant.industry.code,
                    locales: tenant.locales,
                    name: tenant.name,
                    address: tenant.address
                        ? tenant.address
                        : {
                              city: "",
                              countryCode: "",
                              line1: "",
                              line2: "",
                              zip: ""
                          }
                });
            });
        }
    }, [routeParams.tenantUuid, api]);

    const handleSave = () => {
        if (routeParams.tenantUuid && api && tenantUuid) {
            setSubmitting(true);
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider
                .updateTenant(routeParams.tenantUuid, form)
                .then((updatedTenant) => {
                    navigate(`/tenants/${updatedTenant.uuid}/profile`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("tenant.edit.title")}
                        crumbs={[
                            {
                                label: t("tenant.plural"),
                                href: `/tenants/${routeParams.tenantUuid}/tenants`
                            },
                            {
                                label: tenant ? tenant.name : "...",
                                href: `/tenants/${routeParams.tenantUuid}/tenants/${routeParams.tenantUuid}`
                            },
                            {label: t("tenant.edit.title"), href: ""}
                        ]}
                    />
                </Grid>

                {tenant && (
                    <Grid item xs={12}>
                        <TenantForm onChange={(m) => setForm((prev) => ({...prev, ...m}))} tenant={form} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <BoxedPaper loading={submitting}>
                        <Grid container spacing={gs}>
                            <AGridItemGrow>
                                <Button variant="contained" onClick={() => handleSave()}>
                                    {t("save")}
                                </Button>
                            </AGridItemGrow>

                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={() => navigate(`/tenants/${routeParams.tenantUuid}/tenants`)}
                                >
                                    {t("cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
        </Container>
    );
}
