import {AddCircle} from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress, Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {EnumSelectInput} from "../../base/inputs/EnumSelectInput";
import {LanguageSwitcher} from "../../base/inputs/LanguageSwitcher";
import {TextInput} from "../../base/inputs/TextInput";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {useAuthContext} from "../../context/auth/AuthContext";
import {languageConfigurations, useLanguageContext} from "../../context/language/LanguageContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {QuestionnairesProvider} from "../../domain/QuestionnairesProvider";
import {
    AnswerType,
    MultilingualTextWrapper,
    Question,
    QuestionEditRequest,
    QuestionOption,
    Questionnaire, QuestionScoreRiskTemplate, QuestionScore
} from "../../domain/types";
import {gs} from "../../theme";
import {OptionsEditDialog} from "./options/OptionsEditDialog";
import {AHeader} from "../../base/layout/AHeader";
import {BooleanDisplay} from "../../base/displays/BooleanDisplay";
import {ProbabilityDisplay} from "../../base/displays/ProbabilityDisplay";
import {SeverityDisplay} from "../../base/displays/SeverityDisplay";
import {TotalRiskDisplay} from "../../base/displays/TotalRiskDisplay";
import {AnswerDisplay} from "../../base/displays/AnswerDisplay";
import {ScoreAddDialog} from "./scores/ScoreAddDialog";
import {ScoreEditDialog} from "./scores/ScoreEditDialog";
import {maxLen} from "../../libs/tools";
import {filterRiskTemplates} from "../../domain/questionnaires";
import {AiActRiskCategoryDisplay} from "../../base/displays/AiActRiskCategoryDisplay";

interface QuestionEditProps {}

export function QuestionEdit(props: QuestionEditProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const navigate = useNavigate();

    const {t, es, language} = useLanguageContext();

    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    const [question, setQuestion] = useState<Question>();
    const [riskTemplates, setRiskTemplates] = useState<QuestionScoreRiskTemplate[]>();

    const [addLanguageDialogOpened, setAddLanguageDialogOpened] = useState(false);
    const [editOptionsDialogOpened, setEditOptionsDialogOpened] = useState(false);

    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

    const [languages, setLanguages] = useState<string[]>();

    const [preCustomLanguage, setPreCustomLanguage] = useState("");
    const [customLanguage, setCustomLanguage] = useState("");

    const [loading, setLoading] = useState(false);

    const defaultForm: QuestionEditRequest = {
        position: -1,
        descriptions: [],
        required: false,
        titles: [],
        options: [],
        scores: [],
        type: AnswerType.Boolean
    };

    const [form, setForm] = useState<QuestionEditRequest>(defaultForm);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [addScore, setAddScore] = useState<boolean>(false);
    const [editScore, setEditScore] = useState<number | undefined>();

    const handleFormChange = (field: string, value: any) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value
        }));
    };

    const handleApplyOptions = (options: QuestionOption[]) => {
        handleFormChange("options", options);
        setEditOptionsDialogOpened(false);
    };

    useEffect(() => {
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            const questionnairesProvider = new QuestionnairesProvider(api);
            questionnairesProvider.get(tenantUuid, routeParams.slug).then(setQuestionnaire);
            questionnairesProvider.getQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid).then((res) => {
                setQuestion(res);
                setForm({
                    position: res.position,
                    descriptions: res.descriptions,
                    required: res.required,
                    titles: res.titles,
                    type: res.type,
                    options: res.options || [],
                    scores: res.scores || []
                });
                let languages: string[] = [];
                res.titles.map((title) => languages.push(title.locale));
                setLanguages(languages);
            });
            questionnairesProvider.listRiskTemplates(tenantUuid, routeParams.slug, routeParams.questionUuid).then(setRiskTemplates);
        }
    }, []);

    useEffect(() => {
        if (customLanguage) {
            setSelectedLanguage(customLanguage);
            const langs = languages || [];
            langs.push(customLanguage);

            if (languages && !languages.includes(customLanguage)) {
                setLanguages([...new Set([...languages, customLanguage])]);
            }

            const newTitleExists = form.titles.some((item) => item.locale === customLanguage);

            if (!newTitleExists) {
                const newTitle = {locale: customLanguage, text: ""};
                handleFormChange("titles", [...form.titles, newTitle]);
            }

            const newDescriptionExists = form.descriptions.some((item) => item.locale === customLanguage);

            if (!newDescriptionExists) {
                const newDescription = {locale: customLanguage, text: ""};
                handleFormChange("descriptions", [...form.descriptions, newDescription]);
            }
        }
    }, [customLanguage]);

    const handleChangeTitle = (title: string) => {
        const existingTitle = form.titles.find((item) => item.text === title);
        if (existingTitle) {
            handleFormChange(
                "titles",
                form.titles.map((item) => (item.locale === selectedLanguage ? {...item, text: title} : item))
            );
        } else {
            handleFormChange("titles", [
                ...form.titles.filter((item) => item.locale !== selectedLanguage),
                {locale: selectedLanguage, text: title}
            ]);
        }
    };

    const handleChangeDescription = (description: string) => {
        const existingDescription = form.descriptions.find((item) => item.text === description);
        if (existingDescription) {
            handleFormChange(
                "descriptions",
                form.descriptions.map((item) =>
                    item.locale === selectedLanguage ? {...item, text: description} : item
                )
            );
        } else {
            handleFormChange("descriptions", [
                ...form.descriptions.filter((item) => item.locale !== selectedLanguage),
                {locale: selectedLanguage, text: description}
            ]);
        }
    };

    const handleCloseAddScore = useCallback((score?: QuestionScore, templates?: QuestionScoreRiskTemplate[]) => {
        if (score) {
            if (riskTemplates && templates) {
                const newTemplates = [...riskTemplates, ...templates];
                setRiskTemplates(newTemplates);
            }
            handleFormChange("scores", [...form.scores, score]);
        }
        setAddScore(false);
    }, [form, riskTemplates]);

    const handleCloseEditScore = useCallback((score?: QuestionScore, templates?: QuestionScoreRiskTemplate[]) => {
        if (editScore != undefined && score) {
            if (riskTemplates && templates) {
                const answer = form.scores[editScore].answer;
                const riskCategory = form.scores[editScore].riskCategory;
                const filteredTemplates = riskTemplates
                    .filter((t) => t.id.answer != answer || t.id.riskCategory != riskCategory);
                const newTemplates = [...filteredTemplates, ...templates];
                setRiskTemplates(newTemplates);
            }

            const newScores = form.scores.map((item, index) => (index === editScore ? score : item));
            handleFormChange("scores", newScores);
        }
        setEditScore(undefined);
    }, [form, riskTemplates, editScore]);

    const handleDeleteEditScore = useCallback(() => {
        if (editScore != undefined) {
            const newScores = form.scores.filter((item, index) => index !== editScore);
            handleFormChange("scores", newScores);
        }
        setEditScore(undefined);
    }, [form, editScore]);

    const handleEditQuestion = () => {
        const questionnairesProvider = new QuestionnairesProvider(api);
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            setLoading(true);
            form.riskTemplates = riskTemplates;
            questionnairesProvider
                .editQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid, form)
                .then(() => handleCancelEdit())
                .finally(() => setLoading(false));
        }
    };

    const handleDeleteQuestion = () => {
        const questionnairesProvider = new QuestionnairesProvider(api);
        if (routeParams.questionUuid && routeParams.slug && api.auth) {
            questionnairesProvider
                .deleteQuestion(tenantUuid, routeParams.slug, routeParams.questionUuid)
                .then((res) => {
                    setLoading(false);
                    setDeleteDialogOpened(false);
                })
                .finally(() => handleCancelEdit());
        }
    };

    const handleCancelEdit = () => {
        navigate(`/tenants/${tenantUuid}/questionnaires/${routeParams.slug}?selectQuestionUuid=${routeParams.questionUuid}`);
    };

    const deriveOptionLabel = (key: string | null) => {
        if (key) {
            return es("languages", key);
        }
        return "";
    };

    const isValid = useMemo(() => {
        const titles = form.titles.map((title) => title.text);
        const titlesValid = titles.every((title) => title != "");

        return titlesValid != false;
    }, [form]);

    const specificQuestionTypes = [AnswerType.MultipleChoiceMultipleAnswers, AnswerType.MultipleChoiceSingleAnswer];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={question ? new MultilingualTextWrapper(question.titles).resolved(language) : "..."}
                        crumbs={[
                            {label: t("questionnaires.plural"), href: `/tenants/${tenantUuid}/questionnaires`},
                            {
                                label: questionnaire
                                    ? new MultilingualTextWrapper(questionnaire.titles).resolved(language)
                                    : "...",
                                href: `/tenants/${tenantUuid}/questionnaires/${routeParams.slug}`
                            }
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BoxedPaper loading={loading}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={8}>
                                        <TextInput
                                            label={t("title")}
                                            onChange={(e) => handleChangeTitle(e)}
                                            value={new MultilingualTextWrapper(form.titles).resolved(selectedLanguage)}
                                        />
                                        {selectedLanguage !== language && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    {question
                                                        ? new MultilingualTextWrapper(question.titles).resolved(
                                                              language
                                                          )
                                                        : "..."}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LanguageSwitcher
                                            key={JSON.stringify(customLanguage)}
                                            onChange={(e, f) => setSelectedLanguage(e)}
                                            languages={
                                                customLanguage && languages
                                                    ? [
                                                          ...(Array.isArray(languages)
                                                              ? languages.filter((lang) => lang !== customLanguage)
                                                              : []),
                                                          customLanguage
                                                      ]
                                                    : Array.isArray(languages)
                                                    ? languages
                                                    : []
                                            }
                                            customLanguageSelected={customLanguage}
                                        />
                                        <IconButton
                                            size="large"
                                            title={t("languages.add")}
                                            onClick={() => setAddLanguageDialogOpened(true)}
                                        >
                                            <AddCircle />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    label={t("description")}
                                    onChange={(e) => handleChangeDescription(e)}
                                    value={new MultilingualTextWrapper(form.descriptions).resolved(selectedLanguage)}
                                    rows={4}
                                />
                                {selectedLanguage !== language && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            {question
                                                ? new MultilingualTextWrapper(question.descriptions).resolved(language)
                                                : "..."}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={6}>
                                        <EnumSelectInput
                                            label={t("type")}
                                            onChange={(e: AnswerType) => {
                                                handleFormChange("type", e);
                                            }}
                                            lookupKey="questionnaires.types"
                                            options={Object.values(AnswerType)}
                                            value={form.type}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={form.required}
                                                            onChange={(event) =>
                                                                handleFormChange("required", event.target.checked)
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("questionnaires.required")}
                                                />
                                            </Grid>
                                            {question && specificQuestionTypes.includes(form.type) && (
                                                <Grid item>
                                                    <Button
                                                        onClick={() => setEditOptionsDialogOpened(true)}
                                                        color="primary"
                                                        variant="outlined"
                                                    >
                                                        {t("questionnaires.question.options.edit")}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <AHeader title={t("questionnaires.question.scores.title")} variant="h2">
                                    <Button variant="outlined" onClick={() => setAddScore(true)}>{t("questionnaires.scores.add")}</Button>
                                </AHeader>
                            </Grid>
                            {question &&
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableCell sx={{width: "15%"}}>{t("questionnaires.question.answer")}</TableCell>
                                            <TableCell sx={{width: "15%"}}>{t("assets.headerNames.category")}</TableCell>
                                            <TableCell>{t("questionnaires.question.aiActRiskCategory")}</TableCell>
                                            <TableCell>{t("questionnaires.question.redFlag")}</TableCell>
                                            <TableCell align="center">{t("assets.headerNames.probability")}</TableCell>
                                            <TableCell align="center">{t("assets.headerNames.severity")}</TableCell>
                                            <TableCell align="center">{t("assets.headerNames.grossRisk")}</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {form.scores.length == 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={7}>
                                                        <Box p={3}>
                                                            <Typography variant="body1" align="center">
                                                                {t("questionnaires.question.scores.none")}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {form.scores
                                                .sort((a, b) => a.riskCategory.localeCompare(b.riskCategory))
                                                .map((score, index) =>
                                                <TableRow key={index} hover onClick={() => setEditScore(index)}>
                                                    <TableCell>
                                                        <AnswerDisplay question={question} answer={score.answer} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {es("risks.riskCategories", score.riskCategory)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {score.aiActRiskCategory &&
                                                            <AiActRiskCategoryDisplay riskCategory={score.aiActRiskCategory} />
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <BooleanDisplay value={score.redFlag} colored />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <ProbabilityDisplay probability={score.risk.probability} />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <SeverityDisplay severity={score.risk.severity} />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <TotalRiskDisplay totalRisk={score.risk.probability * score.risk.severity} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            }
                            {(question && riskTemplates) &&
                                <>
                                    <Grid item xs={12}>
                                        <AHeader title={t("questionnaires.question.riskTemplates.title")} variant="h2"></AHeader>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableCell sx={{width: "15%"}}>{t("questionnaires.question.answer")}</TableCell>
                                                <TableCell sx={{width: "15%"}}>{t("assets.headerNames.category")}</TableCell>
                                                <TableCell>{t("language")}</TableCell>
                                                <TableCell>{t("questionnaires.question.riskTemplates.riskTitle")}</TableCell>
                                                <TableCell>{t("questionnaires.question.riskTemplates.template")}</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {riskTemplates.length == 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Box p={3}>
                                                                <Typography variant="body1" align="center">
                                                                    {t("questionnaires.question.riskTemplates.none")}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {riskTemplates
                                                    .sort((a, b) => a.id.riskCategory.localeCompare(b.id.riskCategory))
                                                    .map((template, index) =>
                                                    <TableRow key={index} hover>
                                                        <TableCell>
                                                            <AnswerDisplay question={question} answer={template.id.answer} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {es("risks.riskCategories", template.id.riskCategory)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {template.id.locale}
                                                        </TableCell>
                                                        <TableCell>
                                                            {maxLen(template.title, 30)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {maxLen(template.text, 60)}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid
                                        item
                                        sx={{
                                            flexGrow: "1"
                                        }}
                                    >
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleEditQuestion()}
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!isValid}
                                                >
                                                    {t("save")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setDeleteDialogOpened(true)}
                                                    color="error"
                                                    variant="outlined"
                                                    disabled={loading}
                                                >
                                                    {t("delete")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => handleCancelEdit()} variant="outlined">
                                            {t("cancel")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
            </Grid>
            {question &&
                <ScoreAddDialog
                    open={addScore}
                    question={question}
                    onClose={(score, templates) => handleCloseAddScore(score, templates)}
                />
            }
            {question && editScore != undefined &&
                <ScoreEditDialog
                    open={true}
                    question={question}
                    score={form.scores[editScore]}
                    templates={riskTemplates ? filterRiskTemplates(riskTemplates, form.scores[editScore || 0].riskCategory, form.scores[editScore || 0].answer) : []}
                    onClose={(score, templates) => handleCloseEditScore(score, templates)}
                    onDelete={() => handleDeleteEditScore()}
                />
            }
            {addLanguageDialogOpened && (
                <Dialog
                    open={addLanguageDialogOpened}
                    onClose={() => setAddLanguageDialogOpened(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle>{t("languages.add")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={languageConfigurations.map((lc) => lc.language)}
                                    getOptionLabel={(key) => deriveOptionLabel(key)}
                                    style={{width: "100%"}}
                                    value={preCustomLanguage}
                                    disableClearable
                                    onChange={(event: any, newValue: string | null) => {
                                        if (newValue) {
                                            setPreCustomLanguage(newValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                marginTop: "20px"
                                            }}
                                            label={t("languages.add")}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setCustomLanguage(preCustomLanguage);
                                setAddLanguageDialogOpened(false);
                                setPreCustomLanguage("");
                            }}
                            variant="contained"
                            color="primary"
                        >
                            {t("confirm")}
                        </Button>
                        <Button onClick={() => setAddLanguageDialogOpened(false)} variant="outlined">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {editOptionsDialogOpened && question && form.options && (
                <OptionsEditDialog
                    open={editOptionsDialogOpened}
                    onApply={(options) => handleApplyOptions(options)}
                    onCancel={() => setEditOptionsDialogOpened(false)}
                    options={form.options}
                    requiredLanguages={["en", "de"]}
                />
            )}
            {deleteDialogOpened && (
                <Dialog open={deleteDialogOpened} onClose={() => setDeleteDialogOpened(false)} fullWidth maxWidth="sm">
                    <DialogTitle>{t("delete")}</DialogTitle>
                    <DialogContent>{t("questionnaires.deleteQuestion.message")}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleDeleteQuestion()} variant="contained" color="error">
                            {t("delete")}
                        </Button>
                        <Button onClick={() => setDeleteDialogOpened(false)} variant="outlined">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                    {loading && <LinearProgress />}
                </Dialog>
            )}
        </Container>
    );
}
